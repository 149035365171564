@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
* {
  padding: 0;
  margin: 0;
}

ul,
li {
  list-style: none;
}
@font-face {
  font-family: "athelasregular";
  src: url("./Fonts/athelas-regular-webfont.woff2") format("woff2"),
    url("./Fonts/athelas-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "timesnewroman";
  src: url("./Fonts/timesnewroman.eot");
  src: url("./Fonts/timesnewroman.eot") format("embedded-opentype"),
    url("./Fonts/timesnewroman.woff2") format("woff2"),
    url("./Fonts/timesnewroman.woff") format("woff"),
    url("./Fonts/timesnewroman.ttf") format("truetype"),
    url("./Fonts/timesnewroman.svg#timesnewroman") format("svg");
}
body {
  color: #6a6967;
  font-family: "Open Sans", sans-serif;
  margin: 0 auto;
  padding: 0;
}
.certificationcont {
  background-color: #fffbf2;
  position: relative;
  z-index: 1;
  padding: 40px 2% 0 33%;
  height: 792px;
  width: 100%;
  margin: 0 auto !important;
}
.certificationcont::before {
  content: "";
  position: absolute;
  background-image: url("./Images/certificate-leftimg.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.certificationcont::after {
  border: 3px solid #878382;
  content: "";
  position: absolute;
  top: 21px;
  right: 30px;
  height: 95%;
  width: 95%;
  border-radius: 40px;
  z-index: 0;
}
.certificationinnerleft_cont {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}

.certificationinnerleft_cont img {
  height: 100%;
}
.certificationinner_cont {
  height: auto;
  display: flex;
  flex-direction: column;
}

.topheadcont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-right: 40px;
  margin-bottom: 50px;
}

.topheadcont li.complogo.himalayaslogo {
  display: flex;
  align-items: center;
}

.topheadcont li.complogo.himalayaslogo img {
  width: auto;
  margin-right: 15px;
}
.topheadcont .adventureslogo img {
  width: 80px;
}
.certificationcontent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5% 0 10%;
}
.certificationcontent h1 {
  font-size: 82px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "athelasregular";
  color: #4f3325;
}
.certificationcontent h1 abbr {
  font-size: 42px;
}
.certificationcontent span {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  font-family: "athelasregular";
  color: #4f3325;
}
.certificationcontent h2 {
  font-size: 42px;
  margin-bottom: 10px;
  font-family: "athelasregular";
  color: #4f3325;
}
.certificationcontent p {
  font-family: "timesnewroman";
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  margin: 0;
  font-weight: 600;
}
.footercont {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  margin-left: -50px;
  margin-top: 30px;
}
.footercont .footcontcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
}
.footercont .footcontcontent.cofounder_ao .founder_sign {
  width: 120px;
}
.footercont .footcontcontent.cofounder_golden .founder_sign {
  width: 80px;
}
.footercont .footcontcontent h3 {
  color: #333;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 700;
  position: relative;
}
.footercont .footcontcontent h3::before {
  width: 100%;
  background: #333;
  bottom: -2px;
  height: 1px;
  left: 0;
  position: absolute;
  content: "";
}
.footercont .footcontcontent span {
  color: #333;
  font-weight: 500;
  font-size: 14px;
}
