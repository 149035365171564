.logo-a {
    position: relative;
  }
  .logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .aarey-forest-phase9 {
    position: relative;
    font-size: var(--font-size-lg);
    font-family: var(--font-poppins);
  }
  .header-left-content,
  .page-title {
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) 0;
    align-items: center;
    justify-content: flex-start;
  }
  .header-left-content {
    flex: 1;
    gap: var(--gap-xl);
  }
  .header-right-content-child {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .headereco,
  .header-right-content {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .header-right-content {
    width: 123px;
    gap: var(--gap-11xl);
  }
  .headereco {
    background: linear-gradient(180deg, #00a76b 48.44%, #008e5b);
    overflow: hidden;
    padding: 0 0 0 var(--padding-xl);
    gap: var(--gap-3xs);
    text-align: left;
    font-size: var(--font-size-11xl);
    color: var(--color-white);
    font-family: var(--font-treeties);
  }
  