@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/*@font-face {
  font-family: "icomoon";
  src: url("/public/Treeties.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Treeties";
  src: url("/public/Treeties.ttf");
  font-weight: 400;
} */

@font-face {
  font-family: "Treeties";
  src: url("../../css/Treeties.ttf");
  font-weight: 400;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-treeties: Treeties;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-5xl: 24px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  --font-size-base: 16px;
  --font-size-11xl: 30px;
  --font-size-lg: 18px;
  --font-size-3xs: 10px;
  --font-size-3xl: 22px;
  --font-size-mini: 15px;
  --font-size-7xl: 26px;
  --font-size-9xl: 28px;

  /* Colors */
  --color-white: #fff;
  --color-aliceblue: #f0f6fc;
  --color-darkgray: #999;
  --color-mediumseagreen-100: #50b041;
  --color-mediumseagreen-200: #50b041;
  --color-mediumseagreen-300: #50b041;
  --color-gainsboro-100: #ddd;
  --color-gainsboro: #d9d9d9;
  --color-black: #000;
  --color-black-100: #000000;
  --color-darkslategray: #444;
  --color-darkslategray-100: #333;
  --color-darkslategray-200: #2f2f2f;
  --color-gray: #8d8d8d;
  --color-gray-100: #888;
  --color-lightgray: #d2d2d2;
  --color-whitesmoke: #f7f9fb;
  --color-steelblue: #5b83c0;
  --color-silver: #bfbfbf;
  --color-darkseagreen-100: #93ba76;
  --color-darkseagreen-200: #8eba76;

  /* Gaps */
  --gap-11xl: 30px;
  --gap-21xl: 40px;
  --gap-8xs: 5px;
  --gap-3xs: 10px;
  --gap-xl: 20px;
  --gap-9xs: 4px;
  --gap-mini: 15px;
  --gap-31xl: 50px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-11xl: 30px;
  --padding-sm: 14px;
  --padding-7xs: 6px;
  --padding-xs: 12px;
  --padding-9xs: 4px;
  --padding-smi: 13px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-mini: 15px;

  /* border radiuses */
  --br-9xs: 4px;
  --br-8xs: 5px;
  --br-xl: 20px;
  --br-3xs: 10px;
}

.field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
}

.field input,
.field label {
  -webkit-transition: all 0.05s;
  transition: all 0.05s;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.field label {
  font-size: 12px;
}

.field input {
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  height: 30px;
  line-height: 30px;
}

.field input:focus {
  outline: 0;
}

.field input::-moz-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit;
}

.field input:focus::-moz-placeholder {
  opacity: 1;
}

.field input::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: inherit;
  transition: inherit;
}

.field input:focus::-webkit-input-placeholder {
  opacity: 1;
}

.field input:placeholder-shown + label {
  cursor: text;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: translate(0, 1.125rem) scale(1.1);
  transform: translate(0, 1.125rem) scale(1.1);
}

.field input:not(:placeholder-shown) + label,
.field input:focus + label {
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  cursor: pointer;
  color: #118d45;
  margin-bottom: 3px;
}
