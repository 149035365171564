$media-breakpoints: (
  iphone: 415,
  xs: 576,
  sm: 768,
  md: 1025,
  lg: 1280,
  xl: 1440,
  mini-tab: 991,
  mini-tab-max: 992,
);
@mixin media-min($sz: "xs") {
  $size: map-get($media-breakpoints, $sz);
  @media (min-width: #{$size}px) {
    @content;
  }
}
@mixin media-max($sz: "xs") {
  $val: map-get($media-breakpoints, $sz);

  @media (max-width: #{$val}px) {
    @content;
  }
}

/**********************colors mixins**************/
$clr-green: #50b041;
$clr-brown: #c57329;
$clr-lightgray: #c0c0c0;
$clr-gray: #636262;
$clr-white: #fff;
$clr-black: #333;

// transition
$tr-sm: 0.1s;
$tr-md: 0.2s;
$tr-lg: 0.3s;
$tr-xl: 0.4s;

@function px-to-rem($size: 16) {
  @return $size/16 + rem;
}
