.logo,
.logo1,
.logo2,
.logo3,
.logo4,
.logo5,
.logo6,
.logo7,
.logo8,
.logo9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.logo-a,
.logo-a2,
.logo-a3,
.logo-a5,
.logo-a6,
.logo-a7,
.logo-a8,
.logo-a9 {
  position: relative;
}
.aarey-forest-phase,
.aarey-forest-phase10,
.aarey-forest-phase20,
.aarey-forest-phase30,
.aarey-forest-phase40,
.aarey-forest-phase50,
.aarey-forest-phase60,
.aarey-forest-phase70,
.aarey-forest-phase80,
.aarey-forest-phase90 {
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.header-left-content,
.page-title,
.header-left-content1,
.page-title1,
.header-left-content2,
.page-title2,
.header-left-content3,
.page-title3,
.header-left-content4,
.page-title4,
.header-left-content5,
.page-title5,
.header-left-content6,
.page-title6,
.header-left-content7,
.page-title7,
.header-left-content8,
.page-title8,
.header-left-content9,
.page-title9 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  align-items: center;
  justify-content: flex-start;
}
.header-left-content,
.header-left-content1,
.header-left-content2,
.header-left-content3,
.header-left-content4,
.header-left-content5,
.header-left-content6,
.header-left-content7,
.header-left-content8,
.header-left-content9 {
  flex: 1;
  gap: var(--gap-xl);
}
.header-right-content-item,
.header-right-content-child,
.header-right-content-child1,
.header-right-content-child2,
.header-right-content-child3,
.rectangle-icon,
.header-right-content-child4,
.header-right-content-child5,
.header-right-content-child6,
.header-right-content-inner {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.headereco,
.header-right-content,
.header-right-content1,
.header1,
.header-right-content2,
.header2,
.header-right-content3,
.header3,
.header-right-content4,
.header4,
.header-right-content5,
.header5,
.header-right-content6,
.header6,
.header-right-content7,
.header7,
.header-right-content8,
.header8,
.header-right-content9,
.header9 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.header-right-content,
.header-right-content1,
.header-right-content2,
.header-right-content3,
.header-right-content4,
.header-right-content5,
.header-right-content6,
.header-right-content7,
.header-right-content8,
.header-right-content9 {
  width: 123px;
  gap: var(--gap-11xl);
}
.headereco,
.header1,
.header2,
.header3,
.header4,
.header5,
.header6,
.header7,
.header8,
.header9 {
  background: linear-gradient(180deg, #00a76b 48.44%, #008e5b);
  overflow: hidden;
  padding: 0 0 0 var(--padding-xl);
  gap: var(--gap-3xs);
}
.dashboard,
.tree,
.dashboard1,
.tree1,
.dashboard2,
.tree2,
.dashboard3,
.tree3,
.dashboard4,
.tree4,
.dashboard5,
.tree5,
.dashboard6,
.tree6,
.dashboard7,
.tree7,
.dashboard8,
.tree8,
.dashboard9,
.tree9 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-family: var(--font-treeties);
  color: var(--color-gray-100);
  text-align: left;
  display: inline-block;
}
.dashboard1,
.tree1,
.dashboard2,
.tree2,
.dashboard3,
.tree3,
.dashboard4,
.tree4,
.dashboard5,
.tree5,
.dashboard6,
.tree6,
.dashboard7,
.tree7,
.dashboard8,
.tree8 {
  font-size: var(--font-size-5xl);
}
.dashboard9,
.tree9 {
  font-size: var(--font-size-11xl);
}
.tree,
.tree1,
.tree2,
.tree3,
.tree4,
.tree5,
.tree6,
.tree7,
.tree8,
.tree9 {
  color: var(--color-white);
}
.left-navbar,
.left-navbar1,
.left-navbar2,
.left-navbar3,
.left-navbar4,
.left-navbar5,
.left-navbar6,
.left-navbar7,
.left-navbar8,
.left-navbar9 {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.project-summary,
.project-summary2,
.project-summary4,
.project-summary6,
.project-summary8,
.project-summary13,
.project-summary14,
.project-summary16,
.project-summary19,
.project-summary21 {
  position: relative;
  font-weight: 600;
}
.project-summary13 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.project-summary21 {
  flex: 1;
}
.hero-title,
.hero-title2,
.hero-title4,
.hero-title6,
.hero-title8,
.hero-title12,
.hero-title13,
.hero-title16,
.hero-title19,
.hero-title21 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.aarey-forest-phase1,
.aarey-forest-phase10,
.aarey-forest-phase11,
.aarey-forest-phase21,
.aarey-forest-phase31,
.aarey-forest-phase41,
.aarey-forest-phase51,
.aarey-forest-phase61,
.aarey-forest-phase71,
.aarey-forest-phase81,
.aarey-forest-phase91 {
  position: relative;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray);
}
.content-title-text,
.content-title-text12,
.content-title-text29,
.content-title-text41,
.content-title-text56,
.content-title-text78,
.content-title-text80,
.content-title-text99,
.content-title-text124,
.content-title-text153 {
  flex-direction: column;
  gap: var(--gap-3xs);
}
.content-title-text78 .aarey-forest-phase10 {
  font-size: var(--font-size-2xs);
}
.column-1,
.content-title-text,
.column-13,
.container2,
.content-title-text12,
.content9,
.column-19,
.container6,
.content-title-text41,
.content27,
.column-118,
.container10,
.content-title-text99,
.content54,
.column-115,
.container8,
.content-title-text80,
.content45,
.column-127,
.container15,
.content-title-text153,
.content81,
.column-124,
.container13,
.content-title-text141,
.content72,
.column-16,
.container5,
.content-title-text29,
.content18,
.column-112,
.container7,
.content-title-text56,
.content36,
.column-121,
.container12,
.content-title-text124,
.content63,
.column-13,
.container6,
.content-title-text78,
.content65 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.column-1,
.column-13,
.column-16,
.column-19,
.column-112,
.column-115,
.column-118,
.column-121,
.column-124,
.column-127 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
}
.content,
.img-1-1-icon,
.container2,
.content9,
.container6,
.content27 .container10,
.content54,
.container8,
.content45,
.container15,
.content81,
.container13,
.content72,
.container5,
.content18,
.container7,
.content36,
.container12,
.content63,
.content65 {
  align-self: stretch;
}
.content,
.img-1-1-icon {
  flex: 1;
}
.content {
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) 0 0 var(--padding-11xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.container2,
.container5,
.container6,
.container7,
.container10,
.container8,
.container12,
.container13,
.container15 {
  flex-direction: row;
  padding: var(--padding-xl) 0;
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.content9,
.content18,
.content27,
.content36,
.content54,
.content45,
.content63,
.content65,
.content72,
.content81 {
  flex: 1;
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke);
  flex-direction: column;
  padding: var(--padding-xl) 0 0 var(--padding-11xl);
  gap: var(--gap-3xs);
}
.img-icon {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  max-height: 100%;
  width: 442px;
  object-fit: cover;
}
.img-1-1-icon1,
.img-1-1-icon2,
.img-1-1-icon3,
.img-1-1-icon4,
.img-1-1-icon5,
.img-1-1-icon6,
.img-1-1-icon7,
.img-1-1-icon8 {
  align-self: stretch;
  flex: 1;
}
.img-1-1-icon,
.img-1-1-icon,
.img-1-1-icon2,
.img-1-1-icon3,
.img-1-1-icon4,
.img-1-1-icon5,
.img-1-1-icon6,
.img-1-1-icon7,
.img-1-1-icon8 {
  position: relative;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.img11 {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  width: 465px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.container-1,
.img,
.container-11,
.img1,
.container-13,
.img3.container-16,
.img6,
.container-15,
.img5,
.container-111,
.container-110,
.img10,
.container-12,
.img2,
.container-14,
.img4,
.container-18,
.img9 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.img,
.img1,
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img9,
.img10 {
  width: 465px;
  flex-direction: column;
}
.container-1,
.container-11,
.container-12,
.container-13,
.container-14,
.container-15,
.container-16,
.container-18,
.container-111,
.container-110 {
  border: 1px solid var(--color-lightgray);
  flex-direction: row;
}
.handshake,
.partnerships-5,
.handshake1,
.partnerships-51,
.handshake2,
.partnerships-52,
.handshake3,
.partnerships-53,
.handshake4,
.partnerships-54,
.handshake5,
.partnerships-55,
.handshake6,
.partnerships-56,
.handshake7,
.partnerships-57,
.handshake8,
.partnerships-58,
.handshake9,
.partnerships-59 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  text-align: left;
}
.handshake1,
.partnerships-51,
.handshake2,
.partnerships-52,
.handshake3,
.partnerships-53,
.handshake4,
.partnerships-54,
.handshake6,
.partnerships-56,
.handshake5,
.partnerships-55,
.handshake7,
.partnerships-57 {
  color: var(--color-gainsboro);
}
.handshake8,
.partnerships-58 {
  color: var(--color-mediumseagreen-300);
}
.handshake9,
.partnerships-59 {
  color: var(--color-mediumseagreen-300) !important;
}
.partnerships-5,
.partnerships-51,
.partnerships-52,
.partnerships-53,
.partnerships-54,
.partnerships-55,
.partnerships-56,
.partnerships-57,
.partnerships-58,
.partnerships-59 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
}
.partnerships-51,
.partnerships-52,
.partnerships-53,
.partnerships-54,
.partnerships-55,
.partnerships-56,
.partnerships-57,
.partnerships-58 {
  color: var(--color-darkslategray);
}
.title,
.title8,
.title16,
.title24,
.title32,
.title48,
.title40,
.title56,
.title64,
.title72 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.tick-icon,
.tick-icon1,
.tick-icon2,
.tick-icon3,
.tick-icon4,
.tick-icon5,
.tick-icon6,
.tick-icon7,
.tick-icon8,
.tick-icon9 {
  position: relative;
  width: 9.72px;
  height: 7.07px;
}
.verified,
.verified1,
.verified2,
.verified3,
.verified4,
.verified5,
.verified6,
.verified7,
.verified8,
.verified9 {
  position: relative;
  font-size: var(--font-size-2xs);
  line-height: 14px;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: left;
}
.labels,
.labels8,
.labels16,
.labels24,
.labels32,
.labels48,
.labels40,
.labels56,
.labels64 {
  border-radius: var(--br-xl);
  background-color: var(--color-mediumseagreen-100);
  padding: var(--padding-9xs) var(--padding-3xs);
  justify-content: flex-end;
  gap: var(--gap-9xs);
}
.labels8,
.labels16,
.labels24,
.labels32,
.labels48,
.labels40 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content1,
.labels,
.left-tabs-title,
.content10,
.labels11,
.labels9,
.left-tabs-title8,
.content28,
.labels25,
.labels27,
.left-tabs-title24,
.content55,
.labels49,
.left-tabs-title48,
.content46,
.labels41,
.left-tabs-title40,
.content73,
.labels64,
.left-tabs-title64,
.content82,
.labels72,
.left-tabs-title72,
.content19,
.labels17,
.labels19,
.left-tabs-title16,
.content37,
.labels33,
.labels35,
.left-tabs-title32,
.content64,
.labels56,
.left-tabs-title56 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content1,
.left-tabs-title,
.content10,
.labels11,
.labels9,
.left-tabs-title8,
.content28,
.labels25,
.labels27,
.left-tabs-title24,
.content55,
.labels49,
.left-tabs-title48,
.content46,
.labels41,
.left-tabs-title40,
.content73,
.left-tabs-title64,
.content82,
.left-tabs-title72,
.content19,
.labels17,
.labels19,
.left-tabs-title16,
.content37,
.labels33,
.labels35,
.left-tabs-title32,
.content64,
.left-tabs-title56 {
  justify-content: flex-start;
}
.content1,
.content10,
.content19,
.content28,
.content37,
.content55,
.content46,
.content64,
.content73,
.content82 {
  flex: 1;
}
.left-tabs-title,
.labels11,
.labels9,
.left-tabs-title8,
.labels25,
.labels27,
.left-tabs-title24,
.labels41,
.left-tabs-title40,
.left-tabs-title64,
.left-tabs-title72,
.labels17,
.labels19,
.left-tabs-title16,
.labels33,
.labels35,
.left-tabs-title32,
.left-tabs-title56 {
  box-sizing: border-box;
}
.left-tabs-title,
.left-tabs-title8,
.left-tabs-title16,
.left-tabs-title24,
.left-tabs-title32,
.left-tabs-title40,
.left-tabs-title56,
.left-tabs-title57,
.left-tabs-title64,
.left-tabs-title72 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl);
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  width: 330px;
}
.left-tabs-title57 {
  overflow: hidden;
}
.div52,
.execution-partner-ngo2 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
}
.execution-partner-ngo2 {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div52 {
  text-align: right;
}
.submenu13 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-mediumseagreen-200);
}
.div53,
.institutional-sponsors8 {
  position: relative;
  line-height: 14px;
}
.institutional-sponsors8 {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div53 {
  text-align: right;
}
.submenu14 {
  text-decoration: none;
  flex-direction: row;
  cursor: pointer;
  color: inherit;
}
.submenu12,
.submenu14,
.submenu15 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.submenu15 {
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.submenu12 {
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}
.money-bag56,
.sponsorship8 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gainsboro);
  text-align: left;
}
.sponsorship8 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray);
}
.div,
.institutional-sponsors {
  position: relative;
  line-height: 14px;
}
.institutional-sponsors {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div {
  text-align: right;
}
.submenu1 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: inherit;
}
.div1,
.execution-partner-ngo {
  position: relative;
  line-height: 14px;
}
.execution-partner-ngo {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div1 {
  text-align: right;
}
.submenu2 {
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.submenu,
.submenu2,
.submenu3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.submenu3 {
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.submenu {
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}
.money-bag,
.sponsorship {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gainsboro);
  text-align: left;
}
.sponsorship {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray);
}
.div64,
.execution-partner-ngo4 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
}
.execution-partner-ngo4 {
  flex: 1;
}
.div64 {
  text-align: right;
}
.execution-partner-ngo3 {
  text-decoration: none;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  color: var(--color-darkseagreen-100);
}
.div65,
.institutional-sponsors15 {
  position: relative;
  line-height: 14px;
}
.institutional-sponsors15 {
  flex: 1;
}
.div65 {
  text-align: right;
}
.individual-sponsors,
.partnership-submenu {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.individual-sponsors {
  text-decoration: none;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  color: inherit;
}
.partnership-submenu {
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: column;
  padding: var(--padding-xl);
  justify-content: center;
  gap: var(--gap-xl);
}
.money-bag63,
.sponsorship9 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gainsboro);
  text-align: left;
}
.sponsorship9 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray);
}
.labels1,
.labels3,
.labels11,
.labels9,
.labels25,
.labels27,
.labels41,
.labels73,
.labels75,
.labels65,
.labels67,
.labels17,
.labels19,
.labels33,
.labels35,
.labels59 {
  border-radius: var(--br-xl);
  width: 75px;
  padding: var(--padding-9xs) var(--padding-3xs);
}
.labels1,
.labels3,
.labels73,
.labels75,
.labels65,
.labels67,
.labels57,
.labels59,
.left-tabs-title57 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.labels49,
.labels57 {
  border-radius: var(--br-xl);
  background: linear-gradient(
    89.92deg,
    rgba(233, 164, 100, 0.99) 70.52%,
    #e7ecf1 70.67%
  );
  width: 75px;
  padding: var(--padding-9xs) var(--padding-3xs);
}
.labels1,
.labels9,
.labels17,
.labels25,
.labels33,
.labels65,
.labels73 {
  background: linear-gradient(
    89.92deg,
    rgba(233, 164, 100, 0.99) 70.52%,
    #e7ecf1 70.67%
  );
}
.labels3,
.labels11,
.labels19,
.labels27,
.labels35,
.labels59,
.labels67,
.labels75 {
  background: linear-gradient(89.92deg, #e9a464 36.45%, #e7ecf1 36.46%);
}
.labels43 {
  border-radius: var(--br-xl);
  background: linear-gradient(89.92deg, #e9a464 36.45%, #e7ecf1 36.46%);
  width: 75px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) var(--padding-3xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.not-started,
.not-started2,
.not-started4,
.not-started10,
.not-started12,
.not-started14,
.not-started16,
.not-started18 {
  position: relative;
  font-size: var(--font-size-2xs);
  line-height: 14px;
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
}
.labels4,
.labels12,
.labels20,
.labels28,
.labels36,
.labels44,
.labels51,
.labels52,
.labels60,
.labels76,
.labels68 {
  border-radius: var(--br-xl);
  width: 75px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.labels51 {
  background: linear-gradient(89.92deg, #e9a464 36.45%, #e7ecf1 36.46%);
}
.cost1,
.money-bag13,
.money-bag25,
.objectives3,
.location6,
.money-bag43,
.money-bag37,
.ownership5,
.money-bag19,
.risks2,
.money-bag31,
.money-bag49,
.sponsorship7 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-mediumseagreen-300);
  text-align: left;
}
.not-started8 {
  font-size: var(--font-size-2xs);
  line-height: 14px;
  color: var(--color-gray);
}
.cost1,
.objectives3,
.location6,
.ownership5,
.risks2,
.sponsorship7 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
}
.project-summary1,
.project-summary3,
.project-summary5,
.project-summary7,
.project-summary12,
.project-summary17,
.project-summary22,
.project-summary20 {
  flex: 1;
  position: relative;
}
.hero-title1,
.hero-title3,
.hero-title7,
.hero-title12,
.hero-title22 {
  width: 755px;
  flex-direction: row;
  align-items: flex-start;
}
.hero-title3,
.hero-title7,
.hero-title12,
.hero-title17,
.hero-title22 {
  display: flex;
  justify-content: flex-start;
}
.dropdown-15,
.hero-title5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.hero-title5 {
  width: 755px;
  align-items: flex-start;
}
.hero-title20,
.hero-title17 {
  width: auto;
  flex-direction: row;
  align-items: flex-start;
}
.hero-title7 {
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray);
}
.content-title-text21,
.dropdown-13,
.content-title-text89,
.dropdown-119,
.content-title-text133,
.dropdown-126 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.content-title-text9,
.dropdown-1,
.hero-title1,
.content-title-text150,
.dropdown-130,
.hero-title20 {
  display: flex;
  justify-content: flex-start;
}
.content-title-text21,
.dropdown-13,
.dropdown-126 {
  align-self: stretch;
}
.dropdown-1 {
  align-self: stretch;
  gap: var(--gap-3xs);
  line-height: 30px;
}
.dropdown-1,
.dropdown-13,
.dropdown-15,
.dropdown-113,
.dropdown-119,
.dropdown-123,
.dropdown-126,
.dropdown-130 {
  border-bottom: 1px solid var(--color-darkgray);
  align-items: center;
  outline: 0;
  border-color: #999;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: var(--padding-xs) 0;
}
.dropdown-1,
.dropdown-13,
.dropdown-119,
.dropdown-123,
.dropdown-130 {
  flex-direction: row;
}
.dropdown-15,
.dropdown-113 {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.dropdown-119,
.dropdown-123,
.dropdown-130 {
  align-self: stretch;
  line-height: 30px;
}
.content-title-text9,
.content-title-text21,
.content-title-text89,
.content-title-text133,
.content-title-text150 {
  flex-direction: column;
  align-items: flex-start;
}
.content-title-text133 {
  flex: 1;
}
.content-title-text9,
.content-title-text150 {
  flex: 1;
  gap: var(--gap-8xs);
}
.input-field-text31,
.table-left-title4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: var(--font-size-2xs);
}
.input-field-text31 {
  align-items: flex-start;
  gap: var(--gap-11xl);
  color: var(--color-darkgray);
}
.table-left-title4 {
  background-color: var(--color-darkslategray);
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
  color: var(--color-white);
}
.personnel-lorem-ipsum34 {
  position: relative;
  line-height: 30px;
}
.table-row-content-a30 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-3xs) 0;
  align-items: center;
}
.table-right-title4,
.table-row-content-a30,
.tb-col2 {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
}
.tb-col2 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.table-right-title4 {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-darkslategray);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
}
.text-input-field-14 {
  border: 1px solid var(--color-gainsboro-100);
  background-color: var(--color-white);
  align-self: stretch;
  padding: var(--padding-3xs);
  color: var(--color-black-100);
  border-color: #ddd;
  outline: 0;
}
.table4,
.tb-col3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tb-col3 {
  width: 200px;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.table4 {
  align-self: stretch;
  color: var(--color-black);
}
.input-field-text,
.table-left-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: var(--font-size-2xs);
}
.input-field-text {
  align-items: flex-start;
  gap: var(--gap-11xl);
  color: var(--color-darkgray);
}
.table-left-title {
  border-radius: var(--br-8xs) 0 0 0;
  background-color: var(--color-darkslategray);
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
  color: var(--color-white);
}
.personnel-lorem-ipsum {
  position: relative;
  line-height: 30px;
}
.table-row-content-a {
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-3xs) 0;
  align-items: center;
}
.table-right-title,
.table-row-content-a,
.tb-col {
  display: flex;
  justify-content: flex-start;
}
.tb-col {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.table-right-title {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-darkslategray);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
}
.text-input-field-1 {
  border: 1px solid var(--color-gainsboro-100);
  background-color: var(--color-white);
  align-self: stretch;
  padding: var(--padding-3xs);
  color: var(--color-black-100);
  border-color: #ddd;
  outline: 0;
}
.table,
.tb-col1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tb-col1 {
  width: 200px;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.table {
  align-self: stretch;
  color: var(--color-black);
}
.radio-button-yes {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: var(--padding-7xs) 0;
  align-items: center;
  justify-content: flex-start;
}
.radio-button-input-text {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-3xs);
}
.content-title-text90,
.input-field-text15,
.radio-button-input-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text90 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs);
}
.input-field-text15 {
  flex-direction: row;
  gap: var(--gap-31xl);
  z-index: 0;
}
.content-title-text108,
.dropdown-123 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.content-title-text108 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.enter-input-text-1 {
  border: 0;
  font-family: var(--font-poppins);
  font-size: var(--font-size-smi);
  background-color: transparent;
  align-self: stretch;
  border-bottom: 1px solid var(--color-silver);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
}
.content-title-text109 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: var(--padding-base) 0 0;
  align-items: flex-start;
  justify-content: flex-end;
}
.input-field-text20 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.container-17 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.close7 {
  position: absolute;
  margin: 0 !important;
  top: 10px;
  left: 501px;
  z-index: 0;
}
.content-title-text110 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.radio-button-input-text2,
.radio-button-yes2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.radio-button-yes2 {
  cursor: pointer;
  padding: var(--padding-7xs) 0;
  align-items: center;
}
.radio-button-input-text2 {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.text-input-field-3,
.text-input-field-36,
.text-input-field-37,
.text-input-field-319,
.text-input-field-315 {
  border: #d9d9d9;
  background-color: transparent;
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkgray);
  padding: var(--padding-3xs);
}
.text-input-field-3:active,
.text-input-field-36:active,
.text-input-field-37:active,
.text-input-field-319:active,
.text-input-field-315:active {
  padding: var(--padding-3xs);
  border: 0;
  border-bottom: 2px solid red;
}
.content-title-text22 {
  align-self: stretch;
  flex-direction: column;
}
.dropdown-list {
  align-self: stretch;
}
.content-title-text-input {
  flex: 1;
  flex-direction: column;
  padding: 0 var(--padding-11xl) 0 0;
  gap: var(--gap-3xs);
}
.column-content,
.content-title-text22,
.input-field-text2,
.content-title-text92,
.input-field-text16,
.content-title-text-input,
.content-title-text-input2,
.input-field-text32,
.content-title-text38,
.input-field-text7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text38 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs);
}
.input-field-text7 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-11xl);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.content-title-text92 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
}
.input-field-text16 {
  width: 600px;
  flex-direction: row;
  gap: var(--gap-31xl);
  z-index: 1;
}
.select-spoc-for {
  position: relative;
  font-weight: 600;
}
.input-area-field-child {
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkgray);
}
.input-area-field {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px;
}
.input-field-text17,
.input-field-text18 {
  width: 600px;
  flex-direction: row;
  gap: var(--gap-31xl);
}
.input-field-text17 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.input-field-text18 {
  z-index: 3;
}
.content-title-text98,
.input-field-text18,
.input-field-text19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.input-field-text19 {
  width: 600px;
  flex-direction: row;
  gap: var(--gap-31xl);
  z-index: 4;
}
.content-title-text98 {
  flex-direction: column;
  gap: var(--gap-3xs);
  z-index: 5;
}
.table-left-title3,
.table-right-title3 {
  background-color: var(--color-darkslategray);
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
}
.table-left-title3 {
  flex: 1;
  border-radius: var(--br-8xs) 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.table-right-title3 {
  border-radius: 0 var(--br-8xs) 0 0;
}
.table-header-row,
.table-right-title3,
.table-row-content-a26 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.table-header-row {
  align-self: stretch;
  align-items: flex-start;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.table-row-content-a26 {
  flex: 1;
  border-bottom: 1px solid var(--color-gainsboro);
  padding: var(--padding-3xs) var(--padding-3xs) 0;
  align-items: center;
}
.select {
  position: relative;
  line-height: 30px;
}
.table-row,
.table-row-content-link,
.table3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.table-row-content-link {
  border-bottom: 1px solid var(--color-gainsboro);
  padding: var(--padding-3xs) var(--padding-xl) 0;
  align-items: center;
  color: var(--color-steelblue);
}
.table-row,
.table3 {
  align-self: stretch;
  align-items: flex-start;
}
.table3 {
  flex-direction: column;
  z-index: 6;
  font-size: var(--font-size-smi);
  color: var(--color-black);
}
.input-field-text2,
.content-title-text-input2 {
  flex: 1;
  flex-direction: column;
}
.content-title-text-input2 {
  gap: var(--gap-3xs);
}
.column-content,
.input-field-text32 {
  align-self: stretch;
  flex-direction: row;
}
.input-field-text32 {
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.column-content {
  gap: var(--gap-21xl);
  z-index: 0;
}
.close,
.close2,
.close3,
.clos4,
.close5,
.close6,
.clos7 {
  position: absolute;
  margin: 0 !important;
  top: 10px;
  right: 10px;
  font-size: var(--font-size-3xl);
  font-family: var(--font-treeties);
  color: var(--color-steelblue);
  z-index: 1;
}
.container3,
.container9 {
  align-self: stretch;
  border-radius: var(--br-9xs);
  border: 1px solid var(--color-gainsboro-100);
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.container9 {
  box-sizing: border-box;
  position: relative;
}
.add-more,
.add-more3,
.container3,
.plus {
  position: relative;
}
.add-more,
.add-more3 {
  font-size: var(--font-size-2xs);
  text-decoration: underline;
  font-family: var(--font-poppins);
}
.cta-add-more,
.table-container,
.content-area,
.cta-add-more3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta-add-more,
.cta-add-more3 {
  flex-direction: row;
  gap: var(--gap-8xs);
  font-size: var(--font-size-3xs);
  color: var(--color-steelblue);
  font-family: var(--font-treeties);
}
.table-left-title5 {
  flex: 1;
  border-radius: var(--br-8xs) 0 0 0;
  background-color: var(--color-darkslategray);
  padding: var(--padding-3xs) var(--padding-xl);
}
.table-header-row1,
.table-left-title5,
.table-right-title5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-right-title5 {
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-darkslategray);
  padding: var(--padding-3xs) var(--padding-xl);
}
.table-header-row1 {
  align-self: stretch;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.personnel-lorem-ipsum38 {
  position: relative;
  line-height: 30px;
}
.table-row-content {
  flex: 1;
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl);
}
.table-row-content,
.table-row-content-link4,
.table5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.table-row-content-link4 {
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-xl);
  color: var(--color-steelblue);
}
.table-container,
.content-area {
  flex-direction: column;
  gap: var(--gap-3xs);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.table-container {
  align-self: stretch;
}
.table5 {
  align-self: stretch;
  flex-direction: column;
  color: var(--color-black);
}
.content-title-text112,
.left-content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text112 {
  align-self: stretch;
}
.left-content1 {
  flex: 1;
  padding: var(--padding-11xl) 0;
  gap: var(--gap-xl);
  z-index: 1;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.img-2-1-icon {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: var(--br-3xs);
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.img7,
.land-parecel-content-area1 {
  display: flex;
  align-items: flex-start;
}
.img7 {
  width: 358px;
  height: 351px;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
}
.land-parecel-content-area1 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke);
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xl);
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-11xl);
}
.land-parcels-container1 {
  width: 850px;
}
.content-title-text121,
.land-parcels-container1,
.left-content2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text121 {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.left-content2 {
  flex: 1;
  padding: var(--padding-11xl) 0;
  gap: var(--gap-xl);
  z-index: 0;
}
.close8 {
  position: absolute;
  margin: 0 !important;
  top: 10px;
  left: 501px;
  font-size: var(--font-size-3xl);
  font-family: var(--font-treeties);
  color: var(--color-steelblue);
  z-index: 1;
}
.img-2-1-icon1 {
  position: relative;
  border-radius: var(--br-3xs);
  width: 358px;
  height: 284px;
  object-fit: cover;
}
.img8,
.land-parcels-container2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.img8 {
  justify-content: center;
  z-index: 2;
}
.land-parcels-container2 {
  width: 850px;
  justify-content: flex-start;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.add-more4 {
  position: relative;
  font-size: var(--font-size-2xs);
  text-decoration: underline;
  font-family: var(--font-poppins);
}
.container11,
.cta-add-more4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--font-size-3xs);
}
.container11 {
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--font-size-3xl);
  color: var(--color-steelblue);
  font-family: var(--font-treeties);
}
.content-title-text50,
.dropdown-18 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.content-title-text50 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.dropdown-18 {
  border-bottom: 1px solid var(--color-darkgray);
  flex-direction: row;
  align-items: center;
  gap: var(--gap-3xs);
  outline: 0;
  border-color: #999;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: var(--padding-xs) 0;
}
.content-title-text51,
.input-field-text8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text51 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs);
}
.input-field-text8 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-11xl);
}
.back,
.back2,
.back3,
.back4,
.back5,
.back6,
.back7,
.save-continue,
.save-continue8,
.save-continue9 {
  position: relative;
  font-size: var(--font-size-smi);
  font-weight: 600;
  font-family: var(--font-poppins);
  text-align: center;
}
.back,
.back1,
.back2,
.back3,
.back4,
.back5,
.back6,
.back7 {
  color: var(--color-mediumseagreen-200);
}
.save-continue,
.save-continue8,
.save-continue9 {
  color: var(--color-white);
}
.cta,
.cta1,
.cta3,
.cta5,
.cta7,
.cta9,
.cta11,
.cta13,
.cta15 {
  cursor: pointer;
  padding: var(--padding-sm) var(--padding-xl);
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta1,
.cta3,
.cta5,
.cta7,
.cta9,
.cta11,
.cta13 {
  border: 1px solid var(--color-mediumseagreen-200);
  background-color: var(--color-white);
}
.cta,
.cta16,
.cta15 {
  background-color: var(--color-mediumseagreen-200);
}
.cta,
.cta15 {
  border: 0;
}
.save-continue1,
.save-continue2,
.save-continue3,
.save-continue4,
.save-continue5,
.save-continue6,
.save-continue7 {
  position: relative;
  font-size: var(--font-size-smi);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
}
.action-buttons,
.cta2,
.action-buttons2,
.cta6,
.action-buttons5,
.cta12,
.action-buttons4,
.cta10,
.action-buttons1,
.cta4,
.action-buttons3,
.cta8,
.action-buttons6,
.cta14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta2,
.cta4,
.cta6,
.cta8,
.cta12,
.cta10,
.cta14 {
  cursor: pointer;
  border: 1px solid var(--color-mediumseagreen-200);
  padding: var(--padding-sm) var(--padding-xl);
  background-color: var(--color-mediumseagreen-200);
  border-radius: var(--br-9xs);
}
.action-buttons,
.action-buttons1,
.action-buttons2,
.action-buttons3,
.action-buttons4,
.action-buttons5,
.action-buttons6,
.action-buttons7 {
  gap: var(--gap-xl);
}
.right-content-area,
.right-content-area1,
.right-content-area2,
.right-content-area3,
.right-content-area4,
.right-content-area5,
.right-content-area6,
.right-content-area7,
.right-content-area8,
.right-content-area9 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl) 0 0;
  gap: var(--gap-11xl);
}
.right-content-area6 {
  align-self: stretch;
}
.right-content-area,
.right-content-area4,
.right-content-area8,
.right-content-area9 {
  color: var(--color-darkslategray);
}
.container-2,
.middle-content-area,
.right-content-area,
.container-21,
.middle-content-area1,
.right-content-area1,
.container-23,
.middle-content-area3,
.right-content-area3,
.container-26,
.middle-content-area6,
.right-content-area6,
.container-25,
.middle-content-area5,
.right-content-area5,
.container-210,
.middle-content-area9,
.right-content-area9,
.container-29,
.middle-content-area8,
.right-content-area8,
.container-22,
.middle-content-area2,
.right-content-area2,
.container-24,
.middle-content-area4,
.right-content-area4,
.container-27,
.middle-content-area7,
.right-content-area7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container-2,
.container-21,
.container-22,
.container-23,
.container-24,
.container-25,
.container-26,
.container-27,
.container-210,
.container-29 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
}
.container-210,
.container-2,
.container-29 {
  color: var(--color-black);
}
.container-2,
.container-21,
.container-22,
.container-27,
.container-29 {
  font-size: var(--font-size-smi);
}
.container-26 {
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.middle-content-area,
.middle-content-area1,
.middle-content-area2,
.middle-content-area3,
.middle-content-area4,
.middle-content-area5,
.middle-content-area6,
.middle-content-area7,
.middle-content-area8,
.middle-content-area9 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl);
  gap: var(--gap-11xl);
}
.notifications-bell,
.plus-circle,
.notifications-bell1,
.plus-circle1,
.notifications-bell2,
.plus-circle2,
.notifications-bell3,
.plus-circle3,
.notifications-bell4,
.plus-circle4,
.notifications-bell5,
.plus-circle5,
.notifications-bell6,
.plus-circle6,
.notifications-bell7,
.plus-circle7,
.notifications-bell8,
.plus-circle8 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-family: var(--font-treeties);
  color: var(--color-mediumseagreen-300);
  text-align: left;
  display: inline-block;
}
.notifications-bell1,
.plus-circle1,
.notifications-bell3,
.plus-circle3,
.notifications-bell6,
.plus-circle6,
.notifications-bell5,
.plus-circle5 {
  font-size: var(--font-size-5xl);
}
.notifications-bell9,
.plus-circle9 {
  font-size: var(--font-size-11xl);
}
.notifications-bell,
.notifications-bell1,
.notifications-bell2,
.notifications-bell3,
.notifications-bell4,
.notifications-bell5,
.notifications-bell8,
.notifications-bell6,
.notifications-bell7,
.notifications-bell9 {
  color: var(--color-darkgray);
}
.right-navbar,
.right-navbar1,
.right-navbar2,
.right-navbar3,
.right-navbar4,
.right-navbar5,
.right-navbar6,
.right-navbar7,
.right-navbar8,
.right-navbar9 {
  align-self: stretch;
  background-color: var(--color-aliceblue);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  gap: var(--gap-11xl);
}
.right-navbar,
.treeties-addproject-forms1-par,
.right-navbar1,
.treeties-addproject-forms1-cos,
.right-navbar3,
.treeties-addproject-forms1-obj,
.right-navbar6,
.treeties-addproject-forms1-loc,
.right-navbar5,
.treeties-addproject-forms1-own,
.right-navbar9,
.treeties-addproject-forms1-par2,
.right-navbar8,
.treeties-addproject-forms1-par1,
.right-navbar2,
.treeties-addproject-forms1-ris,
.right-navbar4,
.treeties-addproject-forms1-sit,
.right-navbar7,
.treeties-addproject-forms1-spo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.main-content-area,
.main-content-area1,
.main-content-area2,
.main-content-area3,
.main-content-area4,
.main-content-area5,
.main-content-area6,
.main-content-area7,
.main-content-area8,
.main-content-area9 {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.treeties-addproject-forms1-cos,
.treeties-addproject-forms1-loc,
.treeties-addproject-forms1-obj,
.treeties-addproject-forms1-own,
.treeties-addproject-forms1-par2,
.treeties-addproject-forms1-par,
.treeties-addproject-forms1-par1,
.treeties-addproject-forms1-ris,
.treeties-addproject-forms1-sit,
.treeties-addproject-forms1-spo {
  position: relative;
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-white);
  font-family: var(--font-treeties);
}
.div30,
.execution-partner-ngo1 {
  position: relative;
  line-height: 14px;
  font-weight: 600;
}
.execution-partner-ngo1 {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div30 {
  text-align: right;
  display: none;
}
.submenu9 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-mediumseagreen-200);
}
.div31,
.fauna {
  position: relative;
  line-height: 14px;
}
.fauna {
  flex: 1;
}
.div31 {
  text-align: right;
}
.submenu-child,
.submenu-child1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.submenu-child1 {
  text-decoration: none;
  width: 290px;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  box-sizing: border-box;
  color: inherit;
}
.submenu-child {
  align-self: stretch;
  height: 38px;
  flex-direction: column;
  gap: 16px;
}
.div33,
.institutional-sponsors6 {
  position: relative;
  line-height: 14px;
}
.institutional-sponsors6 {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div33 {
  text-align: right;
  display: none;
}
.submenu10,
.submenu8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.submenu10 {
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.submenu8 {
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}
.project-summary9 {
  flex: 1;
  position: relative;
}
.dropdown-113,
.hero-title9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.hero-title9 {
  width: 755px;
  align-items: flex-start;
}
.content-title-text65,
.input-field-text10 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text65 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-3xs);
}
.input-field-text10 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-11xl);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.select-year {
  align-self: stretch;
}
.content-title-text69 {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-more1,
.flora-details {
  position: relative;
}
.add-more1 {
  font-size: var(--font-size-2xs);
  text-decoration: underline;
  font-family: var(--font-poppins);
}
.cta-add-more1,
.table-left-title1,
.text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.cta-add-more1 {
  align-items: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--font-size-3xs);
  color: var(--color-steelblue);
  font-family: var(--font-treeties);
}
.table-left-title1,
.text-container {
  align-items: center;
}
.text-container {
  gap: var(--gap-3xs);
}
.table-left-title1 {
  align-self: stretch;
  border-radius: var(--br-8xs) 0 0 0;
  background-color: var(--color-darkslategray);
  padding: var(--padding-sm) var(--padding-3xs);
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.personnel-lorem-ipsum4 {
  position: relative;
  line-height: 30px;
}
.col,
.table-row-content-a4 {
  display: flex;
  justify-content: flex-start;
}
.table-row-content-a4 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-3xs) 0;
  align-items: center;
}
.col {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.table-center-title,
.table-center-title1 {
  align-self: stretch;
  background-color: var(--color-darkslategray);
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.table-center-title1 {
  flex: 1;
}
.col2 {
  width: 130px;
  height: 154px;
  flex-direction: column;
  align-items: flex-start;
}
.col2,
.col5,
.table-right-title1 {
  display: flex;
  justify-content: flex-start;
}
.col5 {
  width: 200px;
  flex-direction: column;
  align-items: flex-start;
}
.table-right-title1 {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-darkslategray);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.personnel-lorem-ipsum16 {
  flex: 1;
  position: relative;
  line-height: 30px;
}
.close2 {
  position: relative;
  font-size: var(--font-size-mini);
  font-family: var(--font-treeties);
  color: var(--color-steelblue);
}
.table-row-content-a-close {
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  display: flex;
  flex-direction: row;
  padding: var(--padding-smi) var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.content-title-text71,
.table-content-1,
.table1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.table1 {
  flex-direction: row;
  color: var(--color-darkslategray-100);
}
.content-title-text71,
.table-content-1 {
  flex-direction: column;
  gap: var(--gap-mini);
}
.content-title-text71 {
  flex: 1;
  gap: var(--gap-3xs);
}
.column-content2,
.dropdown-117 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.dropdown-117 {
  border-bottom: 1px solid var(--color-darkgray);
  align-items: center;
  gap: var(--gap-3xs);
  outline: 0;
  border-color: #999;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: var(--padding-xs) 0;
}
.column-content2 {
  align-self: stretch;
  padding: 0 var(--padding-xl) 0 0;
  align-items: flex-start;
  gap: var(--gap-xl);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.left-content {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl) 0;
  gap: var(--gap-xl);
}
.land-parcels-container,
.land-parecel-content-area,
.left-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.land-parecel-content-area {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-whitesmoke);
  flex-direction: row;
  padding: 0 0 0 var(--padding-11xl);
}
.land-parcels-container {
  flex-direction: column;
}
.enter-input-text-11,
.radio-button-yes4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.radio-button-yes4 {
  cursor: pointer;
  padding: var(--padding-7xs) 0;
}
.enter-input-text-11 {
  border: 0;
  font-family: var(--font-poppins);
  font-size: var(--font-size-smi);
  background-color: transparent;
  border-bottom: 1px solid var(--color-silver);
  padding: var(--padding-5xs) var(--padding-3xs);
}
.input-field-text25,
.radio-button-input-text4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.radio-button-input-text4 {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.input-field-text25 {
  gap: var(--gap-21xl);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.institutional-sponsorship-ass {
  position: relative;
  display: inline-block;
  width: 705px;
}
.container-19,
.input-field-text26 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.container-19 {
  gap: var(--gap-11xl);
}
.div,
.institutional-sponsors {
  position: relative;
  line-height: 14px;
}
.institutional-sponsors {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div {
  text-align: right;
}
.submenu1 {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  color: inherit;
}
.execution-partner-ngo {
  display: inline-block;
  width: 282px;
  flex-shrink: 0;
}
.div1 {
  text-align: right;
}
.submenu,
.submenu2,
.submenu3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.submenu3 {
  text-decoration: none;
  flex-direction: row;
  color: inherit;
}
.submenu {
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: column;
  padding: var(--padding-xl);
  gap: var(--gap-xl);
}
.money-bag,
.handshake,
.sponsorship {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gainsboro);
  text-align: left;
}
.sponsorship {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray);
}
.input-field-text,
.table-left-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: var(--font-size-2xs);
}
.input-field-text {
  align-items: flex-start;
  gap: var(--gap-11xl);
  color: var(--color-darkgray);
}
.table-left-title {
  border-radius: var(--br-8xs) 0 0 0;
  background-color: var(--color-darkslategray);
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
  color: var(--color-white);
}
.personnel-lorem-ipsum {
  position: relative;
  line-height: 30px;
}
.table-row-content-a {
  align-self: stretch;
  border-bottom: 1px solid var(--color-gainsboro);
  flex-direction: row;
  padding: var(--padding-7xs) var(--padding-3xs) 0;
  align-items: center;
}
.table-right-title,
.table-row-content-a,
.tb-col {
  display: flex;
  justify-content: flex-start;
}
.tb-col {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.table-right-title {
  align-self: stretch;
  border-radius: 0 var(--br-8xs) 0 0;
  background-color: var(--color-darkslategray);
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-3xs);
  align-items: center;
}
.text-input-field-1 {
  border: 1px solid var(--color-gainsboro-100);
  background-color: var(--color-white);
  align-self: stretch;
  padding: var(--padding-3xs);
  color: var(--color-black-100);
  border-color: #ddd;
  outline: 0;
}
.table,
.tb-col1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.tb-col1 {
  width: 200px;
  font-size: var(--font-size-2xs);
  color: var(--color-white);
}
.table {
  align-self: stretch;
  color: var(--color-black);
}
/******common css for all eco tab pages********/
.ecosysobjtab_cont {
  background: #fff;
  margin-left: 60px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.ecosyscom_cont {
  margin-left: 60px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}
.active_tab .title div {
  color: #50b041;
}
.ecosys_middlecont {
  overflow: hidden;
  width: 100%;
  height: 500px;
}
.ecosys_leftcont::-webkit-scrollbar,
.ecosys_rightcont::-webkit-scrollbar {
  width: 10px;
}
.ecosys_leftcont::-webkit-scrollbar-track,
.ecosys_rightcont::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.ecosys_leftcont::-webkit-scrollbar-thumb,
.ecosys_rightcont::-webkit-scrollbar-thumb {
  background: #50b041;
  border-radius: 10px;
}
.ecosys_leftcont {
  float: left;
  width: 330px;
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
}
.ecosys_rightcont {
  float: right;
  width: calc(100% - 330px);
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.ecosys_rightcont input,
.ecosys_rightcont select {
  background-color: transparent;
}
.ecosys_rightcont input:focus,
.ecosys_rightcont select:focus {
  border: none;
  border-bottom: 1px solid #999;
}
.ecosys_rightcont input:focus-visible {
  outline: none;
}
.ecoprosumry_cont .project-summary13 .content65 .container6 > div {
  gap: 20px;
}
.ecoprosumry_cont .project-summary13 .content65 .container6 > div > div {
  height: 40px;
  gap: 5px;
}
.cta1:hover,
.cta3:hover,
.cta5:hover,
.cta9:hover,
.cta11:hover,
.cta13:hover {
  background-color: var(--color-mediumseagreen-200);
}
.cta1:hover .back,
.cta3:hover .back1,
.cta5:hover .back2,
.cta9:hover .back4,
.cta11:hover .back5,
.cta13:hover .back6 {
  color: var(--color-white);
}
.ecopartcont .ecosys_rightcont div .addbtnnewstyle {
  border: none;
  background: #50b041;
  color: #fff;
  border-radius: 4px;
  padding: 0 10px;
}
/****cost tab custom css****/
.costclosetabbtn {
  position: absolute;
  right: 20px;
  top: 15px;
}
.ecosys_rightcont .table-container .column-content .input-field-text2 > div {
  gap: 10px;
}
.ecosys_rightcont
  .table-container
  .column-content
  .input-field-text2
  > div
  input {
  height: 25px;
  line-height: 25px;
  padding: 0;
  width: 100%;
}
.ecocostmid_cont .ecosys_rightcont .costtotalcont input:focus {
  border: 1px solid #ced4da;
}
.ecocostmid_cont .ecosys_rightcont .cta-add-more .addbtnstyle {
  text-decoration: none;
}
/****location tab custom css****/
.addproformloc_cont .project-summary13 {
  border: none;
}
.addproformloc_cont .noofwaterinputs_wrap .content-title-text108 {
  gap: 10px;
}
.addproformloc_cont .noofwaterinputs_wrap .content-title-text108 input,
.addproformloc_cont .noofwaterinputs_wrap .content-title-text108 select,
.addproformloc_cont
  .left-content1
  .column-118
  .content-title-text110
  input[type="text"],
.addproformloc_cont .left-content1 .column-118 .content-title-text110 select,
.addproformloc_cont
  .left-content1
  .column-118
  .content-title-text112
  input[type="text"],
.addproformloc_cont .left-content1 .column-118 .content-title-text112 select {
  height: 25px;
  line-height: 25px;
  width: 100%;
  padding: 0;
  font-size: 13px;
  font-family: var(--font-poppins);
}
.addproformloc_cont .left-content1 input[type="text"]::placeholder,
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div
  input[type="number"]::placeholder {
  color: var(--color-darkslategray);
}
.addproformloc_cont .left-content2 .container-26 .column-118 div select {
  padding: 9px 0;
}
.addproformloc_cont .left-content1 .column-118,
.addproformloc_cont .left-content2 .column-118 {
  gap: 10px;
}
.addproformloc_cont .left-content1,
.addproformloc_cont .left-content2 {
  padding-bottom: 0 !important;
}
.addproformloc_cont .left-content1 .column-118 .content-title-text110,
.addproformloc_cont .left-content1 .column-118 .content-title-text112 {
  gap: 10px;
}
.addproformloc_cont
  .left-content2
  .container-26
  .column-118
  .content-title-text112,
.addproformloc_cont
  .left-content2
  .container-26
  .column-118
  .content-title-text121 {
  gap: 10px;
}
.addproformloc_cont
  .left-content2
  .container-26
  .column-118
  .content-title-text112
  .hero-title13 {
  white-space: nowrap;
}
.addproformloc_cont .land-parcels-container1,
.addproformloc_cont .land-parcels-container2 {
  width: 100%;
}
.ecolocpagemap_cont {
  width: 358px;
  height: 351px;
  position: relative;
  border-radius: 10px;
}
.exoloctab_cont
  .addproformloc_cont
  .table4
  .hero-title19
  .tb-col2:first-child
  .table-left-title4 {
  border-radius: 5px 0 0 0;
}
.exoloctab_cont
  .addproformloc_cont
  .table4
  .hero-title19
  .tb-col2:last-child
  .table-left-title4 {
  border-radius: 0 5px 0 0;
}
.exoloctab_cont .addproformloc_cont .table4 div {
  width: 100%;
}
.exoloctab_cont .addproformloc_cont .table4 div .hero-title19 .tb-col2 div div {
  height: 45px;
  line-height: 45px;
  font-size: 13px;
}
.exoloctab_cont .addproformloc_cont .table4 div .hero-title19 .tb-col2 div {
  padding: 0 5px;
}
.exoloctab_cont
  .addproformloc_cont
  .table4
  div
  .hero-title19
  .tb-col2
  div
  div:empty {
  height: 45px;
  line-height: 45px;
}
.exoloctab_cont
  .addproformloc_cont
  .table4
  div
  .hero-title19
  .tb-col2
  div
  a:first-child {
  margin-right: 10px;
}
.exoloctab_cont .addproformloc_cont .table4 div .hero-title19 .tb-col2 div a i {
  font-size: 14px;
}
.exoloctab_cont .addproformloc_cont .cta-add-more4 .add-more4 {
  text-decoration: none;
}
.exoloctab_cont .addproformloc_cont .cta-add-more4 .add-more4 .addbtnstyle {
  height: 25px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  font-size: 13px;
  text-align: center;
}
.exoloctab_cont
  .addproformloc_cont
  .cta-add-more4
  .add-more4
  .addbtnstyle:hover {
  color: #fff;
}
/****Objective tab custom css******/
.ecosysobjtab_cont .ecosys_rightcont .input-field-text25 {
  gap: 20px;
}
.ecosysobjtab_cont .ecosys_rightcont .input-field-text25 > div {
  gap: 10px;
  margin: 0 0 20px;
}
.ecosysobjtab_cont .ecosys_rightcont .input-field-text25 > div input,
.ecosysobjtab_cont .ecosys_rightcont .input-field-text25 > div select {
  height: 25px;
  line-height: 25px;
  padding: 0;
  width: 100%;
}
.ecosysobjtab_cont
  .ecosys_rightcont
  .input-field-text10
  .content-title-text65
  input[type="date"] {
  width: 100%;
  height: 25px;
  border: none;
  border-bottom: 1px solid #999;
}
.flora-details {
  font-weight: bold;
}
/****Ownership tab custom css******/
.ecoown_cont .content-title-text98 select {
  width: 100%;
}
.ecoown_cont .ownerTable .table4 .hero-title19 > div {
  font-size: 11px;
}
.ecoown_cont .ownerTable .innerOwnerTable .inner-title > div {
  font-size: 13px;
  line-height: 20px;
}
.ecoown_cont .fullwidthspon .content-title-text89 > div:last-child,
.ecoown_cont
  .fullwidthspon
  .content-title-text89
  > div:last-child
  .multiselector_cont
  input {
  width: 100%;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div {
  padding: 12px 0;
  height: 50px;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div input:focus {
  border-bottom: none;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div {
  padding: 12px 0;
  height: 50px;
}

.ecoown_cont .ecosys_rightcont .table3 .table-row > div:last-child {
  display: flex;
  justify-content: flex-end;
}
.ecoown_cont .ecosys_rightcont .table3 .table-header-row > div {
  width: auto;
  flex: 1;
  border-radius: 0;
  justify-content: center;
}
.ecoown_cont .ecosys_rightcont .table3 .table-header-row {
  width: 100%;
}
.ecoown_cont .ecosys_rightcont .table3 .table-header-row > div:first-child {
  border-radius: 5px 0 0 0;
  justify-content: flex-start;
}
.ecoown_cont .ecosys_rightcont .table3 .table-header-row > div:last-child {
  border-radius: 0 5px 0 0;
  text-align: center;
  justify-content: center;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div > div {
  line-height: 20px;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div:nth-child(2),
.ecoown_cont .ecosys_rightcont .table3 .table-row > div:first-child {
  flex-basis: 25%;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div:last-child {
  flex-basis: 50%;
}
.ecoown_cont .ecosys_rightcont .table3 .table-row > div:last-child .select {
  width: 100%;
  display: flex;
  align-items: center;
}
.ecoown_cont
  .ecosys_rightcont
  .table3
  .table-row
  > div:last-child
  .select
  .remove-files-list {
  margin-right: auto;
  margin-left: 5px;
}
.ecoown_cont
  .ecosys_rightcont
  .table3
  .table-row
  > div:last-child
  .select
  .fa.fa-download {
  margin-right: auto;
}
/*******Site details tab custom css******/
.newTable {
  table-layout: fixed;
  width: 100%;
}

.newTable thead th {
  background-color: #333;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 10px 5px;
}
.newTable thead tr th:first-child {
  padding-left: 10px;
}
.newTable td div select {
  width: 100%;
}
.newTable thead tr th:first-child {
  border-radius: 5px 0 0 0;
}

.newTable thead tr th:last-child {
  border-radius: 0 5px 0 0;
}
.newTable tbody tr td .mandatory.has-error {
  position: absolute;
}
.newTable tbody tr:first-child td {
  border-bottom: none;
}
.newTable.nofieldstbl tbody tr:first-child td {
  border-bottom: 1px solid #999;
}
.newTable tbody tr td {
  height: 45px;
  color: #333;
  font-size: 13px;
  border-bottom: 1px solid #999;
}
.newTable tbody tr td div {
  word-break: break-all;
}
.ecosysobjtab_cont
  .ecosys_rightcont
  .newTable
  tbody
  tr
  td
  div:not(.mandatory):empty {
  width: 100%;
  border-bottom: 1px solid #999;
  height: 45px;
  line-height: 45px;
}
.newTable tbody tr td input,
.newTable tbody tr td select {
  padding: 0;
  height: 45px;
  line-height: 45px;
}
.newTable tbody tr td input[type="text"] {
  padding-left: 5px;
  padding-right: 5px;
}
.newTable tbody tr td input[type="date"] {
  border: none;
  border-bottom: 1px solid #999;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.newTable tbody tr td input[type="text"]::placeholder {
  color: var(--color-darkslategray);
}
.newTable tbody tr td:last-child > div button,
.addbtnstyle {
  height: 25px;
  line-height: 25px;
  min-width: 50px;
  border: none;
  background: #50b041;
  color: #fff;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
}
.newTable tbody tr td:last-child > div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}
.newTable tbody tr td:last-child > div a:first-child {
  margin-right: 10px;
}
.newTable tbody tr td:last-child > div a i {
  font-size: 14px;
}
.ecosysobjtab_cont
  .right-content-area3
  .input-field-text10
  .content-title-text65
  input[type="date"] {
  width: 100%;
  height: 27px;
  border: none;
  border-bottom: 1px solid #999;
}
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div
  .hero-title8 {
  white-space: nowrap;
}
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div {
  gap: 10px;
}
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div
  select,
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div
  input {
  width: 100%;
  height: 25px;
  line-height: 25px;
  padding: 0;
}
.newTable tbody tr:hover td select,
.newTable tbody tr:hover td input {
  background-color: #e7f0ff;
}
.ecositjs_cont select,
.ecositjs_cont input {
  width: 100%;
  height: 25px;
  line-height: 25px;
  padding: 0;
}
.waterinnernestcol > div > .hero-title8 {
  color: #444;
}
/*****sponsership tab custom css*******/
.noflextable {
  display: table;
  table-layout: auto;
  margin: 0 9px !important;
  width: 98% !important;
}
.noflextable thead tr th {
  background: #333;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  padding: 14px 15px;
}
.noflextable thead tr th:first-child {
  border-radius: 5px 0 0 0;
}
.noflextable thead tr th:last-child {
  border-radius: 0 5px 0 0;
}
.noflextable tbody tr td {
  vertical-align: middle;
}
.noflextable tbody tr td .form-check-input:checked {
  background-color: #50b041;
  border-color: #50b041;
}
.noflextable tbody tr td:last-child {
  text-align: right;
}
.tblheadwithbtn {
  margin: 0 0 10px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.fullwidthspon {
  width: 100% !important;
}
.fullwidthspon .noflextable {
  width: 100% !important;
}
/*****partnership tab custom css*******/
.ecopartcont
  .ecosys_rightcont
  .executionPartner
  .input-field-text31
  > div
  > div,
.ecosys_middlecont.ecopartcont
  .ecosys_rightcont
  .executionPartner
  .input-field-text31
  > div
  input,
.ecosys_middlecont.ecopartcont
  .ecosys_rightcont
  .executionPartner
  .input-field-text31
  > div
  select {
  width: 100%;
}
.ecopartcont .ecosys_rightcont .executionPartner .input-field-text31 > div,
.ecopartcont .ecosys_rightcont .institutionalSponsor .input-field-text31 > div,
.ecopartcont .ecosys_rightcont .governmentEntities .input-field-text31 > div,
.ecopartcont .ecosys_rightcont .auditServiceProvider .input-field-text31 > div {
  justify-content: space-between;
  height: 70px;
}
.ecopartcont .ecosys_rightcont .farmer .input-field-text31 .jstspcebtn,
.ecopartcont
  .ecosys_rightcont
  .individualSponsor
  .input-field-text31
  .jstspcebtn {
  margin-top: auto;
}
.addrmvebtn {
  min-width: 100px;
  height: 31px;
}
.addrmvebtn:focus {
  box-shadow: none;
}
.jstspcebtn {
  display: flex;
  align-items: flex-end;
}
.ecopartcont
  .auditServiceProvider
  .input-field-text31
  .content-title-text150
  > div:last-child,
.ecopartcont
  .auditServiceProvider
  .input-field-text31
  .content-title-text150
  > div:last-child
  .multiselector_cont
  input,
.ecopartcont
  .institutionalSponsor
  .input-field-text31
  .content-title-text150
  > div:last-child,
.ecopartcont
  .institutionalSponsor
  .input-field-text31
  .content-title-text150
  > div:last-child
  .multiselector_cont
  input,
.ecopartcont
  .governmentEntities
  .input-field-text31
  .content-title-text150
  > div:last-child,
.ecopartcont
  .governmentEntities
  .input-field-text31
  .content-title-text150
  > div:last-child
  .multiselector_cont
  input {
  width: 100%;
}
.ecopartcont .auditServiceProvider .input-field-text31 .jstspcebtn,
.ecopartcont .institutionalSponsor .input-field-text31 .jstspcebtn {
  margin-top: auto;
}
.ecosyscom_cont .ecosys_rightcont .action-buttons1 .cta3 {
  padding: 12px 20px;
}
.ecopartcont .ecosys_rightcont .noflextable input:focus,
.ecosponsmid_cont .ecosys_rightcont .noflextable input:focus {
  border: 1px solid #ced4da;
}
/**********ownership tab custom css********/
.ownerTable {
  width: 100%;
}
.ownerTable .hero-title19 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px 15px;
  background: #333;
  color: #fff;
}
.ownerTable .hero-title19 .project-summary19 {
  max-width: calc(100% / 4);
  width: 100%;
  text-align: center;
}
.ownerTable .innerOwnerTable {
  width: 100%;
}
.ownerTable .innerOwnerTable .inner-title {
  background: #f8f8f8;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 15px;
}
.ownerTable .innerOwnerTable .inner-title .personnel-lorem-ipsum34 {
  max-width: calc(100% / 4);
  width: 100%;
  text-align: center;
}
.ecoown_cont .ecosys_rightcont .container9 .input-field-text15,
.ecoown_cont .ecosys_rightcont .container9 .input-field-text16 {
  width: 100%;
}
.ecoown_cont .content-title-text98 select {
  width: 100%;
}
.ecoown_cont .ownerTable .table4 .hero-title19 > div {
  font-size: 11px;
}
.ecoown_cont .ownerTable .innerOwnerTable .inner-title > div {
  font-size: 13px;
}
.ecoown_cont .fullwidthspon .content-title-text89 > div:last-child,
.ecoown_cont
  .fullwidthspon
  .content-title-text89
  > div:last-child
  .multiselector_cont
  input {
  width: 100%;
}
.ecoown_cont .ecosys_rightcont .newTable thead tr th {
  padding: 10px 0;
}
.ecoown_cont .ecosys_rightcont .newTable thead tr th:first-child {
  padding-left: 10px;
}
.ecoown_cont .ecosys_rightcont .newTable thead tr th:last-child {
  text-align: center;
}
.ecoown_cont .ecosys_rightcont .cta-add-more3 .addbtnstyle {
  text-decoration: none;
}
/********** css for astrik symbol**********/
.addproformloc_cont .project-summary13 {
  position: relative;
  white-space: nowrap;
}
.addproformloc_cont .project-summary13 .mandatory {
  top: 8px;
}
@supports (-webkit-hyphens: none) {
  .ecosys_rightcont select {
    padding: 0;
    height: 32px;
  }
  .columnlapacont
    .land-parecel-content-area
    .left-content
    .column-content2
    > div
    > div
    input,
  .columnlapacont
    .land-parecel-content-area
    .left-content
    .column-content2
    > div
    > div
    select,
  .ecosysobjtab_cont .ecosys_rightcont .input-field-text25 > div input,
  .ecosysobjtab_cont .ecosys_rightcont .input-field-text25 > div select {
    padding: 0;
    height: 32px;
  }
  .ecoprosumry_cont .project-summary13 {
    width: 100%;
  }
}
/******submenu hover css*******/
.submenu > a:hover {
  color: #118d45;
}
/*******summarymap overlapping issue*********/
.ecoprosumry_cont .project-summary13 {
  width: 100%;
}
.ecoprosumry_cont .project-summary13 .content65 .hero-title12 {
  width: auto;
}
/*******Layers form design css*********/
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  .content-title-text112
  label {
  display: none;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  .content-title-text112
  #speciesName {
  width: 100%;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div:not(.radio-button-input-text2) {
  flex-direction: column;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable {
  margin-top: 10px;
  width: 100%;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td
  input {
  height: auto;
  line-height: auto;
  border: none;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td:empty {
  display: none;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td:last-child {
  text-align: center;
  width: 15%;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td:last-child
  button {
  border: 1px solid #ddd;
  width: 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  background: #fff;
  color: #666;
}
.table4.pb-4.addlayerspretbl_cont > div:first-child > div > div {
  padding: 14px 0;
}
.table4.pb-4.addlayerspretbl_cont > div:first-child > div:first-child > div {
  padding: 14px 15px;
}
.table4.pb-4.addlayerspretbl_cont
  > div:not(:first-child)
  > div
  > div:first-child
  > div
  > div {
  padding-left: 15px;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2
  div
  div {
  line-height: 18px;
  white-space: pre-line;
  display: flex;
  align-items: center;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2 {
  align-items: center;
  height: 45px;
}
/* .addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div.content-title-text110
  .hero-title13 {
  margin-bottom: 20px;
} */
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div {
  gap: 5px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(2) {
  margin-top: 20px;
  margin-bottom: 15px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(3) {
  margin-top: 10px;
}

.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(2)
  input {
  padding: 0 0 10px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div:nth-child(2)
  .hero-title13 {
  margin-bottom: 3px;
}
/* .addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(3) {
  margin-top: 10px;
  margin-bottom: 30px;
} */
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(3)
  .radio-button-input-text2 {
  margin: 10px 0 25px;
  font-size: 13px;
  line-height: 13px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(4)
  .radio-button-input-text2 {
  margin: 10px 0 25px;
  font-size: 13px;
  line-height: 13px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div:nth-child(2) {
  margin-bottom: 20px;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(3).mapboundsfield_cont {
  margin-bottom: 30px;
}
/* .addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(4) {
  margin-bottom: 30px;
} */
.addproformloc_cont .addlayesrfrom_cont .left-content1 {
  padding-bottom: 20px !important;
}
.newTable.nameclrtbl {
  position: relative;
  width: 92%;
}
.newTable.nameclrtbl tbody tr {
  display: flex;
  width: max-content;
}
.newTable.nameclrtbl tbody tr td {
  display: flex;
  width: 50%;
  align-items: center;
}
.newTable.nameclrtbl thead tr th:last-child {
  display: none;
}
.newTable.nameclrtbl tbody tr td button {
  order: -1;
  display: flex;
  margin-right: 10px;
  border: none !important;
  height: 16px !important;
}
.treeties-addproject-forms1-loc.ecosyscom_cont.ecosyscomlayers_cont
  .land-parecel-content-area1 {
  padding: 0 30px;
}

.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid #ddd;
  line-height: 46px;
  text-align: left;
  justify-content: flex-start;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info
  .table-row-content-a30 {
  position: relative;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info
  .table-row-content-a30
  .personnel-lorem-ipsum34 {
  display: block;
  white-space: initial;
  line-height: 45px;
  text-overflow: ellipsis;
  position: relative;
  width: 92%;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info
  .table-row-content-a30
  .personnel-lorem-ipsum34::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: calc(100% - 94%);
  left: auto;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info
  .addprolayers_hoverinfo {
  background: #333;
  border: 1px solid #4c4c4d;
  border-radius: 4px;
  bottom: 0;
  color: #fff;
  left: 50%;
  transform: translateX(-6%);
  opacity: 0;
  padding: 10px;
  position: absolute;
  transition: 0.3s ease-out;
  visibility: hidden;
  z-index: 1;
  width: 350px;
  height: auto !important;
  display: flex;
  flex-wrap: wrap;
  line-height: 24px;
  font-size: 12px;
}

.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info:hover
  .addprolayers_hoverinfo {
  opacity: 1;
  visibility: visible;
}

.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19 {
  position: relative;
}
.left-tabs-title.active_tab .title .ecolayers_icon svg path {
  stroke: #50b041;
}
.left-tabs-title.active_tab .title .ecoprolvlimg_icon svg path {
  fill: #50b041;
}
.left-tabs-title.active_tab .title .ecocollab_icon svg path {
  fill: #50b041;
}
.left-tabs-title.active_tab .title .ecoprowatrparm_icon svg path {
  stroke: #50b041;
}
.ecosys_rightcont input[placeholder="Enter sequence number"]::placeholder {
  color: #000;
}
.ecosyscomlayers_cont
  .exoloctab_cont
  .addproformloc_cont
  .table4
  div
  .hero-title19
  .tb-col2
  div {
  padding: 0 5px 0 0;
}
.ecosys_rightcont input[placeholder="Enter sequence number"] {
  padding: 8px 0;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont
  div
  .hero-title19
  .tb-col2:first-child
  div {
  padding-left: 7px;
}
.exoloctab_cont
  .addproformloc_cont
  .table4.addlayerspretbl_cont.waterparamtbl_cont
  div
  .hero-title19
  .tb-col2.addprolayers_info
  .table-row-content-a30
  .personnel-lorem-ipsum34::after {
  display: none;
}
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  div
  select {
  border-bottom: 1px solid #bfbfbf;
}
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div:nth-child(2),
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(2) {
  margin-top: 20px;
}
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div:nth-child(3),
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:first-child
  > div:nth-child(4),
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:nth-child(3) {
  margin-bottom: 15px;
}
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div
  input[type="number"] {
  padding: 0;
}
.addlayesrfrom_cont.addwaterparam_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div {
  margin-top: 4px;
}
.ecosys_rightcont
  .input-field-text25.fullwidthspon
  .content-title-text133
  .field {
  margin-top: 13px;
}
.ecosys_rightcont input[placeholder="Enter Amount"]::placeholder {
  color: #333;
  opacity: 1;
}
/**********layers edit page table css**********/
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  thead
  th:nth-child(2) {
  text-align: center;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  thead
  th:last-child {
  text-align: right;
  padding-right: 20px;
  display: block;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td:last-child
  button {
  margin: 0;
}
.addlayesrfrom_cont
  .land-parecel-content-area1
  .left-content1
  .container-26
  > div:last-child
  > div:last-child
  > div
  .newTable
  tbody
  tr
  td:nth-child(2) {
  width: 58%;
}
.treeties-addproject-forms1-spo.ecosyscom_cont
  .ecosys_rightcont
  .container-19:nth-child(2)
  .input-field-text26.fullwidthspon {
  margin-top: 20px;
}
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div
  select,
.columnlapacont
  .land-parecel-content-area
  .left-content
  .column-content2
  > div
  > div
  select
  option {
  text-transform: capitalize;
}
.exoloctab_cont.exocolabtab_cont
  .addproformloc_cont
  .table4
  > div:not(:first-child)
  > div
  div {
  width: calc(100% / 5);
  word-break: break-all;
}
.exoloctab_cont.exocolabtab_cont
  .addproformloc_cont
  .table4
  > div:not(:first-child)
  > div
  > div
  div {
  width: 100%;
  height: 45px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.exoloctab_cont.exocolabtab_cont
  .addproformloc_cont
  .table4
  > div:not(:first-child)
  > div
  > div
  > div
  div {
  height: auto;
  line-height: 18px;
  white-space: normal;
  width: 100%;
}
