.dashboard,
.tree {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gray-100);
  text-align: left;
  display: inline-block;
}
.tree {
  color: var(--color-white);
}
.left-navbar {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
