.logo-a7 {
  position: relative;
}
.logo7 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.aarey-forest-phase70 {
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
}
.header-left-content7,
.page-title7 {
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) 0;
  align-items: center;
  justify-content: flex-start;
}
.header-left-content7 {
  flex: 1;
  gap: var(--gap-xl);
}
.header-right-content-child4 {
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.header-right-content7,
.header7 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.header-right-content7 {
  width: 123px;
  gap: var(--gap-11xl);
}
.header7 {
  background: linear-gradient(180deg, #00a76b 48.44%, #008e5b);
  overflow: hidden;
  padding: 0 0 0 var(--padding-xl);
  gap: var(--gap-3xs);
}
.dashboard7,
.tree7 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gray-100);
  text-align: left;
  display: inline-block;
}
.tree7 {
  color: var(--color-white);
}
.left-navbar7 {
  align-self: stretch;
  background-color: var(--color-darkslategray-200);
  display: flex;
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.project-summary16 {
  position: relative;
  font-weight: 600;
}
.hero-title16 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.aarey-forest-phase71 {
  position: relative;
  font-size: var(--font-size-smi);
  color: var(--color-darkslategray);
}
.content-title-text124 {
  flex-direction: column;
  gap: var(--gap-3xs);
}
.column-121,
.container12,
.content-title-text124,
.content63 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.column-121 {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-11xl);
}
.container12,
.content63 {
  align-self: stretch;
}
.container12 {
  flex-direction: row;
  padding: var(--padding-xl) 0;
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.content63 {
  flex: 1;
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke);
  flex-direction: column;
  padding: var(--padding-xl) 0 0 var(--padding-11xl);
  gap: var(--gap-3xs);
}
.img-1-1-icon7 {
  align-self: stretch;
  flex: 1;
  position: relative;
  border-radius: 0 var(--br-8xs) var(--br-8xs) 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.container-18,
.img9 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.img9 {
  width: 465px;
  flex-direction: column;
}
.container-18 {
  border: 1px solid var(--color-lightgray);
  flex-direction: row;
}
.handshake7,
.partnerships-57 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-gainsboro);
  text-align: left;
}
.partnerships-57 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-darkslategray);
}
.title56 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.tick-icon7 {
  position: relative;
  width: 9.72px;
  height: 7.07px;
}
.verified7 {
  position: relative;
  font-size: var(--font-size-2xs);
  line-height: 14px;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: left;
}
.content64,
.labels56,
.left-tabs-title56 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.labels56 {
  border-radius: var(--br-xl);
  background-color: var(--color-mediumseagreen-100);
  padding: var(--padding-9xs) var(--padding-3xs);
  justify-content: flex-end;
  gap: var(--gap-9xs);
}
.content64,
.left-tabs-title56 {
  justify-content: flex-start;
}
.content64 {
  flex: 1;
}
.left-tabs-title56 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl);
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  box-sizing: border-box;
  width: 330px;
}
.money-bag49,
.sponsorship7 {
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-mediumseagreen-300);
  text-align: left;
}
.sponsorship7 {
  font-size: var(--font-size-base);
  font-weight: 600;
  font-family: var(--font-poppins);
}
.labels57 {
  border-radius: var(--br-xl);
  background: linear-gradient(
    89.92deg,
    rgba(233, 164, 100, 0.99) 70.52%,
    #e7ecf1 70.67%
  );
  width: 75px;
  padding: var(--padding-9xs) var(--padding-3xs);
}
.labels57,
.labels59,
.left-tabs-title57 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.left-tabs-title57 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl);
  background-color: var(--color-white);
  border-right: 1px solid var(--color-gainsboro);
  border-bottom: 1px solid var(--color-gainsboro);
  width: 330px;
  overflow: hidden;
}
.labels59 {
  border-radius: var(--br-xl);
  background: linear-gradient(89.92deg, #e9a464 36.45%, #e7ecf1 36.46%);
  width: 75px;
  padding: var(--padding-9xs) var(--padding-3xs);
}
.not-started14 {
  position: relative;
  font-size: var(--font-size-2xs);
  line-height: 14px;
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
}
.labels60 {
  border-radius: var(--br-xl);
  width: 75px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-9xs) 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-start;
}
.project-summary17 {
  flex: 1;
  position: relative;
}
.hero-title17 {
  width: 850px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.content-title-text133,
.dropdown-126 {
  display: flex;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.dropdown-126 {
  align-self: stretch;
  border-bottom: 1px solid var(--color-darkgray);
  flex-direction: row;
  align-items: center;
  border-color: #999;
  outline: 0;
  line-height: 30px;
  padding: var(--padding-xs) 0;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.content-title-text133 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.enter-input-text-11,
.radio-button-yes4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.radio-button-yes4 {
  cursor: pointer;
  padding: var(--padding-7xs) 0;
}
.enter-input-text-11 {
  border: 0;
  font-family: var(--font-poppins);
  font-size: var(--font-size-smi);
  background-color: transparent;
  border-bottom: 1px solid var(--color-silver);
  padding: var(--padding-5xs) var(--padding-3xs);
}
.input-field-text25,
.radio-button-input-text4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.radio-button-input-text4 {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.input-field-text25 {
  gap: var(--gap-21xl);
  font-size: var(--font-size-2xs);
  color: var(--color-darkgray);
}
.institutional-sponsorship-ass {
  position: relative;
  display: inline-block;
  width: 705px;
}
.container-19,
.input-field-text26 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.container-19 {
  gap: var(--gap-11xl);
}
.back6 {
  position: relative;
  font-size: var(--font-size-smi);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-mediumseagreen-200);
  text-align: center;
}
.cta13 {
  cursor: pointer;
  border: 1px solid var(--color-mediumseagreen-200);
  padding: var(--padding-sm) var(--padding-xl);
  background-color: var(--color-white);
  border-radius: var(--br-9xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.save-continue7 {
  position: relative;
  font-size: var(--font-size-smi);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
}
.action-buttons6,
.cta14 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.cta14 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-sm) var(--padding-xl);
  background-color: var(--color-mediumseagreen-200);
  border-radius: var(--br-9xs);
}
.action-buttons6 {
  gap: var(--gap-xl);
}
.right-content-area7 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl) 0 0;
  gap: var(--gap-11xl);
}
.container-27,
.middle-content-area7,
.right-content-area7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.container-27 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-21xl);
  font-size: var(--font-size-smi);
}
.middle-content-area7 {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl);
  gap: var(--gap-11xl);
}
.notifications-bell7,
.plus-circle7 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  font-size: var(--font-size-5xl);
  font-family: var(--font-treeties);
  color: var(--color-mediumseagreen-300);
  text-align: left;
  display: inline-block;
}
.notifications-bell7 {
  color: var(--color-darkgray);
}
.right-navbar7 {
  align-self: stretch;
  background-color: var(--color-aliceblue);
  flex-direction: column;
  padding: var(--padding-xl) var(--padding-3xs);
  gap: var(--gap-11xl);
}
.main-content-area7,
.right-navbar7,
.treeties-addproject-forms1-spo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.main-content-area7 {
  align-self: stretch;
  flex-direction: row;
  font-size: var(--font-size-base);
  color: var(--color-darkslategray);
  font-family: var(--font-poppins);
}
.treeties-addproject-forms1-spo {
  position: relative;
  background-color: var(--color-white);
  /* width: 100%; */
  overflow: hidden;
  flex-direction: column;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-white);
  font-family: var(--font-treeties);
}
.noflextable {
  display: table;
  table-layout: auto;
  margin: 0 9px !important;
  width: 98% !important;
}
.noflextable thead tr th {
  background: #333;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  padding: 14px 15px;
}
.noflextable thead tr th:first-child {
  border-radius: 5px 0 0 0;
}
.noflextable thead tr th:last-child {
  border-radius: 0 5px 0 0;
}
.noflextable tbody tr td {
  vertical-align: middle;
}
.noflextable tbody tr td:last-child {
  text-align: right;
}
.tblheadwithbtn {
  margin: 0 0 10px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.fullwidthspon {
  width: 100% !important;
}
.fullwidthspon .noflextable {
  width: 100% !important;
}
.file-name-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-name {
  flex: 1;
  margin-right: 5px;
}

.remove-file-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: red;
}
