@import url("https://fonts.cdnfonts.com/css/euclid-circular-b");
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Bold.eot");
  src: local("../fonts/Euclid Circular B Bold"), local("EuclidCircularB-Bold"),
    url("../fonts/EuclidCircularB-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidCircularB-Bold.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Bold.woff") format("woff"),
    url("../fonts/EuclidCircularB-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Italic.eot");
  src: local("../fonts/Euclid Circular B Italic"),
    local("EuclidCircularB-Italic"),
    url("../fonts/EuclidCircularB-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-Italic.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Italic.woff") format("woff"),
    url("../fonts/EuclidCircularB-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Medium.eot");
  src: local("../fonts/Euclid Circular B Medium"),
    local("EuclidCircularB-Medium"),
    url("../fonts/EuclidCircularB-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-Medium.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Medium.woff") format("woff"),
    url("../fonts/EuclidCircularB-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-SemiBoldItalic.eot");
  src: local("../fonts/Euclid Circular B SemiBold Italic"),
    local("../fonts/EuclidCircularB-SemiBoldItalic"),
    url("../fonts/EuclidCircularB-SemiBoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-SemiBoldItalic.woff") format("woff"),
    url("../fonts/EuclidCircularB-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-BoldItalic.eot");
  src: local("../fonts/Euclid Circular B Bold Italic"),
    local("../fonts/EuclidCircularB-BoldItalic"),
    url("../fonts/EuclidCircularB-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-BoldItalic.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-BoldItalic.woff") format("woff"),
    url("../fonts/EuclidCircularB-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-MediumItalic.eot");
  src: local("../fonts/Euclid Circular B Medium Italic"),
    local("../fonts/EuclidCircularB-MediumItalic"),
    url("../fonts/EuclidCircularB-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-MediumItalic.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-MediumItalic.woff") format("woff"),
    url("../fonts/EuclidCircularB-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-SemiBold.eot");
  src: local("../fonts/Euclid Circular B SemiBold"),
    local("EuclidCircularB-SemiBold"),
    url("../fonts/EuclidCircularB-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-SemiBold.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-SemiBold.woff") format("woff"),
    url("../fonts/EuclidCircularB-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Light.eot");
  src: local("../fonts/Euclid Circular B Light"), local("EuclidCircularB-Light"),
    url("../fonts/EuclidCircularB-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/EuclidCircularB-Light.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Light.woff") format("woff"),
    url("../fonts/EuclidCircularB-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Regular.eot");
  src: local("../fonts/Euclid Circular B Regular"),
    local("EuclidCircularB-Regular"),
    url("../fonts/EuclidCircularB-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-Regular.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-Regular.woff") format("woff"),
    url("../fonts/EuclidCircularB-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-LightItalic.eot");
  src: local("../fonts/Euclid Circular B Light Italic"),
    local("../fonts/EuclidCircularB-LightItalic"),
    url("../fonts/EuclidCircularB-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EuclidCircularB-LightItalic.woff2") format("woff2"),
    url("../fonts/EuclidCircularB-LightItalic.woff") format("woff"),
    url("../fonts/EuclidCircularB-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

$font-1: "Euclid Circular B";
$font-2: "Playfair Display", serif;
$font-calc: "Chivo", sans-serif;
$font-opensans: "Open Sans", sans-serif;
$font-poppins: "Poppins", sans-serif;

@import "../../../../css/mixins.scss";

body {
  font-family: $font-1 !important;
  &.nobackdrop_cont {
    .pf-c-backdrop {
      --pf-c-backdrop--BackgroundColor: transparent !important;
    }
  }
  &.prolistviewbackdrop_cont {
    .pf-c-backdrop {
      --pf-c-backdrop--BackgroundColor: transparent !important;
      z-index: 0;
    }
  }
  &.prolistviewkmediabg_cont {
    .pf-c-backdrop {
      --pf-c-backdrop--BackgroundColor: rgba(0, 0, 0, 0.5) !important;
    }
  }
}
.top_header {
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  @include media-max(xl) {
    height: 50px;
  }
  .nav-icon {
    border-right: none;
    @include media-max(xl) {
      width: 55px;
    }
    i {
      color: #50b041;
    }
  }
  .navbar_right {
    > li {
      &.main_heading span {
        color: #4e4e4e;
        @include media-max(xl) {
          font-size: px-to-rem(20);
        }
      }
      &.user_cont {
        .profiledata {
          span.profile_name,
          span.profile_company {
            color: #4e4e4e;
          }
        }
      }
    }
  }
}
.sidebar_cont {
  background: linear-gradient(180deg, #72bc40 0%, #0b9743 100%);
  top: 60px;
  @include media-max(xl) {
    top: 50px;
  }
  .sidebarinner_cont {
    @include media-max(xl) {
      width: 50px;
    }
    .sidebar_menu {
      margin: 0;
      overflow: hidden;
      li {
        height: auto;
        // a {
        //   height: 50px;
        //   width: 50px;
        //   border-radius: 0;
        //   color: #fff;
        //   &:hover {
        //     background-color: initial;
        //     border-radius: 0;
        //     box-shadow: none;
        //   }
        //   &[aria-current="page"] {
        //     background-color: #086500;
        //     border-radius: 0;
        //     box-shadow: none;
        //   }
        // }
        &.NGODashboard,
        &.Dashboard {
          a {
            i {
              &::before {
                content: url("../icons/dashboardwhite_icon.png");
              }
            }
            &[aria-current="page"] {
              i {
                &::before {
                  content: url("../icons/dashboardactive_icon.png");
                }
              }
            }
          }
        }
        &.Projects {
          a {
            i {
              &::before {
                content: url("../icons/Parkicon_white.png");
              }
            }
            &[aria-current="page"] {
              i {
                &::before {
                  content: url("../icons/Parkicon_Green.png");
                }
              }
            }
          }
        }
        &.submenu_main {
          display: flex;
          flex-direction: column;
          padding: 0 13px;
          height: 50px;
          > .submenumain_menu {
            width: 100%;
            display: inline-block;
            position: relative;
            color: #fff;
            cursor: pointer;
            line-height: 50px;
            span {
              margin-left: 20px;
              font-weight: 500;
            }
            .submenu_downarrow {
              position: absolute;
              right: 10px;
              top: 0;
              transition: 0.3s;
              > svg,
              > svg path {
                stroke: #fff;
              }
              &.open {
                transform: rotate(180deg);
              }
            }
            span,
            .submenu_downarrow {
              visibility: hidden;
              opacity: 0;
            }
            &.openactive {
              span {
                color: #ffaa17;
              }
              > svg,
              > svg path {
                stroke: #ffaa17;
              }
            }
          }
          .sidebar_submenu {
            width: 100%;
            padding: 0;
            margin: 0 0 25px 80px;
            li {
              margin-bottom: 15px;
              a {
                width: 100%;
                height: auto;
                padding: 0;
                color: #fff;
                span {
                  margin-left: 10px;
                  font-size: 14px;
                }
                &[aria-current="page"] {
                  color: #f5ae6b;
                  svg path {
                    fill: #f5ae6b;
                  }
                }
                &[href="/ApplicationList"][aria-current="page"] {
                  svg {
                    fill: #f5ae6b;
                  }
                }
                &[href="/PaymentList"][aria-current="page"] {
                  svg {
                    fill: #f5ae6b;
                    rect {
                      stroke: #f5ae6b;
                    }
                  }
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &.nosidebar_submenu {
            padding: 0;
            .submenumain_menu {
              padding: 0 13px;
              height: 50px;
              &[aria-current="page"] {
                background: #086500;
                color: #f5ae6b;
                svg path {
                  fill: #f5ae6b;
                }
              }
            }
          }
          &.submenusidebar_active {
            background: #086500;
            > .submenumain_menu {
              color: #fff;
              > svg {
                top: -3px;
                position: relative;
              }
              > svg path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.viewmap {
  margin: 60px 0 40px 50px !important;
  .mapClass {
    > div {
      margin: -60px 0 0;
    }
    .gm-style {
      z-index: 1 !important;
      &::before {
        content: "";
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.7) 21.58%,
          rgba(0, 0, 0, 0) 88.52%
        );
        top: -9px;
        left: 0;
        height: 250px;
        position: absolute;
        width: 100%;
        z-index: 1;
        pointer-events: none;
      }
      > div {
        &:first-child {
          > div {
            &:nth-child(2) {
              > div {
                &:last-child {
                  > div {
                    &:last-child {
                      z-index: 9999 !important;
                      .gm-style-iw-a {
                        .gm-style-iw-t {
                          .gm-style-iw.gm-style-iw-c {
                            padding: 0 !important;
                            .gm-style-iw-ch {
                              padding: 0 !important;
                            }
                            .gm-style-iw-d {
                              overflow: auto !important;
                              padding: 0 !important;
                              width: 310px;
                            }
                            button.gm-ui-hover-effect {
                              top: 8px !important;
                              right: 10px !important;
                              position: absolute !important;
                              z-index: 999 !important;
                              width: 20px !important;
                              height: 20px !important;
                              span {
                                width: 18px !important;
                                height: 18px !important;
                                background-color: #fff;
                                margin: 0 !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &:last-child {
          .gmnoprint > div {
            height: 30px !important;
            line-height: 30px !important;
          }
          .gm-style-cc {
            height: 30px !important;
            line-height: 30px !important;
          }
        }
      }
      button[aria-label="Toggle fullscreen view"] {
        @include media-max(xl) {
          height: 36px !important;
          width: 36px !important;
        }
      }
      button[aria-label="Toggle fullscreen view"]
        + div
        a[title="Open this area in Google Maps (opens a new window)"] {
        display: none !important;
      }
      > div > div:nth-child(2)::before {
        background: linear-gradient(
          460deg,
          rgba(0, 0, 0, 0.5) 81.58%,
          rgba(0, 0, 0, 0) 100%
        );
        //content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
    .Pojectdetail_headernew {
      position: relative;
      top: 70px;
      left: 10px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      padding: 10px 12px 0 20px;
      @include media-max(xl) {
        top: 60px;
        padding: 10px 12px 0 5px;
      }
      > div {
        position: relative;
        z-index: 1;
      }
      .gobackbtn {
        margin: -3px 15px 0 0;
        i {
          color: #50b041;
          font-size: px-to-rem(24);
        }
      }
      .prodetheader_proheading {
        display: flex;
        flex-direction: column;
        h1 {
          color: #fff;
          font-family: $font-1;
          font-size: px-to-rem(20);
          font-weight: 600;
          margin-bottom: 10px;
          @include media-max(xl) {
            font-size: px-to-rem(16);
          }
          .verifyicon {
            margin-left: 15px;
          }
        }
        .projectviewhead_carbonseq {
          display: flex;
          flex-direction: column;
          font-family: $font-1;
          margin-bottom: 10px;
          h3 {
            color: #e9e9e9;
            font-size: px-to-rem(13);
            font-weight: 400;
            margin-bottom: 2px;
            @include media-max(xl) {
              font-size: px-to-rem(12);
            }
          }
          span {
            color: #fff;
            font-size: px-to-rem(20);
            font-weight: 700;
            letter-spacing: 1px;
            @include media-max(xl) {
              font-size: px-to-rem(16);
            }
          }
        }
        .morebtn {
          color: #fff;
          font-family: $font-1;
          font-size: px-to-rem(14);
          font-style: normal;
          font-weight: 500;
          position: relative;
          background: #50b041;
          display: flex;
          width: 110px;
          padding: 5px;
          border-radius: 5px;
          align-items: center;
          justify-content: center;

          &::after {
            //content: "";
            position: absolute;
            left: 0;
            height: 1px;
            background: #50b041;
            display: inline-flex;
            width: 82px;
            bottom: -3px;
          }
          // @include media-max(xl) {
          //   font-size: px-to-rem(12);
          // }
        }
      }
      .prolisttime_cont.logilangval {
        display: flex;
        width: auto;
        margin: 0 20px;
        .polycord {
          width: auto;
          flex-basis: initial;
          white-space: nowrap;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          > div {
            display: inline-flex;
            margin: 0 0 5px;
            width: 260px;
            span {
              color: #c0c0c0;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-family: Poppins;
              font-size: 10px;
              font-weight: 500;
            }
          }
        }
        .spocshover_content {
          background: #333;
          border: 1px solid #4c4c4d;
          position: absolute;
          bottom: -75px;
          left: calc(100% - 80%);
          color: #fff;
          padding: 10px;
          border-radius: 4px;
          transform: translateX(-50px);
          opacity: 0;
          visibility: hidden;
          z-index: 1;
          transition: 0.3s ease-out;
          > div {
            display: flex;
            margin: 0 0 5px;
            &:last-child {
              margin: 0;
            }
            span {
              color: #fff;
              font-family: $font-poppins;
              font-size: 12px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0.24px;
              &:nth-child(2) {
                margin-right: 20px;
              }
            }
          }
          &::after {
            content: "";
            position: absolute;
            top: -12px;
            left: 20px;
            background: url("/assets/topafter_arrow.png");
            width: 16px;
            height: 12px;
            transition: 0.3s;
            transform: translateX(30%);
            visibility: hidden;
            opacity: 0;
          }
        }
        &:hover {
          .spocshover_content {
            opacity: 1;
            visibility: visible;
            &::after {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
      .prodetheader_verifycont {
        display: flex;
        align-items: center;
        .prodheadverify_heading {
          display: flex;
          align-items: center;
          margin-right: 20px;
          span {
            color: #fff;
            font-family: $font-poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
            margin-left: 10px;
          }
        }
        .verified_capsule {
          position: relative;
          .verifiedpercent {
            width: 50px;
            position: absolute;
            right: -46px;
            top: -12px;
            z-index: 2;
            .CircularProgressbar {
              .CircularProgressbar-text {
                fill: #444;
                font-size: px-to-rem(28) !important;
              }
            }
          }
          .typeofverify_forest {
            border-radius: 50px;
            display: flex;
            height: 30px;
            line-height: 30px;
            justify-content: center;
            align-items: center;
            padding: 10px 12px;
            color: #fff;
            font-family: $font-1;
            font-size: px-to-rem(13);
            font-weight: 600;
            text-transform: uppercase;
            @include media-max(xl) {
              font-size: px-to-rem(12);
            }
            &.unhlthyforbtn {
              background: #b54e56;
            }
            &.hlthyforbtn {
              background: #50b041;
            }
            svg {
              margin-left: 5px;
            }
          }
        }
      }
      .projectview_cont {
        display: flex;
        align-items: center;
        margin-left: auto;
        h1 {
          color: #c0c0c0;
          font-family: $font-poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-right: 20px;
        }
        .projectviews_wrap {
          display: flex;
          margin-right: 8px;
          button {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            background: #e8e8e8;
            border: none;
            transition: 0.3s;
            @include media-max(xl) {
              width: 36px;
              height: 36px;
            }
            &.projview_map {
              border-radius: 4px 0 0 4px;
            }
            &.projview_list {
              border-radius: 0 4px 4px 0;
            }
            &.active_viewbtns {
              background: #50b041;
              svg,
              path {
                fill: #fff;
              }
            }
            &.inactive_viewbtns {
              svg,
              path {
                fill: #b7b7b7;
              }
            }
          }
        }
      }
      .prodetheader_selectcont {
        width: 200px;
      }
      .custom-select-container {
        position: relative;
        height: 40px;
        width: 40px;
        border: none;
        background: #50b041;
        border-radius: 4px;
        margin: 0 10px;
        @include media-max(xl) {
          width: 36px;
          height: 36px;
        }
        .custom-select {
          border: none;
          border-radius: 4px;
          padding: 0 15px 0 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            + .options {
              width: 250px;
            }
          }

          .selected-option {
            display: none;
            align-items: center;
          }

          .placeholder {
            color: #fff;
            font-family: $font-poppins;
            font-weight: 500;
            font-size: px-to-rem(16);
          }

          .dropdown-icon {
            margin-left: auto;
          }
        }
        .options {
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          border: 1px solid #ccc;
          border-top: none;
          border-radius: 0 0 4px 4px;
          background-color: #fff;
          margin-top: 3px;
          border-radius: 6px;
          margin-top: 3px;
          padding: 5px 0;
          z-index: 99;
          .option {
            padding: 5px 15px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 10px;
            justify-content: space-between;
            &:hover {
              background-color: #d3ebcf;
            }
            &.selected {
              background-color: #f0f0f0;
            }
            .option-icon {
              order: 1;
              margin-left: 10px;
            }
            .option-label {
              color: #434343;
              font-family: $font-1;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .root-hamburger-menu {
      .hamburger-menu {
        top: 140px;
        right: 10px;
        left: auto;
        position: fixed;
        z-index: 9;
        transition: 0.3s;
        border: none;
        background: #50b041;
        border-radius: 6px 6px 0px 6px;
        width: 36px;
        height: 36px;
        z-index: 2;
        display: flex;
        align-items: center;
        font-family: $font-1;
        cursor: inherit;
        .opensidebarbtn {
          position: absolute;
          left: 6px;
        }
        span {
          visibility: hidden;
          opacity: 0;
          transition: 0.3s;
          font-size: px-to-rem(16);
          font-weight: 600;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          color: #fff;
          transform: translateX(110%);
        }
        .close-sidebar-btn {
          position: absolute;
          right: 15px;
          top: 5px;
          visibility: hidden;
          opacity: 0;
        }
        &.iconmapsidebar {
          transform: translate(0%, 0);
          width: 310px;
          .opensidebarbtn {
            pointer-events: none;
          }
          span {
            visibility: visible;
            opacity: 1;
            left: 40px;
            transform: translateX(0%);
          }
          .close-sidebar-btn {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .hamburger-menu-sidebar {
        transition: 0.3s;
        transform: translateX(110%);
        padding: 20px 15px 20px;
        background-color: #fff;
        color: #333;
        width: 280px;
        height: calc(100vh - 300px);
        border-radius: 0 0 6px 6px;
        overflow-y: auto;
        font-weight: bold;
        position: fixed;
        right: 10px;
        bottom: 124px;
        z-index: 9;
        &.togglemapsidebar {
          transform: translateX(0);
        }
        nav {
          position: relative;
          height: 100%;
          .hamburger-menu-item {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            position: relative;
          }
          /***************css for input******************/
          input[type="checkbox"] {
            position: absolute !important;
            width: 24px;
            height: 24px;
            opacity: 0;
            z-index: 2;
            left: 0;
            cursor: pointer;
          }
          .checkboxLabel {
            color: #212529;
            font-family: $font-1;
            font-size: px-to-rem(14);
            font-weight: 400;
            padding: 0 8px 0 30px;
            word-break: break-all;
          }
        }
        .eye-icon {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          transition: 0.3s;
        }
        .eye-icon.selected + span {
          color: #50b041;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #d9d8d8;
          border-radius: 10px 10px 6px 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: #50b041;
          border-radius: 10px 10px 6px 6px;
        }
      }
    }
  }
}
.prosumtop_cont {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  li {
    display: flex;
    align-items: center;
    .prosum_tree {
      margin-right: 5px;
    }
    .prosum_data {
      display: flex;
      flex-direction: column;
      span {
        font-family: $font-1;
        &:first-child {
          color: #fff;
          font-size: px-to-rem(14);
          font-weight: 500;
          line-height: 18px;
          @include media-max(xl) {
            font-size: px-to-rem(13);
          }
        }
        &:last-child {
          color: #c57329;
          font-size: px-to-rem(20);
          font-weight: 600;
          line-height: 22px;
          @include media-max(xl) {
            font-size: px-to-rem(16);
          }
        }
      }
    }
    &.prosumtopfunrsed_cont {
      width: 200px;
      .prosum_data {
        width: 100%;
        position: relative;
        .prolisttreedet_val {
          color: #c57329;
          font-weight: 700;
        }
        .totalfndrsedamt_cont {
          position: absolute;
          color: #fff;
          right: 0;
          bottom: 12px;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
        }
        .prolist_progress {
          width: auto;
          margin: 8px 0 0;
          > div {
            width: 100%;
            grid-gap: 0;
            .pf-c-progress__status {
              display: none;
            }
            .pf-c-progress__bar {
              height: 6px;
              background-color: transparent;
              &::before {
                background-color: #624128;
                border-radius: 50px;
                opacity: 1;
              }
              .pf-c-progress__indicator {
                height: 6px;
                border-radius: 50px;
                background-color: #c57329;
              }
            }
          }
        }
      }
    }
  }
}
modal {
  button {
    &[aria-label="Close"] {
      background: #50b041;
      top: 0px !important;
      height: 50px;
      right: 0 !important;
      border-radius: 0 6px 0 0 !important;
      color: #fff !important;
      @include media-max(xl) {
        height: 40px;
      }
    }
  }
  .pf-c-modal-box__header {
    border-radius: 6px 6px 0px 0px;
    background: #50b041;
    padding: 0;
    height: 50px;
    display: flex;
    @include media-max(xl) {
      height: 40px;
    }
    .pf-c-modal-box__title {
      display: flex;
      align-items: center;
      height: 100%;
      color: #fff;
      font-size: px-to-rem(18);
      font-weight: 600;
      font-family: $font-1;
      padding: 0 20px;
      @include media-max(xl) {
        font-size: px-to-rem(16);
      }
      &::before {
        content: "";
        width: 22px;
        height: 22px;
        left: 20px;
        top: 10px;
        position: absolute;
        background-image: url("../icons/projectsummary_icon.png");
      }
      .pf-c-modal-box__title-text {
        padding-left: 30px;
      }
    }
  }
  .pf-c-modal-box__body {
    border-radius: 0px 0px 6px 6px;
    background: rgba(0, 0, 0, 0.8);
  }
}
.prosumrymodal_cont {
  position: fixed !important;
  left: 90px;
  top: 190px;
  width: 650px;
  height: calc(100vh - 210px);
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.7) !important;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: 0.3s ease-out;
  z-index: 3;
  @media (min-width: 1441px) and (max-width: 1920px) {
    left: 105px;
    top: 210px;
  }
  &.toggleprosumrymodal {
    opacity: 1;
    visibility: visible;
  }
  .prosumrymodal_header {
    border-radius: 6px 6px 0px 0px;
    background: #50b041;
    padding: 0;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    @include media-max(xl) {
      height: 40px;
    }
    .prosumrymodal_heading {
      display: flex;
      align-items: center;
      margin-right: auto;
      span {
        margin-left: 10px;
        font-size: px-to-rem(18);
        font-weight: 600;
        font-family: $font-1;
        color: $clr-white;
      }
    }
  }
  .Pojectsummary_cont {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #ccc;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #50b041;
      border-radius: 10px;
    }
  }
}
.projectmodal_body {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 59px;
}
.prosumaccord_cont {
  .szh-accordion {
    margin-top: 20px;
    .szh-accordion__item {
      width: 100%;
      margin-bottom: 2px;
      .szh-accordion__item-heading {
        width: 100%;
        border-radius: 4px;
        background: rgba(80, 176, 65, 0.4);
        height: 40px;
        display: flex;
        align-items: center;
        transition: 0.3s;
        @include media-max(xl) {
          height: 30px;
        }
        &:hover {
          background: rgba(54, 114, 45, 0.4);
        }
        button {
          background: transparent;
          color: #fff;
          border: none;
          font-family: $font-1;
          font-weight: 500;
          width: 100%;
          text-align: left;
          padding: 0 20px;
          position: relative;
          @include media-max(xl) {
            padding: 0 10px;
          }
          &::after {
            position: absolute;
            content: "\f107";
            font-family: fontawesome;
            font-size: 18px;
            top: 0;
            right: 15px;
            transition: 0.3s;
          }
          .prosumaccord_head {
            display: flex;
            align-items: center;
            font-size: px-to-rem(16);
            margin-right: 10px;
            @include media-max(xl) {
              font-size: px-to-rem(13);
            }
            svg {
              margin-right: 10px;
              @include media-max(xl) {
                width: 20px;
                height: 20px;
              }
            }
            svg,
            svg path {
              fill: #d9d9d9;
            }
          }
        }
      }
      .szh-accordion__item-content {
        border-radius: 0px 0px 6px 6px;
        background: #fff;
        color: #333;
        padding: 20px;
        display: flex;
        flex-wrap: wrap;
        font-family: $font-1;
        .szh-accordion__item-panel {
          width: 100%;
          .partshprosumpacrd_content {
            margin: 0 -25px 0 0px;
            .row > * {
              padding: 0;
            }
            .prosumacordtbls_wrap {
              p {
                @include media-max(xl) {
                  font-size: px-to-rem(13);
                  margin-bottom: 5px;
                }
              }
              .prosumacord_inertbl {
                &:last-child {
                  margin: 0 0 20px;
                }
              }
            }
          }
          .row {
            .hero-title20 {
              margin: 0 0 10px;
            }
            .prosumacordtbls_wrap {
              .project-summary19 {
                font-size: px-to-rem(14);
                line-height: 24px;
                font-weight: 500;
                margin-bottom: 10px;
              }
            }
          }
          .table4 > div {
            width: 100%;
            .hero-title19 > div {
              font-size: 14px;
              .table-row-content-a30 > div:empty {
                border-bottom: 1px solid #ddd;
                height: 30px;
                line-height: 30px;
              }
            }
          }
        }
      }
      &.szh-accordion__item--expanded {
        .szh-accordion__item-heading {
          background: #333;
          border-radius: 4px 4px 0px 0px;
          position: sticky;
          top: 0;
          z-index: 2;
          @include media-min(xl) {
            top: -2px;
          }
          button {
            &::after {
              transform: rotate(180deg);
            }
            .prosumaccord_head {
              svg,
              svg path {
                fill: #fff;
              }
            }
          }
        }
      }
      &.partnershipacordcont_wrap {
        .szh-accordion__item-content {
          padding: 0;
          border-radius: 0 0 6px 6px;
        }
      }
      &.costviewsummarypopupdata_cont {
        .partshprosumpacrd_content {
          .prosummarylist .prosummarylist_item .produmitem_title {
            min-width: auto;
          }
        }
      }
      &.projectlevelimages_cont {
        .szh-accordion__item-content {
          padding-top: 40px;
          padding-bottom: 0;
        }
      }
    }
  }
}
.prosumcordtabscont_wrap {
  .col-5 {
    width: 38%;
  }
  .col-7 {
    width: 62%;
  }
  .tab-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    button {
      border: none;
      height: 56px;
      text-align: left;
      padding: 0 10px;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: $clr-black;
      line-height: 24px;
      letter-spacing: 0.28px;
      display: flex;
      align-items: center;
      :last-child {
        border-radius: 0 0 0 6px;
      }
      &.active {
        background: transparent;
        border-left: 6px solid #50b041;
      }
    }
  }
  .tabs-content {
    padding: 20px 0;
    .executivepartnerstrust_wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .executivepatners_trust {
        display: flex;
        width: 100%;
        margin: 0 0 10px;
        position: relative;
        padding-bottom: 10px;
        &::after {
          position: absolute;
          content: "";
          width: 95%;
          height: 1px;
          background: #dcdcdc;
          bottom: 0;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .exectveprtnetrst_shrtnme {
          background: #4bc2d2;
          width: 50px;
          height: 40px;
          border-radius: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          margin-right: 15px;
        }
        .exectveprtnetrst_right {
          display: flex;
          flex-direction: column;
          width: 100%;
          .exectveprtnetrst_fllnme {
            display: flex;
            color: #4bc2d2;
            font-family: "Euclid Circular B";
            font-size: px-to-rem(16);
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 5px;
          }
          .exectveprtnetrst_content {
            display: flex;
            align-items: center;
            > span {
              color: #333;
              font-family: $font-1;
              font-size: px-to-rem(14);
              font-weight: 500;
              padding-left: 5px;
              position: relative;
              text-transform: capitalize;
              &:first-child {
                padding-left: 0;
                padding-right: 5px;
                &::after {
                  background: #888;
                  height: 12px;
                  right: -1px;
                  top: 3px;
                  position: absolute;
                  content: "";
                  width: 2px;
                }
              }
            }
            .exectveprtnetrst_spocswrap {
              display: flex;
              margin-left: 10px;
              .exectveprtnetrst_spocsitem {
                display: flex;
                position: relative;
                margin-right: -4px;
                .exectveprtnetrst_spocssrtnme {
                  display: flex;
                  width: 30px;
                  height: 30px;
                  border-radius: 100px;
                  color: #ffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-transform: uppercase;
                }
                .spocshover_content {
                  background: #333;
                  position: absolute;
                  bottom: -68px;
                  right: calc(100% - 150px);
                  color: #fff;
                  padding: 10px;
                  border-radius: 4px;
                  transform: translateX(-50px);
                  opacity: 0;
                  visibility: hidden;
                  z-index: 1;
                  transition: 0.3s ease-out;
                  &::after {
                    content: "";
                    position: absolute;
                    top: -7px;
                    right: calc(100% - 50%);
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 7px solid #333;
                    transition: 0.3s;
                    transform: translateX(30%);
                    visibility: hidden;
                    opacity: 0;
                  }
                  .spocs_name {
                    color: #fff;
                    font-family: $font-1;
                    font-size: px-to-rem(16);
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.32px;
                  }
                  .spocsinfo {
                    display: flex;
                    white-space: nowrap;
                    span {
                      color: #d6d0d0;
                      font-family: $font-1;
                      font-size: px-to-rem(14);
                      font-weight: 400;
                      letter-spacing: 0.28px;
                      padding-left: 5px;
                      position: relative;
                      &:first-child {
                        padding-left: 0;
                        padding-right: 5px;
                        &::after {
                          background: #d6d0d0;
                          height: 12px;
                          right: -1px;
                          top: 3px;
                          position: absolute;
                          content: "";
                          width: 2px;
                        }
                      }
                    }
                  }
                }
                &:hover {
                  .spocshover_content {
                    opacity: 1;
                    visibility: visible;
                    &::after {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                }

                &.blue {
                  .exectveprtnetrst_spocssrtnme {
                    background: #5fb3be;
                  }
                }
                &.red {
                  .exectveprtnetrst_spocssrtnme {
                    background: #c65555;
                  }
                }
              }
            }
          }
        }
      }
    }
    .onlyclspartnerstab_content {
      .executivepartnerstrust_wrap {
        .executivepatners_trust {
          &::after {
            display: none;
          }
          .exectveprtnetrst_right {
            .exectveprtnetrst_content {
              .exectveprtnetrst_spocswrap {
                .exectveprtnetrst_spocsitem {
                  &:last-child {
                    margin-right: 0;
                    .spocshover_content {
                      transform: translateX(-85px);
                      &::after {
                        right: calc(100% - 80%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .institlnaltabs_cont {
      .executivepartnerstrust_wrap {
        .executivepatners_trust {
          .exectveprtnetrst_right {
            .exectveprtnetrst_fllnme {
              color: #c57329;
            }
            .exectveprtnetrst_content {
              margin-top: 10px;
              > span {
                &:first-child {
                  width: 50%;
                  display: flex;
                  font-weight: 400;
                  abbr {
                    margin-left: auto;
                  }
                  &::after {
                    display: none;
                  }
                }
              }
              .exectveprtnetrst_spocswrap {
                margin-left: 0;
                .exectveprtnetrst_spocsitem {
                  .spocshover_content {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    left: -15px;
                    .spocsinfo {
                      span {
                        &:first-child:empty {
                          padding: 0;
                          &::after {
                            display: none;
                          }
                        }
                        &:first-child:empty + span {
                          padding-left: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin: 10px 0;
      li {
        margin-right: 5px;
        a {
          padding: 5px 11px;
          background: #333;
          border-radius: 4px;
          background: #b7b7b7;
          transition: 0.3s;
          i {
            color: #fff;
            font-size: 20px;
          }
          &:hover {
            background: #50b041;
          }
        }
      }
    }
  }
}
.sitedetailsaccord_wrap {
  .sitedetailsaccord_heading {
    h3 {
      color: #c57329;
      font-family: $font-1;
      font-size: px-to-rem(16);
      font-weight: 600;
      line-height: 20px;
    }
  }
  .prosummarylist {
    margin-bottom: 20px;
    .prosummarylist_item {
      .produmitem_title {
        display: flex;
        width: 30%;
      }
    }
  }
  &.objectivedetailsaccord_wrap,
  &.riskdetailsaccord_wrap {
    .prosummarylist {
      .prosummarylist_item {
        .produmitem_title {
          width: 50%;
        }
      }
    }
  }
}
.sitedetailstabs_cont {
  display: flex;
  flex-wrap: wrap;
  .tab-buttons {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    position: sticky;
    top: 30px;
    background: #fff;
    z-index: 2;
    @include media-min(xl) {
      top: 38px;
    }
    button {
      background: #fff;
      border: none;
      display: flex;
      height: 44px;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      &.active {
        color: #fff;
        background: #50b041;
        border-radius: 5px 5px 0px 0px;
      }
    }
  }
  .tabs-content {
    background: #e5f3e3;
    width: 100%;
    padding: 20px;
    .yearsninfo_wrap {
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        margin: 0 0 10px;
        span {
          color: #333;
          font-family: $font-1;
          font-size: px-to-rem(14);
          font-weight: 500;
          line-height: 24px;
          text-transform: capitalize;
          &:first-child {
            font-weight: 600;
            width: 30%;
          }
        }
      }
    }
    .floratype_cont {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .faflmicTypes_heading {
        display: flex;
        color: #fff;
        padding: 0 20px;
        border-radius: 5px;
        background: #333;
        height: 36px;
        align-items: center;
        color: #fff;
        font-family: "Euclid Circular B";
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
      .floratypelist_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px 0 0;
        width: 100%;
        .floratypelist_item {
          display: flex;
          width: 48%;
          margin: 0 20px 20px 0;
          &:nth-child(2n + 2) {
            margin-right: 0;
          }
          .floratypelist_img {
            margin-right: 10px;
            width: 110px;
            height: 73px;
            img {
              border-radius: 6px;
              object-fit: fill;
              max-width: 100%;
              max-height: 100%;
              width: 100%;
              height: 100%;
            }
          }
          .floratypelist_content {
            display: flex;
            flex-direction: column;
            width: 100%;
            div {
              display: flex;
              margin: 0 0 10px;
              span {
                color: #333;
                font-family: $font-1;
                font-size: px-to-rem(14);
                font-weight: 500;
                width: 50%;
                text-transform: capitalize;
                &:first-child {
                  display: flex;
                  font-weight: 400;
                  abbr {
                    margin-left: auto;
                  }
                }
              }
            }
          }
        }
      }
      &.microbestype_cont {
        .floratypelist_wrap {
          .floratypelist_item {
            width: 100%;
            margin: 0 0 20px 0;
          }
        }
      }
      &.climatewatercontent_wrap {
        .climatedetails_cont {
          display: flex;
          flex-wrap: wrap;
          padding-right: 20px;
          margin-top: 20px;
          .climatedetails_item {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            margin: 0 0 20px;
            align-items: center;
            .climatedetails_heading {
              width: 25%;
              word-break: break-word;
              display: flex;
              align-items: center;
              h3 {
                color: #333;
                font-family: $font-1;
                font-size: px-to-rem(14);
                font-weight: 500;
                margin: 0;
              }
            }
            .climatedetails_tblcontent {
              width: 74%;
              padding-left: 3%;
              .prosumarytbl {
                margin: 0;
                tbody {
                  position: relative;
                  &::before,
                  &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 15px;
                    height: 100%;
                    z-index: 0;
                    border-radius: 50px 0 0 50px;
                  }
                  &::before {
                    left: -12px;
                  }
                  &::after {
                    right: -14px;
                    transform: rotate(180deg);
                  }
                  tr {
                    td {
                      text-align: center;
                      line-height: 24px;
                      position: relative;
                      color: #333;
                      &.activetype_td {
                        &::after {
                          border: 1px solid #333;
                          width: 100%;
                          height: 35px;
                          content: "";
                          position: absolute;
                          left: calc(50% - 52%);
                          top: -5px;
                          border-radius: 4px;
                        }
                      }
                    }
                  }
                }
                &.sitedet_climatetbl {
                  td {
                    &:nth-child(2) {
                      text-align: center;
                    }
                  }
                }
                &.sitedet_avgrainfalltbl,
                &.sitedet_humiditytbl,
                &.sitedet_windkmhpbl,
                &.sitedet_aqibl {
                  tbody {
                    tr {
                      td {
                        color: #fff;
                        text-align: center;
                      }
                    }
                  }
                }
                &.sitedet_avgrainfalltbl {
                  tbody {
                    background: linear-gradient(
                      90deg,
                      #95b0f6 0.49%,
                      #89a6f0 21.87%,
                      #7393e4 45.75%,
                      #3e68d2 72.61%,
                      #1f51d1 99.97%
                    );
                    &::before {
                      background: linear-gradient(
                        90deg,
                        #95b0f6 0.49%,
                        #95b0f6 21.87%
                      );
                    }
                    &::after {
                      background: linear-gradient(
                        90deg,
                        #1f51d1 72.61%,
                        #1f51d1 99.97%
                      );
                    }
                  }
                }
                &.sitedet_humiditytbl {
                  tbody {
                    background: linear-gradient(
                      90deg,
                      #ae6e38 0.49%,
                      #ae6e38 21.87%,
                      #82a338 45.75%,
                      #388bae 72.61%,
                      #384774 99.97%
                    );
                    &::before {
                      background: linear-gradient(
                        90deg,
                        #ae6e38 0.49%,
                        #ae6e38 21.87%
                      );
                    }
                    &::after {
                      background: linear-gradient(
                        90deg,
                        #384774 72.61%,
                        #384774 99.97%
                      );
                    }
                  }
                }
                &.sitedet_windkmhpbl {
                  tbody {
                    background: linear-gradient(
                      90deg,
                      #5b46a8 0.49%,
                      #67a3c5 21.87%,
                      #f7ea77 45.75%,
                      #b13b54 72.61%,
                      #621341 99.97%
                    );
                    &::before {
                      background: linear-gradient(
                        90deg,
                        #5b46a8 0.49%,
                        #5b46a8 21.87%
                      );
                    }
                    &::after {
                      background: linear-gradient(
                        90deg,
                        #621341 72.61%,
                        #621341 99.97%
                      );
                    }
                  }
                }
                &.sitedet_aqibl {
                  tbody {
                    background: linear-gradient(
                      90deg,
                      #00b14b 0.49%,
                      #90d246 25.36%,
                      #e2e220 40.28%,
                      #ff9a00 60.18%,
                      #f00 80.07%,
                      #c20000 99.97%
                    );
                    &::before {
                      background: linear-gradient(
                        90deg,
                        #00b14b 0.49%,
                        #00b14b 25.36%
                      );
                    }
                    &::after {
                      background: linear-gradient(
                        90deg,
                        #c20000 80.07%,
                        #c20000 99.97%
                      );
                    }
                  }
                }
              }
            }
            &.lasttwoheading_maxwidth {
              .climatedetails_heading {
                width: 40%;
              }
              .climatedetails_tblcontent {
                width: 60%;
                .prosumarytbl {
                  &.prosumarytbl {
                    tbody {
                      background: #ffda58;
                      &::before,
                      &::after {
                        background: #ffda58;
                      }
                    }
                  }
                }
              }
            }
          }
          &.waterdetails_cont {
            .climatedetails_item {
              .climatedetails_tblcontent {
                .waterhardnesstbls_wrap {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 1px;
                  align-items: center;
                  > div {
                    width: 130px;
                    color: #333;
                    text-align: center;
                    font-family: "Euclid Circular B";
                    font-size: 12px;
                    font-weight: 500;
                    margin-right: 30px;
                  }
                }
                .prosumarytbl {
                  &.water_turbiditytbl {
                    tbody {
                      &::before {
                        background: #c56b36;
                      }
                      &::after {
                        background: #f5f5f3;
                      }
                      tr {
                        td {
                          text-align: center;
                          color: #333;
                          &:first-child {
                            background: #c56b36;
                          }
                          &:nth-child(2) {
                            background: #db9b6e;
                          }
                          &:nth-child(3) {
                            background: #e5c6aa;
                          }
                          &:nth-child(4) {
                            background: #e5e1d0;
                          }
                          &:last-child {
                            background: #f5f5f3;
                          }
                        }
                      }
                    }
                  }
                  &.water_tastenodertbl {
                    tbody {
                      &::before {
                        background: #26a9e1;
                      }
                      &::after {
                        background: #ed1b24;
                      }
                      tr {
                        td {
                          text-align: center;
                          width: calc(100% / 6);
                          color: #fff;
                          &:first-child {
                            background: #26a9e1;
                          }
                          &:nth-child(2) {
                            background: #1a75bb;
                          }
                          &:nth-child(3) {
                            background: #2c3791;
                          }
                          &:nth-child(4) {
                            background: #912891;
                          }
                          &:nth-child(5) {
                            background: #f5b91d;
                          }
                          &:last-child {
                            background: #ed1b24;
                          }
                        }
                      }
                    }
                  }
                  &.water_hardnesstbl {
                    tbody {
                      &::before {
                        background: #43e403;
                      }
                      &::after {
                        background: #fe0000;
                      }
                      tr {
                        td {
                          text-align: center;
                          width: calc(100% / 4);
                          color: #333;
                          font-weight: 600;
                          &:first-child {
                            background: #43e403;
                          }
                          &:nth-child(2) {
                            background: #ffff01;
                          }
                          &:nth-child(3) {
                            background: #ffc001;
                          }
                          &:last-child {
                            background: #fe0000;
                          }
                        }
                      }
                    }
                  }
                  &.water_phscaletbl {
                    tbody {
                      &::before {
                        background: #e80202;
                      }
                      &::after {
                        background: #8e03a4;
                      }
                      tr {
                        td {
                          text-align: center;
                          width: calc(100% / 15);
                          color: #fff;
                          &:first-child {
                            background: #e80202;
                          }
                          &:nth-child(2) {
                            background: #ed7709;
                          }
                          &:nth-child(3) {
                            background: #e8b001;
                          }
                          &:nth-child(4) {
                            background: #e0d404;
                          }
                          &:nth-child(5) {
                            background: #a9d20a;
                          }
                          &:nth-child(6) {
                            background: #7dd50f;
                          }
                          &:nth-child(7) {
                            background: #1fd207;
                          }
                          &:nth-child(8) {
                            background: #10d377;
                          }
                          &:nth-child(9) {
                            background: #11c4c8;
                          }
                          &:nth-child(10) {
                            background: #0290ce;
                          }
                          &:nth-child(11) {
                            background: #0290ce;
                          }
                          &:nth-child(12) {
                            background: #075ed1;
                          }
                          &:nth-child(13) {
                            background: #1a1bce;
                          }
                          &:nth-child(14) {
                            background: #6d00d0;
                          }
                          &:last-child {
                            background: #8e03a4;
                          }
                        }
                      }
                    }
                  }
                }
                .watertblunits_cont {
                  display: flex;
                  padding: 15px 15px;
                  justify-content: space-between;
                  span {
                    color: #333;
                    font-family: "Euclid Circular B";
                    font-size: 12px;
                    font-weight: 500;
                  }
                  &.water_tastenodertblfoot {
                    padding: 15px 0;
                    span {
                      text-align: center;
                    }
                  }
                  &.water_hardnesstblfoot {
                    margin-left: 100px;
                  }
                }
              }
            }
          }
        }
        &.soildetailscontent_wrap {
          .climatedetails_cont {
            .climatedetails_item {
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
}
.watertoxicsubstance_wrap {
  display: flex;
  margin-top: 20px;
  .watertoxicsubstance_cont {
    display: flex;
    margin: 0 0 20px;
    .watertoxicsubstance_left {
      width: 30%;
      color: #c57329;
      font-family: "Euclid Circular B";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    .watertoxicsubstance_right {
      display: flex;
      flex-direction: column;
      h3,
      p {
        color: #333;
        font-family: $font-1;
        font-size: px-to-rem(14);
        h3 {
          font-weight: 700;
        }
        p {
          font-weight: 400;
        }
      }
    }
  }
}
.soilswrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 20px 0;
  div {
    display: flex;
    flex-direction: column;
    width: calc(100% / 4 - 15px);
    margin: 0 19px 0 0;
    text-align: center;
    font-size: 14px;
    &:last-child {
      margin-right: 0;
    }
    img {
      border-radius: 4px;
      margin: 0 0 5px;
    }
  }
}
.socialwrap {
  display: flex;
  margin: 20px 0;
  flex-wrap: wrap;
  .socialtop_cont {
    display: flex;
    flex-wrap: wrap;
    .socialtop_left {
      width: 50%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      div {
        display: flex;
        width: 48%;
        background: #ffff;
        margin: 0 1% 10px;
        flex-direction: column;
        padding: 15px;
        border-radius: 5px;
        align-items: center;
        text-align: center;
        .social_counts {
          color: #c57329;
          text-align: center;
          font-family: $font-1;
          font-size: px-to-rem(26);
          font-weight: 600;
          line-height: 24px;
          margin: 0 0 10px;
        }
        .social_content {
          color: #333;
          font-family: $font-1;
          font-size: px-to-rem(12);
          font-weight: 400;
          abbr {
            display: flex;
            font-size: 10px;
            color: #6c6c6c;
          }
        }
      }
    }
    .socialtop_right {
      display: flex;
      align-items: center;
      padding-left: 15px;
    }
  }
  .socialbot_cont {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      width: 48%;
      margin: 0 1%;
      background: #ffff;
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .socialbot_heading {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
        color: #333;
        font-family: "Euclid Circular B";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 10px;
      }
      > div {
        width: 50%;

        img {
          float: left;
          margin-right: 15px;
        }
        span {
          flex: 0 0 100%;
          max-width: 100%;
          display: flex;
          &.socialbot_contenthead {
            color: #333;
            font-family: "Euclid Circular B";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          &.socialbot_count {
            color: #c98446;
            font-family: "Euclid Circular B";
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
      &.socialbot_left {
        .socialbotiner_left {
          .socialbot_count {
            color: #eb5db3;
          }
        }
        .socialbotiner_right {
          .socialbot_count {
            color: #0eb7a2;
          }
        }
      }
      &.socialbot_right {
        .socialbotiner_right {
          .socialbot_count {
            color: #eb5db3;
          }
        }
      }
    }
  }
}
.institutionaltabs_content {
  .institutetabs_item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    span {
      display: flex;
      color: #333;
      font-family: $font-1;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      width: 50%;
      &:first-child {
        abbr {
          margin-left: auto;
        }
      }
    }
  }
}
.ownershiptab_content {
  padding-right: 20px;
  .owershiptabtop_cont {
    .ownertb_ownrnme {
      color: #c57329;
      font-family: $font-1;
      font-size: px-to-rem(16);
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .ownertbtypmobile_wrap {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      .ownertb_ownertype,
      .ownertb_ownermobile {
        color: #333;
        font-family: $font-1;
        font-size: px-to-rem(14);
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .ownertb_ownertype,
      .ownertb_ownermobile span {
        text-transform: capitalize;
      }
    }
  }
  .prosumacordinertbl_wrap {
    .prosumacord_inertbl {
      margin: 0 0 20px;
      &:last-child {
        margin: 0;
      }
      thead {
        background: transparent;
        th {
          color: #333;
          font-family: $font-1;
          font-size: px-to-rem(16);
          font-weight: 500;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            font-size: 0;
          }
        }
      }
      tbody {
        tr {
          position: relative;
          td {
            div {
              text-transform: capitalize;
            }
            &:last-child {
              button {
                background: #333;
                border: none;
                height: 28px;
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 4px;
                width: 28px;
              }
            }
          }
        }
      }
    }
  }
}
.prosummarylist {
  .prosummarylist_item {
    margin: 0 0 10px;
    font-family: $font-1;
    font-size: px-to-rem(14);
    display: flex;
    @include media-max(xl) {
      font-size: px-to-rem(13);
    }
    .produmitem_title {
      color: #333;
      font-weight: 500;
      min-width: 120px;
      display: flex;
      text-transform: capitalize;
      abbr {
        margin-left: auto;
      }
    }
    .produmitem_content {
      color: #636262;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}
.prosumarytbl {
  width: 100%;
  margin: 20px 0 0;
  font-family: $font-1;
  thead {
    font-weight: 500;
    tr {
      height: 24px;
      th {
        font-size: px-to-rem(14);
        font-weight: 500;
        line-height: 32px;
        color: #333;
        @include media-max(xl) {
          font-size: px-to-rem(13);
        }
      }
    }
  }
  tbody {
    position: relative;
    tr {
      height: 24px;
      td {
        font-size: px-to-rem(12);
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
  &.prosumartbltemp,
  &.prosumartblrainfall {
    margin: 20px 15px 0;
    thead {
      position: relative;
      left: -11px;
    }
    tbody {
      tr {
        td {
          position: relative;
          text-align: center;
          &.activetype_td {
            &::after {
              border: 1px solid #333;
              width: 100%;
              height: 35px;
              content: "";
              position: absolute;
              left: calc(50% - 52%);
              top: -5px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
  &.prosumartbltemp {
    tbody {
      background: linear-gradient(
        90deg,
        #68a8f4 0.49%,
        #68a8f4 21.87%,
        #b9d3fb 45.75%,
        #ffd057 72.61%,
        #ff2e00 99.97%
      );
      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #68a8f4 0.49%, #68a8f4 51.87%);
        z-index: 0;
        border-radius: 50px 0 0 50px;
      }
      &::after {
        content: "";
        position: absolute;
        right: -14px;
        top: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #ff2e00 72.61%, #ff2e00 99.97%);
        z-index: 0;
        border-radius: 50px 0 0 50px;
        transform: rotate(180deg);
      }
      tr td {
        color: #333;
      }
    }
  }
  &.prosumartblrainfall {
    thead {
      th {
        &[colspan="3"] {
          padding-left: 12px;
        }
        &[colspan="2"] {
          padding-left: 10px;
        }
      }
    }
    tbody {
      background: linear-gradient(
        90deg,
        #b69dfc 0.49%,
        #9b7fec 21.87%,
        #8e71e3 45.75%,
        #603bc9 72.61%,
        #3d1b9e 99.97%
      );
      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #b69dfc 0.49%, #b69dfc 51.87%);
        z-index: 0;
        border-radius: 50px 0 0 50px;
      }
      &::after {
        content: "";
        position: absolute;
        right: -14px;
        top: 0;
        width: 15px;
        height: 100%;
        background: linear-gradient(90deg, #3d1b9e 72.61%, #3d1b9e 99.97%);
        z-index: 0;
        border-radius: 50px 0 0 50px;
        transform: rotate(180deg);
      }
      tr td {
        color: #fff;
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          width: 42px;
        }
        &:nth-child(3n + 4) {
          width: 30px;
        }
      }
    }
  }
}
.prosummap_cont {
  h3 {
    color: #585858;
    font-family: $font-poppins;
    font-size: px-to-rem(15);
    font-weight: 600;
    line-height: 32px;
  }
}
.legendscont_wrap {
  position: absolute;
  bottom: 15px;
  left: 55px;
  z-index: 2;
  .legendscont {
    margin: 0 0 10px;
    &:last-child {
      margin: 0;
    }
  }
}
.legendscont {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  height: 30px;
  align-items: center;
  .legends_heading {
    display: flex;
    position: relative;
    padding: 0 10px;
    font-family: "EuclidCircularBRegular";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    &::after {
      background: url("../icons/legendsheading_arrow.png");
      content: "";
      position: absolute;
      top: -8px;
      right: 0;
      width: 7px;
      height: 32px;
    }
  }
  .legends_list {
    display: flex;
    .legend_item {
      display: flex;
      position: relative;
      margin: 0 8px;
      border-radius: 50%;
      .legend_clr {
        display: flex;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .legend_clrname {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        font-family: "EuclidCircularBRegular";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        transition: opacity 0s, top 0.4s;
        left: -9999px !important;
        .legendclrname_arrow {
          position: absolute;
          bottom: -7px;
          left: 12px;
          visibility: hidden;
          opacity: 0;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top-width: 7px;
          border-top-style: solid;
          transition: 0.3s;
        }
        &[style="background-color: rgb(240, 240, 240);"] {
          color: #000 !important;
        }
      }
      &:hover {
        .legend_clrname {
          opacity: 1;
          top: -36px;
          visibility: visible;
          border-radius: 2px;
          padding: 5px 7px;
          color: #fff;
          left: -15px !important;
          white-space: nowrap;
          .legendclrname_arrow {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      &.legendgreen {
        background: #20ce23;
        .legend_clrname {
          background: #20ce23;
          &::after {
            border-top: 7px solid #20ce23;
          }
        }
      }
      &.legendbrown {
        background: #4f2600;
        .legend_clrname {
          background: #4f2600;
          &::after {
            border-top: 7px solid #4f2600;
          }
        }
      }
      &.legendblue {
        background: #6e9ce1;
        .legend_clrname {
          background: #6e9ce1;
          &::after {
            border-top: 7px solid #6e9ce1;
          }
        }
      }
      &.legendorange {
        background: #ff7f00;
        .legend_clrname {
          background: #ff7f00;
          &::after {
            border-top: 7px solid #ff7f00;
          }
        }
      }
      &.legendlgtgreen {
        background: #9ef315;
        .legend_clrname {
          background: #9ef315;
          &::after {
            border-top: 7px solid #9ef315;
          }
        }
      }
      &.legenblack {
        background: #2f2f2f;
        .legend_clrname {
          background: #2f2f2f;
          &::after {
            border-top: 7px solid #2f2f2f;
          }
        }
      }
      &.legendgray {
        background: #848484;
        .legend_clrname {
          background: #848484;
          &::after {
            border-top: 7px solid #848484;
          }
        }
      }
      &.legendwhite {
        background: #fff;
        .legend_clrname {
          background: #fff;
          color: #333;
          &::after {
            border-top: 7px solid #fff;
          }
        }
      }
    }
  }
}
.prolistmodal {
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: calc(100% - 11%);
  height: calc(100vh - 220px);
  &.approvalgsdata_cont {
    background-color: #fff !important;
    height: auto;
    width: calc(100% - 8%);
    position: relative;
    .loader-overlay {
      border-radius: 20px;
    }
    .selectsearch_wrap {
      justify-content: flex-start;
      align-items: center;
      select {
        margin-left: 10px;
      }
      .addpro_btn {
        margin-left: 10px;
        &.addproreject_btn {
          margin-left: 10px;
          a {
            background: #b54e56 !important;
          }
        }
      }
    }
    .growalarm_content .commontbl_wrap {
      .commontbl_cont {
        thead th {
          padding: 0;
          &:first-child {
            text-align: center;
            width: 5%;
            input[type="checkbox"] {
              top: 2px;
              position: relative;
              cursor: pointer;
            }
          }
          &.thGridNo {
            width: 5%;
            text-align: left;
          }
          &:last-child {
            text-align: left;
          }
        }
        tbody {
          tr {
            td {
              width: auto;
              padding: 0 5px 0 0;
              &:first-child {
                width: 5%;
                padding-right: 0;
                input[type="checkbox"] {
                  top: 2px;
                  position: relative;
                  cursor: pointer;
                }
              }
              &.statustwo {
                text-align: left;
                &.rejected {
                  span {
                    background: #b54e56;
                  }
                }
                &.pending {
                  span {
                    background: #e5b000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .prosumrymodal_header {
    padding: 0 15px 0 5px;
  }
  .projectmodal_body {
    overflow-y: hidden;
    padding-bottom: 95px;
    position: relative;
  }
  .prosumrymodal_close {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Pojectsummary_cont {
    padding-bottom: 0;
    overflow-x: hidden;
  }
  button[aria-label="Close"] {
    background: transparent;
    display: none;
  }
  .pf-c-modal-box__header {
    display: none;
  }

  .pf-c-modal-box__body {
    border-radius: 0px 0px 6px 6px;
    opacity: 1;
    background: transparent;
    margin-right: 0;
  }
  .selectsearch_wrap {
    margin: 0 0 15px;
    display: flex;
    justify-content: space-between;
    label {
      font-weight: 600;
    }
    .prolstvewpop_select {
      display: flex;
      width: 230px;
      height: 40px;
      padding: 10px 14px;
      border-radius: 4px;
      font-family: $font-1;
      font-size: px-to-rem(16);
      line-height: 18px;
      font-weight: 500;
      appearance: none;
      background-image: url("../icons/listview_selectarrow.png");
      background-repeat: no-repeat;
      background-position: right 0.7rem top 50%;
      background-size: 0.65rem auto;
      margin-right: auto;
      &:focus-visible {
        outline: none;
      }
      option {
        font-family: $font-1;
        font-size: px-to-rem(16);
        font-style: normal;
        font-weight: 500;
      }
    }
    .projlistview_search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      input {
        display: inline-flex;
        height: 40px;
        padding: 10px 35px 10px 15px;
        justify-content: center;
        align-items: center;
        color: #333;
        font-family: "Euclid Circular B";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        width: 320px;
        border-radius: 4px;
        border: none;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #333;
          transform: translate(0);
        }
      }
      .projlistsrchbtn {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }
  }
  table.prolistviewtbl {
    width: 100%;
    thead {
      border-radius: 10px;
      border-style: none;
      position: sticky;
      top: 0;
      z-index: 1;
      tr {
        &:first-child {
          border-radius: 10px;
          border-style: none;
        }
        th {
          &:first-child {
            border-radius: 10px 0 0 0;
            border: none;
            padding-left: 10px;
          }
          &:last-child {
            border-radius: 0 10px 0 0;
            border: none;
            text-align: center;
          }
        }
      }
    }
    tbody {
      background: #fff;
    }
    tr {
      &:nth-child(odd) {
        background-color: #edf2f8;
      }
      &.listTrActive {
        background-color: #b3ddb4;
      }
    }
    th {
      color: #fff;
      font-family: $font-poppins;
      font-size: 14px;
      font-weight: 500;
      height: 46px;
      line-height: 21px;
      padding: 0 4px;
      position: relative;
      text-align: left;
      white-space: nowrap;
      background-color: #333;
      &.thGridNo,
      &.treeheighttd {
        text-align: center;
      }
    }
    td {
      height: 60px;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      padding: 0 4px;
      &:first-child {
        padding-left: 10px;
        text-align: center;
      }
      &.treeidtd {
        color: #1da5de;
        font-weight: 600;
      }
      &.treesplantbytd {
        white-space: nowrap;
      }
      &.alivetd {
        span {
          display: inline-flex;
          padding: 0 14px;
          height: 32px;
          color: #fff;
          border-radius: 100px;
          background: #50b041;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          align-items: center;
        }
      }
      &.listviewmediatd {
        padding: 7px 4px;
        > img.defaultimg_treelist {
          width: 40px;
          border-radius: 4px;
          border: 1px solid #ddd;
        }
        .listviewmedia_popup {
          width: 40px;
          display: flex;
          justify-content: center;
          img {
            width: 40px;
            height: 40px;
            border-radius: 4px;
          }
          &.listviewvideos_cont {
            margin-top: 5px;
            i {
              color: #333;
            }
          }
        }
      }
      &.action_cont,
      &.treeheighttd {
        text-align: center;
      }
      &.action_cont {
        .editicon_btn {
          margin-left: 10px;
        }
        .thmbsupdwn_apprvestas {
          margin-left: 10px;
        }
      }
    }
  }
  .paginationDiv {
    display: flex;
    justify-content: flex-end;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;
      li {
        margin-right: 5px;
        a {
          border: none;
          background: #fff;
          border-radius: 4px;
          color: #333;
          font-weight: 600;
          transition: 0.3s;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          line-height: 30px;
          padding: 0 10px;
          &:hover {
            background: #50b041;
            color: #fff;
          }
        }
        &.active a {
          background: #50b041;
          color: #fff;
          border-color: #50b041;
        }
        &.disabled {
          display: flex !important;
          a {
            background: transparent;
            i {
              color: #b6b6b6;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
.prosumacord_inertbl {
  display: table;
  width: 100%;
  thead tr th,
  tbody tr td {
    padding: 5px;
    &:first-child {
      padding-left: 20px;
    }
  }
  thead {
    background: #333;
    border-bottom: 1px solid #fff;
    border-radius: 5px 5px 0 0;
    height: 30px;
    border: none;
    tr {
      th {
        color: #fff;
        font-size: px-to-rem(14);
        font-weight: 500;
        border: none;
        &:first-child {
          border-radius: 5px 0 0 0;
        }
        &:last-child {
          border-radius: 0 5px 0 0;
        }
      }
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: #f3e3d4;
      }
      background: #f8eee5;
      td {
        color: #636262;
        font-size: px-to-rem(14);
        font-weight: 500;
        border-bottom: 1px solid #fff;
        text-transform: capitalize;
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
        &:last-child {
          border-radius: 0 4px 4px 0;
        }
        &:empty {
          padding: 0;
        }
      }
    }
  }
  &.bluetheme_inertbl {
    tbody {
      tr {
        &:nth-child(odd) {
          background: #d4eef9;
        }
        background: #e5f4fb;
        td {
          &:last-child {
            background: #38a5d2;
          }
        }
      }
    }
  }
  &.orange_inertbl {
    tbody {
      tr {
        &:nth-child(odd) {
          background: #fef1d1;
        }
        background: #fef6e3;
        td {
          &:last-child {
            background: #fbbb18;
          }
        }
      }
    }
  }
  &.bluetheme_inertbl,
  &.orange_inertbl {
    thead {
      display: none;
    }
    tbody {
      tr {
        td {
          color: #333;
          font-weight: 600;
          &:first-child {
            width: 80%;
          }
          &:last-child {
            width: 20%;
            text-align: right;
            color: #fff;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
.prosumacordtbls_wrap {
  table.prosumacord_inertbl {
    margin: 0 0 10px;
  }
}
.sponsorshipacord_content {
  .row {
    .hero-title20 {
      margin: 0 0 5px !important;
    }
  }
  .prosummarylist {
    margin: 0 0 10px;
    .prosummarylist_item {
      margin: 0 0 5px;
      &.sponseracordamt {
        .produmitem_content {
          font-weight: 600;
        }
      }
    }
  }
}
.prosumryrprt_btncont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto !important;
  button {
    background: #50b041;
    font-family: $font-1;
    font-size: px-to-rem(16);
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border: none;
    color: #fff;
    border-radius: 4px;
    font-weight: 600;
    svg {
      margin-left: 10px;
    }
  }
}
.prosumreport_cont {
  width: 900px;
  margin: 0 auto;
  .prosumtop_cont {
    padding: 20px 40px;
    border: 1px solid #ddd;
    border-bottom: none;
  }
  .Pojectsummary_cont {
    background: #fff;
    padding: 30px;
  }
  .prosumtop_cont {
    li {
      .prosum_data {
        span {
          &:first-child {
            color: #444;
          }
        }
      }
      &.prosumtopfunrsed_cont {
        .prosum_data {
          .totalfndrsedamt_cont {
            color: #444;
            bottom: 10px;
          }
          .prolist_progress > div .pf-c-progress__bar::before {
            background-color: #dadada;
          }
        }
      }
    }
  }
  .prosumaccord_cont {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .prosumrprt_acord {
      background: #f9f9f9;
      margin: 0 0 20px;
      width: 100%;
      .prosumaccord_head {
        background: #444444;
        display: flex;
        padding: 10px 15px;
        align-items: center;
        svg path {
          fill: #fff;
        }
        h3 {
          padding-left: 15px;
          color: #fff;
          font-size: 16px;
          margin: 0;
          font-weight: 600;
          font-family: "EuclidCircularBRegular";
        }
      }
      .prosumrprt_acordcontent {
        padding: 20px 15px 10px;
        font-family: $font-1;
        .row {
          &.partshprosumpacrd_content {
            margin: 0 -25px 0 0px;
            .row > * {
              padding: 0;
            }
            .prosumacordtbls_wrap {
              .prosumacord_inertbl {
                &:last-child {
                  margin: 0 0 20px;
                }
              }
            }
          }
          .hero-title20 {
            margin: 0 0 10px;
          }
          .prosumacordtbls_wrap {
            .project-summary19 {
              font-size: px-to-rem(14);
              line-height: 24px;
              font-weight: 500;
              margin-bottom: 10px;
            }
          }
        }
      }
      .sitedetailstabs_cont .tabs-content {
        background: #fff;
      }
    }
  }
}
.locationaccordscont_wrap {
  .locationtbls_heading {
    color: #333;
    font-family: $font-1;
    font-size: px-to-rem(16);
    font-weight: 600;
    line-height: 24px;
  }
  .prosumacord_inertbl {
    thead tr {
      th {
        &:last-child {
          font-size: 0;
        }
      }
    }
  }
  .viewmore {
    display: flex;
    border: none;
    background: transparent;
    justify-content: flex-end;
    text-align: right;
    width: 100%;
    color: #50b041;
    font-family: "Euclid Circular B";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    padding: 0 10px;
    margin: 5px 0 0;
    position: relative;
  }
}
.parentsublayers_wrap {
  border-top: 1px solid #d1d1d1;
  padding: 10px 0 0;
  .parentsublayers_heading {
    color: #333;
    font-family: $font-1;
    font-size: px-to-rem(16);
    font-weight: 400;
    margin: 0 0 10px;
    svg {
      margin-left: 10px;
    }
  }
}
.sublaerysparent_cont {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 30px;
  padding-bottom: 15px;
  overflow: hidden;
  position: relative;
  .sublayers_heading {
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #dddd;
    display: flex;
    margin: 0 0 10px;
    padding: 0 0 10px;
    .layers_speciescount {
      margin-left: 5px;
    }
    align-items: center;
    .sublayerstoggle {
      margin-left: auto;
      svg {
        transform: rotate(180deg);
        transition: 0.3s;
      }
      &.changesublayericon {
        svg {
          transform: rotate(0);
        }
      }
    }
  }
  .sublayers_innercont {
    display: flex;
    flex-direction: column;
    max-height: 0;
    transition: max-height 0.3s ease-out;
    overflow-y: auto;
    &.togglesublayerscont {
      max-height: 110px;
      transition: max-height 0.3s ease-in;
    }
    .maplayers_clrbtn {
      position: absolute;
      top: 0;
      right: 20px;
      a {
        color: $clr-green;
        font-family: $font-1;
        font-size: 14px;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        i {
          font-family: "FontAwesome";
          font-style: normal;
          text-decoration: none;
          font-size: 16px;
        }
        .layersinfo_tooltip {
          width: auto;
          white-space: nowrap;
          right: -7px;
        }
        .clearbtn_tooltiptxt {
          visibility: hidden;
          background-color: #50b041;
          color: #fff;
          text-align: center;
          border-radius: 4px;
          padding: 4px 6px;
          position: absolute;
          z-index: 1;
          top: -2px;
          right: 20px;
          white-space: nowrap;
          font-size: 12px;
          opacity: 0;
          transition: opacity 0.3s;
          &::after {
            position: absolute;
            content: "";
            top: 8px;
            right: -9px;
            visibility: hidden;
            opacity: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #50b041;
            transition: opacity 0.3s;
            transform: rotate(-90deg);
          }
        }
        &:hover {
          .clearbtn_tooltiptxt {
            visibility: visible;
            opacity: 1;
            &::after {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #d9d8d8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #50b041;
      border-radius: 10px;
    }
  }
}
.treesinfowindow_cont {
  background: #fff;
  width: 320px;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  top: 190px;
}
.infwdtree_head {
  background: #222;
  color: #fff;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  span {
    font-family: $font-1;
    font-weight: 500;
  }
}
.treesinfowindowitem_wrap {
  padding: 15px;
  .treesinfowindow_item {
    display: flex;
    margin-bottom: 5px;
    span {
      font-family: $font-1;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      &:first-child {
        width: 50%;
        font-weight: 500;
      }
    }
  }
  &.samplegridinfowindow_wrap {
    height: 250px;
    overflow-y: auto;
    .treesinfowindow_item {
      span {
        display: flex;
        width: 50%;
        white-space: pre-wrap;
        abbr {
          margin-left: auto;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #d9d8d8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #50b041;
      border-radius: 10px;
    }
  }
}
.projdetailpage_modal {
  button[aria-label="Close"] {
    right: -10px;
    top: -10px;
    background: #50b041;
    padding: 0;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    font-size: px-to-rem(14);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.projdetimgcarusel_modal {
    border-radius: 10px;
    top: 30px;
    .pf-c-modal-box__body {
      margin-right: 0;
      padding-bottom: 0;
      .thumbs-wrapper {
        margin: 15px 0;
        .thumbs {
          margin: 0;
          padding: 0;
          .thumb {
            width: 77px !important;
            height: 77px !important;
            margin-right: 6px !important;
            padding: 0;
            &:hover,
            &.selected {
              border: none !important;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .treedetails_cont .treedetimg {
        .slider-wrapper .slider {
          li {
            img {
              height: calc(100vh - 280px);
              width: auto;
              border-radius: 8px;
            }
          }
        }
        .thumbs-wrapper {
          .thumbs {
            li {
              img {
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
  &.projdetaction_modal {
    border-radius: 10px;
    .pf-c-modal-box__body {
      padding: 0 !important;
      height: 450px;
      overflow-y: auto;
      .prosumcordtabscont_wrap {
        .tab-buttons {
          button {
            &:first-child {
              border-top-left-radius: 10px;
            }
          }
        }
        .tabs-content {
          padding: 20px 20px 20px 0;
          .actionmodal_imgcont {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            .actionmodal_tree {
              width: 76%;
              border-radius: 8px;
            }
          }
          .executivepartnerstrust_wrap {
            .executivepatners_trust {
              .exectveprtnetrst_right {
                .exectveprtnetrst_fllnme {
                  color: #c57329;
                }
                .institutionaltabs_content {
                  .institutetabs_item {
                    margin: 0 0 10px;
                    span {
                      display: flex;
                      abbr {
                        margin-left: auto;
                        display: inline-flex;
                      }
                      &:first-child {
                        font-weight: 500;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.partshpreportcont_wrap {
  .col-12 {
    .tabs-content {
      padding: 0;
      .partshpreport_heading {
        display: flex;
        padding: 10px 10px;
        background: #ebebeb;
        width: 100%;
        color: #333;
        font-weight: 600;
      }
      .executivepatners_trust {
        padding: 20px;
        margin-bottom: 20px;
        background: #fff;
        .ownershiptab_content {
          padding-right: 0;
        }
      }
      &.exepartnrs_tabscontent
        .executivepatners_trust
        .exectveprtnetrst_fllnme {
        color: #c57329;
      }
    }
  }
}

@media print {
  @page {
    size: A4;
    margin: 0;
    padding: 0;
  }
  .Pojectsummary_cont {
    width: 100% !important;
    padding: 0 !important;
  }
  .page-break-after {
    page-break-after: always;
  }
}
.layersinfo_tooltipcont {
  .layersinfo_tooltip {
    position: absolute;
    width: 140px;
    top: 25px;
    left: auto;
    z-index: 9;
    background: #333;
    font-size: 12px;
    color: #fff;
    padding: 5px;
    line-height: 18px;
    border-radius: 4px;
    right: 0;
    font-weight: 400;
    &::before {
      content: "";
      position: absolute;
      top: -7px;
      right: 5px;
      border-bottom: 8px solid #333;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }
}
.powebidashboard_cont {
  .newdashboard_heading {
    margin-top: 70px;
  }
  iframe {
    width: 100% !important;
    height: 100vh !important;
  }
  &.admindashboard_cont {
    margin: 0 0 0 50px;
  }
}
.sitedetailsaccord_wrap {
  &.sitedetailsfrpdf_cont {
    .sitedetailstabs_cont {
      .tabs-content {
        .sitedetailstabs_heading {
          background: #f3e3d4;
          margin: -20px -20px 20px;
          padding: 10px 20px;
          font-weight: 600;
        }
        .faflmicTypes_cont {
          .prosumacordtbls_wrap {
            .project-summary20 {
              margin-bottom: 10px;
              display: flex;
            }
            .prosumacordinertbl_wrap {
              margin: 0 0 20px;
            }
          }
        }
      }
    }
  }
}
.objectiveforpdf_cont {
  .sitedetailstabs_cont {
    .tabs-content {
      .faflmicTypes_cont {
        .prosumacordtbls_wrap {
          .project-summary20 {
            margin-bottom: 10px;
            display: flex;
          }
          .prosumacordinertbl_wrap {
            margin: 0 0 20px;
          }
        }
      }
    }
  }
}
.show-tooltip {
  position: relative;
}

.show-tooltip .layersinfo_tooltip {
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
}
.locationreportcont_wrap {
  .locationrpt_tabscontent {
    .executivepatners_trust {
      display: flex;
      flex-wrap: wrap;
      .eachLandParcelscontent_wrap {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        > div {
          display: flex;
          margin: 0 0 10px;
          span {
            width: 50%;
            color: #333;
            font-family: $font-1;
            font-size: px-to-rem(14);
            font-weight: 400;
          }
        }
      }
      .institutionaltabs_content {
        display: flex;
        width: 48%;
        margin: 0 29px 20px 0;
        flex-direction: column;
        .locationpdf_mapcont {
          margin: 10px 0 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }
}
.sitedetdisturbance_heading {
  display: flex;
  color: #fff;
  padding: 0 20px;
  border-radius: 5px;
  background: #333;
  height: 36px;
  align-items: center;
  color: #fff;
  font-family: "Euclid Circular B";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.sitedetdistbnce_tabscontent {
  .sitedetdisturbance_list {
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    margin: 0;
    .sitedetdisturbance_item {
      width: 48%;
      margin: 0 18px 15px 0;
      .prosummarylist_item {
        div {
          color: #333;
          &.produmitem_title {
            font-weight: 400;
            abbr {
              margin-left: auto;
            }
          }
          &.produmitem_content {
            font-weight: 500;
          }
        }
      }
      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }
    &.sitedetdisturbanceimct_list {
      .sitedetdisturbance_item {
        width: 100%;
        margin: 0 0 15px 0;
        .prosummarylist_item {
          width: 100%;
          div {
            width: 50% !important;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.proinfotooltip_cont {
  position: fixed !important;
  right: 291px;
  top: 177px;
  width: 220px;
  border-radius: 6px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: 0.3s ease-out;
  padding: 10px;
  z-index: 1;
  &.toggleproinfotooltip {
    opacity: 1;
    visibility: visible;
  }
  .tooltipcloseicon_btn {
    position: absolute;
    right: 10px;
    top: 0;
  }
  .projdetinfoiconpopu_cont {
    span {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 10px;
      display: flex;
    }
    p {
      font-size: 13px;
      line-height: 20px;
      margin: 0;
    }
  }
}
.layersinfo_tooltipcont {
  &.active_infoicon {
    svg,
    svg path {
      fill: #50b041;
    }
  }
}
.norecodfoundprosum_cont {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
}
.timeseriesbtn_cont {
  position: absolute;
  bottom: 60px;
  background: #fff;
  width: 36px;
  height: 36px;
  left: 10px;
  z-index: 2;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.open {
    bottom: 70px;
    span {
      position: absolute;
      bottom: -20px;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
}
.timeseries_wrap {
  display: flex;
  position: absolute;
  bottom: 40px;
  background: rgba(0, 0, 0, 0.65);
  left: 50%;
  z-index: 1;
  flex-direction: column;
  padding: 25px 60px;
  border-radius: 10px;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-out;
  &.toggletimeseries {
    opacity: 1;
    visibility: visible;
  }
  .timeseriesbtns_cont {
    display: flex;
    justify-content: center;
    margin: 0 0 20px;
    button {
      background: transparent;
      border: none;
      margin-right: 15px;
      svg path {
        fill: #fff;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .timesersbuletsarrs_cont {
    bottom: 40px;
    position: absolute;
    &.timesersbuletsarrs_Prev {
      left: 41px;
    }
    &.timesersbuletsarrs_Next {
      right: 41px;
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 25px;
    padding: 0 0 0;
    background: #454545;
    height: 2px;
    border-radius: 8px;
    li {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .timeseries_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        .timeseries_bullets {
          width: 8px;
          height: 8px;
          background: #7e7e7e;
          border-radius: 50%;
          justify-content: center;
          display: flex;
          top: 7px;
          left: 40%;
          position: relative;
        }
        .timeseries_Year {
          position: relative;
          top: 15px;
          font-size: 12px;
          font-weight: 500;
          color: #7e7e7e;
        }
      }
      &.active_timeseries {
        .timeseries_btn {
          .timeseries_bullets {
            background: #50b041;
          }
          .timeseries_Year {
            color: #50b041;
          }
        }
      }
      &:first-child {
        margin-left: 12px;
      }
      &:last-child {
        margin-right: 12px;
      }
    }
  }
}
.prolevelimages_cont {
  .treedetimg {
    button.control-arrow.control-prev,
    button.control-arrow.control-next {
      display: none !important;
    }
    .slider-wrapper .slider {
      li {
        img {
          height: calc(100vh - 280px);
          width: auto;
          border-radius: 8px;
        }
      }
    }
    .thumbs-wrapper {
      margin: 15px 0;
      text-align: center;
      .thumbs {
        margin: 0;
        padding: 0;
        .thumb {
          width: 77px !important;
          height: 77px !important;
          margin-right: 6px !important;
          padding: 0;
          &:hover,
          &.selected {
            border: none !important;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }
    p.carousel-status {
      display: none;
    }
  }
}
.prolevelimages_list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(100% / 5 - 20px);
    margin: 0 25px 25px 0;
    img {
      height: 80px;
      width: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
    &:nth-child(5n + 5) {
      margin-right: 0;
    }
  }
}
.nav-open {
  .sidebar_cont {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #d9d8d8;
      border-radius: 10px 10px 6px 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #50b041;
      border-radius: 10px 10px 6px 6px;
    }
    .sidebarinner_cont {
      width: 310px;
      .sidebar_menu {
        li {
          &.submenu_main {
            height: auto;
            .submenumain_menu {
              span,
              .submenu_downarrow {
                visibility: visible;
                opacity: 1;
              }
            }
            .sidebar_submenu {
              margin: 0 0 25px 85px;
            }
          }
        }
      }
    }
  }
}
.addproject_modal .addproject_form li {
  label[for="selectlocation"] {
    order: 1;
    margin-bottom: 20px;
  }
  > p {
    font-size: 14px;
    font-weight: 500;
  }
}
/***************Uploaded Image Table css*******************/
.uploadedimgtbl_cont {
  display: flex;
  overflow-y: auto;
  margin: 20px 0;
  table {
    width: 100%;
    thead {
      background: #50b041;
      position: sticky;
      top: 0;
      tr {
        th {
          color: #fff;
          &:first-child {
            border-radius: 6px 0 0 6px;
          }
          &:last-child {
            border-radius: 0 6px 6px 0;
          }
        }
      }
    }
    tr {
      td,
      th {
        font-size: 12px;
        padding: 10px 5px;
        word-break: keep-all;
      }
      td {
        img {
          width: 35px;
        }
      }
    }
    tbody {
      min-height: 135px;
      tr {
        td {
          svg {
            width: 16px;
            height: 16px;
          }
          &:last-child {
            text-align: center;
            a {
              &:first-child {
                margin-right: 5px;
              }
            }
          }
        }
      }
      &:empty {
        min-height: 0;
      }
    }
  }
}
.addproject_modal {
  z-index: 500 !important;
  &.edittreedet_modal {
    .addproject_form {
      li {
        &.inventory_cont {
          > label {
            font-size: 11px !important;
            padding: 0;
            margin-bottom: 5px;
          }
          .treedetmodal_uploadmsg {
            margin: 10px 0;
            font-weight: 600;
          }
          a.treedetmodal_addbtn {
            display: flex;
            height: 31px;
            align-items: center;
            background: #50b041;
            border-radius: 4px !important;
            color: #fff;
            padding: 0 10px;
            font-size: 13px;
            font-weight: 500;
            justify-content: center;
            &:empty {
              height: 0;
            }
          }
        }
      }
    }
  }
  &.timeseriesform_modal {
    .pf-c-modal-box__body {
      .uploadedimgtbl_cont {
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #d9d8d8;
          border-radius: 10px 10px 6px 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: #50b041;
          border-radius: 10px 10px 6px 6px;
        }
      }
    }
    .addproject_form {
      li {
        &.projectidfield {
          .css-2b097c-container {
            font-size: 12px;
            border-bottom: 1px solid #ddd;
            .css-yk16xz-control,
            .css-1pahdxg-control {
              border: none;
              background: transparent;
              min-height: 0;
              font-family: $font-calc;
              div {
                &:first-child {
                  padding: 0;
                  .css-1uccc91-singleValue {
                    font-size: 11px;
                  }
                  .css-1wa3eu0-placeholder {
                    font-size: 11px;
                    color: #212529;
                    margin: 0;
                  }
                  .css-b8ldur-Input {
                    padding: 0;
                    margin: 0;
                  }
                }
                &:nth-child(2) {
                  .css-1okebmr-indicatorSeparator {
                    display: none;
                  }
                  .css-tlfecz-indicatorContainer,
                  .css-1gtu0rj-indicatorContainer {
                    padding: 0;
                    color: #000;
                    svg {
                      height: 15px;
                      width: 15px;
                    }
                  }
                }
              }
            }
            .css-1pahdxg-control {
              box-shadow: none;
              border-radius: 0;
              .css-2613qy-menu {
                padding: 0;
                div,
                li,
                span,
                filter,
                option {
                  font-size: 12px;
                }
              }
            }
            div {
              &:nth-child(3) {
                z-index: 2;
              }
            }
          }
        }
        &.inventory_cont {
          .uploadedimgtbl_cont {
            height: 90px;
            &.legthuploadedimgtbl_cont {
              height: 200px;
            }
          }
        }
        div {
          &.mapboundsfield_cont {
            .mapbound_infowindow {
              width: 190px;
              left: 92px;
              top: -95px;
              .mapinfowidw_clsbtn {
                top: -2px;
                right: 0;
              }
              > div {
                font-size: 12px;
                font-weight: 300;
                line-height: 18px;
              }
            }
            .mandatory.has-error {
              font-size: 11px;
            }
          }
        }
      }
    }
    a.treedetmodal_addbtn {
      display: inline-flex;
      height: 31px;
      align-items: center;
      background: #50b041;
      border-radius: 4px !important;
      color: #fff;
      padding: 0 10px;
      font-size: 13px;
      font-weight: 500;
      justify-content: center;
      &:empty {
        height: 0;
      }
    }
  }
  &.addpointofcontact_modal {
    .pf-c-modal-box__body {
      .uploadedimgtbl_cont {
        height: 130px;
        &.nodataupldimgtbl_cont {
          height: auto;
        }
      }
    }
  }
  &.addtreespeies_modal {
    .addproject_form {
      li {
        label {
          .mandatory {
            font-size: 10px;
          }
        }
        &.speciestyoe_frmgp {
          .radiobtns_wrap {
            justify-content: space-between;
            .radio__input {
              display: flex;
              .radio__label {
                margin-left: 5px;
                line-height: 26px;
              }
            }
          }
        }
      }
    }
  }
  &.instsponsor_modal {
    .addproject_form {
      li {
        &.bottomhr {
          padding: 20px 0 0;
          border-top: 2px dashed #50b041;
          .forms_subheading {
            font-size: 14px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
.prosumcordtabscont_wrap {
  &.partnershipsmasterdata_cont {
    margin-top: 80px;
    .tab-buttons {
      flex-direction: row;
      background: #f0f0f0;
      button {
        width: calc(100% / 7);
        line-height: 18px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        text-align: center;
        &.active {
          background: #fff;
          border-top: 4px solid #50b041;
          border-left: none;
        }
      }
    }
    .tabs-content {
      .headbottom_cont {
        margin: 0;
        .headbottom_content {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}
/*Firefox-specific styles here */
@-moz-document url-prefix() {
  .addproject_form {
    li {
      .radiobtns_wrap .radio__input .custom-radio input {
        width: 15px;
      }
    }
  }
}
.layersloader_cont {
  height: 100%;
  .spinner-loader {
    height: 100%;
    flex-direction: column;
    .loading_txt {
      margin: 10px 0 0;
    }
  }
}
/*****************DeleteConfirmationPopup.css********************/
.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  margin: 0 !important;
  .delete-popup-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    h5 {
      font-size: px-to-rem(18);
      margin: 0 0 20px;
    }
    button {
      margin: 0 10px;
      padding: 5px 15px;
      border: none;
      color: white;
      border-radius: 3px;
      cursor: pointer;
      &.deleteYes_btn {
        background: #50b041;
        color: #fff;
      }
      &.deleteno_btn {
        background: transparent;
        border: 1px solid #50b041;
        color: #50b041;
      }
    }
  }
}
