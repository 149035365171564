@import url("https://fonts.cdnfonts.com/css/euclid-circular-b");
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-1: "Euclid Circular B", sans-serif;
$font-2: "Playfair Display", serif;
$font-calc: "Chivo", sans-serif;
$font-opensans: "Open Sans", sans-serif;
$font-poppins: "Poppins", sans-serif;
@import "mixins";

/*general styling*/
* {
  margin: 0;
  padding: 0;
}

a {
  color: #333;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
input {
  &:focus {
    box-shadow: none !important;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
html {
  scroll-behavior: smooth;
}
body {
  box-sizing: border-box;
  font-family: $font-1 !important;
  background-color: #f7f9fb !important;
  overflow-x: hidden;
  font-size: px-to-rem(16);
  @include media-max(xl) {
    font-size: px-to-rem(14);
  }
}
body {
  #root {
    height: 100%;
    background: transparent;
    > div {
      height: 100%;
      background: transparent;
      > div {
        background: transparent;
      }
      .layout {
        height: 100%;
        > div {
          height: 100%;
          > div {
            height: 100%;
            &.pagenotfound_wrap {
              background-image: url("../assets/contactus_bg.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .pagentfundcont {
                height: auto;
                h1 {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
ul {
  list-style: none;
}
/**********************colors mixins**************/
$clr-calc: #feb224;
$clr-white: #fff;
$clr-btngrn: #50b041;
$clr-alive: #50b041;
/*media query mixin*/

@font-face {
  font-family: "EuclidCircularBRegular";
  src: url("/assets/fonts/EuclidCircularBRegular.eot");
  src: url("/assets/fonts/EuclidCircularBRegular.eot")
      format("embedded-opentype"),
    url("/assets/fonts/EuclidCircularBRegular.woff2") format("woff2"),
    url("/assets/fonts/EuclidCircularBRegular.woff") format("woff"),
    url("/assets/fonts/EuclidCircularBRegular.ttf") format("truetype"),
    url("/assets/fonts/EuclidCircularBRegular.svg#EuclidCircularBRegular")
      format("svg");
}

$fonteuclid: "EuclidCircularBRegular";

/*icomoon font css*/
@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?tiasa1");
  src: url("/assets/fonts/icomoon.eot?tiasa1#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?tiasa1") format("truetype"),
    url("/assets/fonts/icomoon.woff?tiasa1") format("woff"),
    url("/assets/fonts/icomoon.svg?tiasa1#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-left-arrow:before {
  content: "\e90c";
}
.icon-treeicon .path1:before {
  content: "\e90e";
  color: rgb(255, 255, 255);
}
.icon-treeicon .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path4:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path5:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path6:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path7:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path8:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-treeicon .path9:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--3 .path1:before {
  content: "\e917";
  color: rgb(255, 255, 255);
}
.icon-Web-1366--3 .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--3 .path3:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--5 .path1:before {
  content: "\e91b";
  color: rgb(255, 255, 255);
}
.icon-Web-1366--5 .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--5 .path3:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--5 .path4:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Web-1366--5 .path5:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-calendar:before {
  content: "\e90a";
}
.icon-verified:before {
  content: "\e90b";
}
.icon-grid:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-location-pin:before {
  content: "\e904";
}
.icon-document:before {
  content: "\e905";
}
.icon-menu-of-three-lines:before {
  content: "\e906";
}
.icon-photo:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e902";
}
.icon-tree:before {
  content: "\e903";
}
.icon-2:before {
  content: "\e900";
  color: #fff;
}
.icon-iconfinder_Info_372922:before {
  content: "\e901";
  color: #fff;
}

/*icomoon font css*/
/*mixin css*/

@function px-to-rem($size: 16) {
  @return $size/16 + rem;
}

@mixin section_heading {
  display: flex;
  color: #000;
  font-size: 24px;
  font-weight: 700;
}
.section_heading {
  @include section_heading();
}
.section_heading1 {
  display: flex;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  color: #6b9057;
  margin-bottom: 15px;
}
.commontbl_cont1 {
  margin-bottom: 15px;
  width: 100%;
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-width: 1px;
    text-align: center;
    padding: 0 5px;
  }
  td {
    word-break: break-all;
  }
  th {
    color: #6b9057;
    text-transform: capitalize;
  }
}
/*mixin css*/
// animation loader
@keyframes line-scale {
  0%,
  100% {
    ransform: scaleY(1);
  }
  50% {
    transform: scaleY(0.4);
  }
}
.animsition-loading {
  background-color: $clr-white;
  z-index: 10104 !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  text-align: center;
  .loader {
    position: absolute !important;
    top: 50%;
    margin-top: -16px;
    margin-left: -16px;
    border-radius: 50%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0 !important;
    right: 0;
  }
  .line-scale > div {
    width: 4px;
    height: 35px;
    border-radius: 0px;
    margin: 2px;
    display: inline-block;
    background: #393939;
    animation-fill-mode: both;
    animation-name: line-scale;
    animation-timing-function: cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-iteration-count: infinite;
    animation-duration: 1s;
    &:nth-child(1) {
      animation-delay: -0.4s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.2s;
    }
    &:nth-child(4) {
      animation-delay: -0.1s;
    }
    &:nth-child(5) {
      animation-delay: 0s;
    }
  }
}
.viewall_btn {
  display: flex;
  font-size: 13px;
  font-weight: 600;
  color: #333;
  transition: 0.3s;
  &:hover {
    color: #61c6f1;
  }
}
/*common css*/
.filterlist {
  display: flex;
  padding: 0;
  margin: 0;
  li {
    margin: 0 4px;
    a {
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 20px;
      color: #444 !important;
      font-weight: 600;
      border: 1px solid rgba(33, 69, 27, 0.27);
      border-radius: 4px;
      height: 38px;
    }
    &:hover {
      a {
        border: 1px solid #50b041;
        background: #fff;
      }
    }
    &.active {
      a {
        border: 1px solid #50b041;
        background: #50b041;
        color: #fff !important;
      }
    }
  }
}
.notification_list {
  padding: 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  li {
    margin: 0 0 20px;
    font-size: 12px;
    color: #000;
    font-weight: 500;
    .ntfntime {
      color: #b3b3b3;
      margin-left: 5px;
    }
  }
}
a.gobackbtn {
  margin-right: 10px;
  i {
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }
}
/*common css*/
.container {
  max-width: 1532px !important;
}
/*login, register, change, reset password pages css*/
.login_container {
  display: flex;
  height: 100vh;
  margin: 106px 0 0 66px;
  @include media-max(md) {
    flex-direction: column;
    height: 100%;
  }
  & > div {
    height: 100%;
    &.loginleft_cont {
      background: transparent linear-gradient(326deg, #118d45 0%, #95c13d 100%)
        0% 0% no-repeat padding-box;
      width: 45%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
      height: 100%;
      @include media-max(md) {
        width: 100%;
        padding: 20px 0;
      }
      .loginform_cont {
        display: flex;
        width: calc(100% - 35%);
        flex-wrap: wrap;
        justify-content: center;
        align-content: space-around;
        height: 100%;
        @include media-max(xs) {
          width: calc(100% - 20%);
        }
        .growlogocont {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
          @include media-max(xl) {
            margin-top: 10px;
          }
          .growlogo {
            img {
              width: 100%;
            }
          }
        }
        .loginfrm_wrap {
          width: 100%;
          .section_heading {
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            color: $clr-white;
            font-size: 36px;
            font-weight: 600;
            line-height: 55px;
          }
          .login_form {
            margin: 0;
            width: 100%;
            padding: 0;
            li {
              width: 100%;
              margin-bottom: 20px;
              position: relative;
              label {
                color: #d9ff8d;
              }
              input {
                width: 100%;
                border: none;
                border-bottom: 1px solid #ddd;
                background: transparent;
                color: $clr-white;
                padding: 0 15px;
                font-weight: 400;
              }
              .forget_btn {
                position: absolute;
                right: 0;
                bottom: 0;
                background: none;
                border: none;
                padding: 0;
                font-size: 13px;
                font-weight: 100;
                color: $clr-white;
                font-weight: 300;
              }
              .backbtn {
                background: none;
                margin: -22px 0 0 !important;
                color: #fff;
                position: absolute !important;
                img {
                  width: 12px;
                }
                span {
                  font-size: 12px;
                  margin-left: 5px;
                }
              }
              .signin_btn {
                margin: 40px 0 0;
              }
              .signin_btn,
              .register_btn {
                background: $clr-white;
                color: #333;
                width: 100%;
                border: none;
                font-size: 16px;
                font-weight: 600;
                line-height: 18px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
              }
            }
          }
        }
        .footertext {
          color: $clr-white;
          font-size: 13px;
          text-align: center;
          font-weight: 300;
        }
      }
    }
    &.loginright_cont {
      width: 55%;
      overflow: hidden;
      position: relative;
      @include media-max(md) {
        display: none;
      }
      .loginpge_backbtn {
        position: absolute;
        top: 50px;
        left: 50%;
      }
      > img {
        width: 100%;
        height: 100%;
      }
      .logrghtimgtxt {
        position: absolute;
        bottom: 20px;
        left: auto;
        width: 100%;
        text-align: center;
        color: $clr-white;
        font-size: 24px;
        line-height: 24px;
        font-weight: 300;
        font-family: $font-2;
      }
    }
  }
}
/*Search page css*/
.search_cont {
  padding: 0 10px 0 20px;
  display: flex;
  align-items: center;
  a {
    order: 1;
    i {
      color: #b5b5b5;
      &.fas.fa-times {
        display: none;
      }
    }
  }
  .searchbox_cont {
    display: flex;
    align-items: center;
    margin-right: 20px;
    transition: 0.3s;
    position: relative;
    input {
      width: 100%;
      border: 1px solid #ddd;
      border-radius: 50px;
      height: 35px;
      padding: 0 50px 0 20px;
      &:focus {
        outline: none;
      }
    }
    button {
      position: absolute;
      right: 20px;
      top: 12px;
      border: none;
      background: no-repeat;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #b5b5b5;
        line-height: 10px;
      }
      span {
        font-size: 0;
      }
    }
  }
}
.menu-open {
  .headbottom_cont {
    ul li {
      &.search_container {
        .search_cont {
          a i {
            &.fa-search {
              display: none;
            }
            &.fas.fa-times {
              display: flex;
            }
          }
        }
      }
    }
  }
}
/*Sidebar css*/
.sidebar_cont {
  top: 59px;
  height: 100%;
  position: fixed;
  background: #f7f9fb;
  z-index: 99;
  padding-bottom: 40px;
  @include media-max(md) {
    overflow: hidden;
  }
  .sidebarinner_cont {
    width: 50px;
    transition: width 0.3s ease-out;
    @include media-max(md) {
      width: 0;
    }
    .sidebar_menu {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      li {
        display: flex;
        position: relative;
        width: 100%;
        height: 60px;
        vertical-align: middle;
        align-items: center;
        // justify-content: center;
        // border-bottom: 1px solid #656565;
        // a {
        //   display: flex;
        //   position: relative;
        //   color: #999999;
        //   overflow: hidden;
        //   width: 100%;
        //   cursor: pointer;
        //   width: 48px;
        //   height: 48px;
        //   border-radius: 50%;
        //   margin: auto;
        //   font-size: 14px;
        //   padding: 8px;
        //   // height: 100%;
        //   align-items: center;
        //   // justify-content: center;
        //   text-decoration: none;
        //   transition: 0.3s;
        //   i {
        //     font-size: 22px;
        //     text-align: center;
        //     // min-width: 40px;
        //     display: inline-block;
        //     margin: auto;
        //     transition: 0.3s;
        //   }
        //   &::before {
        //     display: none;
        //     position: absolute;
        //     content: "";
        //     top: 0;
        //     left: 0;
        //     height: 100%;
        //     background: $clr-alive;
        //     width: 2px;
        //     opacity: 0;
        //     visibility: hidden;
        //     transition: 0.3s;
        //   }
        //   span {
        //     opacity: 0;
        //     position: relative;
        //     white-space: pre-wrap;
        //     transition: left 0.3s ease-in-out;
        //   }
        //   &[aria-current="page"] {
        //     background-color: #fff;
        //     border-radius: 50%;
        //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        //     i {
        //       color: #8eba76;
        //     }
        //   }
        //   &:hover {
        //     background-color: #fff;
        //     border-radius: 50%;
        //     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        //     i {
        //       color: #8eba76;
        //     }
        //   }
        // }
        // &:hover a {
        //   // background: #262626;
        //   &::before {
        //     visibility: visible;
        //     opacity: 1;
        //   }
        // }
        &.Grow.Alarm {
          display: none !important;
        }
      }
    }
    // &:hover {width: 200px;
    //   .sidebar_menu li a span {
    //     opacity: 1;
    //   }
    // }
    .footersitelogo {
      position: sticky;
      bottom: 10px;
      left: 0;
      z-index: 999;
      margin-top: calc(100vh - 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
      }
    }
  }
}

.nav-open {
  .sidebar_cont {
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transform: translate3d(0px, 0, 0);
    .sidebarinner_cont {
      width: 200px;
      .sidebar_menu {
        li {
          a {
            justify-content: flex-start;
            span {
              opacity: 1;
              text-indent: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/*header css*/
.top_header {
  padding: 0;
  height: 60px;
  flex-wrap: nowrap;
  // background: transparent linear-gradient(123deg, #95C13D 0%, #118D45 100%) 0% 0% no-repeat padding-box;
  background-color: #8eba76;
  display: flex;
  .nav-icon {
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #8c8c8c;
    height: 100%;
    i {
      font-size: 25px;
      color: $clr-white;
      font-weight: 500;
    }
  }
  .navbar_right {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    > li {
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;
      > a {
        display: flex;
        cursor: pointer;
      }
      &.logo_cont {
        margin-left: 15px;
        .growlogo {
          img {
            &.growlogo_img {
              width: 70px;
            }
            &.treetieslogo_img {
              width: 120px;
            }
          }
        }
        @include media-max(xs) {
          display: none;
        }
      }
      &.main_heading {
        margin: 0 auto 0 15px;
        color: $clr-white;
        display: flex;
        align-items: center;
        span {
          font-size: 26px;
          font-weight: 600;
          @include media-max(md) {
            font-size: 18px;
          }
        }
        button {
          i {
            font-size: 14px;
          }
        }
        a {
          color: #f68d20;
          @include media-max(md) {
            margin-left: 10px;
          }
        }
      }
      &.notification {
        position: relative;
        margin: 0 70px;
        @include media-max(sm) {
          margin: 0 auto;
        }
        i {
          font-size: 18px;
        }
        &::before {
          position: absolute;
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 50px;
          background: #61c6f1;
          right: -10px;
          top: 0px;
        }
        &::after {
          background: #4e4e4e;
          height: 20px;
          right: -30px;
          top: 0;
          position: absolute;
          width: 1px;
          content: "";
        }
        ul.submenu.notification_list {
          width: 350px;
          margin-top: 15px;
        }
      }

      &.user_cont {
        > a {
          display: contents;
        }
        .profiledata {
          display: flex;
          flex-direction: column;
          margin-right: 20px;
          justify-content: center;
          span {
            &.profile_name {
              font-size: 14px;
              color: $clr-white;
              display: flex;
              text-transform: uppercase;
              line-height: 14px;
              width: 100%;
              font-weight: 600;
              justify-content: flex-end;
              i {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;
              }
            }
            &.profile_company {
              font-size: 12px;
              color: $clr-white;
              text-align: right;
              line-height: 12px;
            }
            @include media-max(xs) {
              white-space: nowrap;
            }
          }
        }
        img {
          width: 48px;
        }
      }
      &.mobilelogocont {
        display: none;
        .mobilegrowlogo img {
          width: 60px;
        }
        @include media-max(xs) {
          display: flex;
          margin: 0 10px;
        }
      }
      > a {
        color: #333;
      }
      .submenu {
        visibility: hidden;
        position: absolute;
        top: 100%;
        opacity: 0;
        background: $clr-white;
        right: -99999px !important;
        width: 150px;
        transition: all 150ms linear;
        z-index: 999;
        border: 1px solid #ddd;
        box-shadow: 0 0 5px #949494;
        border-radius: 5px;
        padding: 10px 0;
        transform: translate3d(0, -20px, 0) !important;
        gap: unset;
        li {
          padding: 0 10px 5px;
          width: 100%;
          a {
            color: #333;
            display: flex;
            padding: 0.4rem 1rem;
            font-size: 14px;
            font-weight: 400;
            &:hover {
              background: rgba(222, 222, 222, 0.3);
              text-decoration: none;
            }
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
      &:hover .submenu {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 1px, 0) !important;
        right: 15px !important;
        top: 98%;
      }
    }
  }
}
.addpro_btn {
  button,
  a {
    background: #50b041 !important;
    color: #fff !important;
    height: 38px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    font-family: $font-poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.8px;
    i {
      margin-right: 10px;
      order: -1;
    }
    svg {
      margin-right: 10px;
    }
  }
}
.headbottom_cont {
  display: flex;
  background-color: #f7f9fb;
  margin: 0 0 0 66px;
  height: 55px;
  ul {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    align-items: center;
    margin: 0;
    &.headbottom_content {
      width: 92%;
      li {
        &.heading_cont {
          font-family: $font-poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          margin-right: 10px;
        }
        &.newfilters_cont {
          .filter_dropdown {
            button {
              border: none;
              width: 36px;
              height: 36px;
              background: #50b041;
              border-radius: 4px 4px 0 0;
              &::after {
                display: none;
              }
            }
            .filterdropdown_list {
              position: absolute;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              width: 220px;
              background: #fff;
              height: auto;
              padding: 20px;
              border: 1px solid #50b041;
              border-radius: 0px 4px 4px 4px;
              li {
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid #ebebeb;
                &:first-child {
                  padding-top: 0;
                }
                &:last-child {
                  border-bottom: none;
                  padding-bottom: 0;
                }
                a {
                  font-weight: 500;
                  display: flex;
                  justify-content: space-between;
                }
                &.active {
                  a {
                    color: #50b041;
                  }
                }
              }
            }
          }
        }
        &.addprobtn_container {
          margin-left: auto;
        }
      }
      &.fieldofficertop_cont {
        width: 98%;
        .addfieldofficer_btn {
          margin-left: auto;
        }
      }
    }
    &.viewbtn_cont {
      width: 9%;
      padding: 0;
      margin-left: 1%;
      @media (max-width: 1289px) {
        width: 10%;
      }
      @media (min-width: 1920px) {
        width: 8%;
      }
      li {
        background: transparent;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: flex;
        }
        &.active {
          background: #50b041;
          color: #fff;
          border-radius: 4px;
        }
        &:first-child {
          &.active {
            a {
              svg path {
                stroke: #fff;
              }
            }
          }
        }
        &:not(:first-child) {
          &.active {
            a {
              svg path {
                fill: #fff;
              }
            }
          }
        }
        &.mapreseticon_cont {
          display: none;
        }
      }
    }
    li {
      &.updatetxt_cont {
        font-size: 13px;
        color: #999;
        line-height: 20px;
        padding-left: 20px;
        margin-right: auto;
        i {
          margin-left: 5px;
        }
      }
      &.timeperiod_cont {
        display: flex;
        background: #f0f0f0;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 13px;
        align-items: center;
        justify-content: center;
        color: #777;
        position: relative;
        i {
          margin-left: 5px;
        }
        .rdrCalendarWrapper {
          position: absolute;
          top: 40px;
          right: 0;
          box-shadow: 0 0 5px #ccc;
          border-radius: 4px;
        }
      }
      &.export_btn {
        display: flex;
        button {
          background: transparent;
          color: $clr-btngrn;
          font-size: 14px;
          line-height: 27px;
          font-weight: 600;
          margin-left: 15px;
          padding-left: 15px;
          border-left: 1px solid #ddd;
          border-radius: 0;
          height: 30px;
          align-items: center;
          justify-content: center;
          display: flex;
          @media (max-width: 1246px) {
            border-left: none;
          }
          i {
            margin-left: 5px;
          }
        }
      }
      &.downloadreprt_btn,
      &.export_btn {
        button {
          @media (max-width: 1246px) {
            margin: 10px 0;
          }
        }
      }
    }
  }
  &.treespeslisthead_cont {
    .headbottom_content {
      width: 99%;
      li {
        &.heading_cont {
          margin-right: auto;
        }
      }
    }
  }
}
/*header css*/
/*footer css*/
.footer_cont {
  background: #f7f9fb;
  margin: 0 0 0 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #c1c0c0;
  position: relative;
  @include media-max(md) {
    margin: 0;
  }
  // .footersitelogo {
  //   position: absolute;
  //   left: -50px;
  //   z-index: 99;
  //   img {
  //     width: 40px;
  //   }
  // }
  .footer_content {
    color: #333;
    font-size: 11px;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    > a {
      color: #0076d7 !important;
      font-weight: 600;
      position: absolute;
      right: 20px;
    }
  }
}
/*footer css*/
.main-panel {
  margin: 50px 0 0;
  height: 100%;
  width: 100%;
  &.powerbidashboard_wrap {
    > div {
      .powebidashboard_cont {
        padding-top: 20px;
        .newdashboard_heading {
          font-size: px-to-rem(24);
          color: $clr-black;
          margin: 0 0 10px;
        }
        iframe {
          width: 100%;
          height: 100vh;
        }
      }
    }
  }
  > div {
    > div {
      margin: 40px 30px 40px 80px;

      &.pagenotfound_wrap {
        height: calc(100vh - 90px);
      }
      &.projects_container {
        margin: 0 30px 0 80px;
        height: 100%;
        &.loadingprojects_cont {
          height: 100vh;
        }
        &.mapviewactive_container {
          margin: 0 0px 0 50px !important;
          .viewmap {
            margin: 0 !important;
            .mapClass > div {
              margin: 0;
            }
          }
        }
      }
      &.co2emicalcwrap_cont {
        height: 100%;
      }
      @include media-max(md) {
        margin: 20px 10px 20px 10px;
      }
      &.headbottom_cont {
        margin: 0 0 0 50px;
        position: sticky;
        top: 50px;
        height: auto;
        padding: 25px 0 10px;
        width: calc(100% - 65px);
        z-index: 3;
        @include media-min(xl) {
          top: 60px;
          padding: 25px 0 20px;
        }
        @include media-max(md) {
          margin: 0;
        }
        @media (max-width: 1246px) {
          height: auto;
        }
        &.mapviewactive_container {
          position: absolute;
          top: 50px;
          background: transparent;
          ul.headbottom_content {
            li.heading_cont {
              color: #fff;
            }
          }
          ul.viewbtn_cont {
            width: 16%;
            @media (max-width: 1289px) {
              width: 18%;
            }
            @media (min-width: 1920px) {
              width: 12%;
            }
            li {
              background: #e8eef3;
              margin-right: 10px;
              border-radius: 4px;
              &.active {
                background: #50b041;
              }
              &.mapreseticon_cont {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  .ngodashboard_cont,
  .growmanhome_cont,
  .growalarm_container,
  .co2emicalcwrap_cont,
  .globalsetting_container {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 0;
  }
  .ngodashboard_cont,
  .growmanhome_cont,
  .growalarm_container,
  .globalsetting_container {
    padding: 20px;
  }
  .co2emicalcwrap_cont {
    padding: 0 37px;
  }
  .co2emicalcwrap_cont {
    margin-top: 90px !important;
  }
}
.card_box {
  background: $clr-white;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 4px;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-basis: 100%;
  max-width: 100%;
  height: 100%;
  font-size: 24px;
  .cardboxinner_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 30px 20px;
    .section_heading {
      padding: 0;
      font-size: 16px;
    }
    .card_time {
      font-size: 13px;
      padding: 0 10px;
      color: #ccc;
      i {
        margin-left: 5px;
      }
    }
    .card_dropdown {
      select {
        font-size: 13px;
        border: none;
        padding: 0;
        width: 130px;
        line-height: 25px;
        appearance: auto;
        -webkit-appearance: auto;
        &:focus {
          box-shadow: none;
        }
        option {
          font-size: 12px;
        }
      }
    }
  }
  .donutwrap {
    height: 200px;
    width: calc(100%);
  }
  .cardboxmap_cont {
    padding: 0 30px;
  }
  .toptreeheroes_list {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      flex-direction: row;
      width: calc(100% / 2 - 10px);
      margin: 0 5px 20px;
      .toptreeheroes_left {
        display: flex;
        width: 40px;
        font-size: 14px;
        background: #ebebeb;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 50px;
        align-items: center;
        justify-content: center;
      }
      .toptreeheroes_right {
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        span {
          &.hrs_treedonate {
            font-size: 11px;
            color: #000;
          }
          &.hrs_name {
            font-weight: 600;
            font-size: 13px;
          }
          &.hrs_location {
            font-size: 12px;
            color: #bfbfbf;
          }
        }
      }
    }
  }
  &.cdboxnotify_cont {
    height: auto;
    .viewall_btn {
      display: flex;
      font-size: 13px;
      color: #61c6f1;
    }
  }
  &.planned_cont .cardboxinner_wrap {
    flex-wrap: nowrap;
  }
}
.myProfile {
  margin-top: 100px !important;
  margin-bottom: 0px !important;
  h3 {
    color: #444444 !important;
  }
}
.profileNBox {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  margin-top: 10px !important;
  padding-bottom: 30px;
  .profileSection {
    display: flex;
    align-items: center;
    height: 100px;
    border-bottom: 1px solid #e0e0e0;
    #profileImage {
      font-family: Arial, Helvetica, sans-serif;
      width: 100px;
      height: 100px;
      border-top-right-radius: 25%;
      border-bottom-left-radius: 25%;
      background: #e0e0e0;
      font-size: 2rem;
      color: #333333;
      text-align: center;
      line-height: 100px;
      margin: 0;
    }
    ul {
      margin: 0px;
      padding: 0px;
      padding-left: 30px;
      h6 {
        margin: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: #444444;
        text-transform: capitalize;
      }
      p {
        margin: 0px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #444444;
        span {
          color: #e9ce43;
        }
      }
    }
  }
  .Infocontainer {
    display: flex;
    align-items: center;
    height: 100px;
    border-bottom: 1px solid #e0e0e0;
    margin-left: 40px;
    button {
      outline: none;
      border: none;
      height: 30px;
      padding: 5px 20px;
      background: #f7f9fb;
      border-radius: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      text-align: center;
      color: #444444;
      margin-right: 15px;
      &.active {
        background-color: #8eba76;
        color: #fff;
      }
    }
  }
  .sectionAllData {
    margin-left: 40px;
    position: relative;
    .generalData {
      padding-top: 30px;
    }
    .section_data {
      &:first-child {
        padding: 30px 0px 0px 0px;
      }
      h3 {
        margin: 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #444444;
        padding-bottom: 20px;
      }
      ul {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        li {
          width: calc(100% / 4 - 20px);
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-self: baseline;
          p {
            margin: 0px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #333333;
            &:first-child {
              font-weight: 600;
              color: #222222;
            }
            &.mobile,
            &.email {
              color: #0076d7;
            }
          }
        }
      }
      &.profothrdet_cont ul li {
        width: calc(100% / 2 - 20px);
      }
    }
    &.documentSectionData {
      .section_data {
        padding-top: 30px;
      }
      ul {
        li {
          width: calc(100% / 2 - 20px);
        }
      }
    }
    .editDocument {
      display: none;
      position: absolute;
      right: 20px;
      top: 10px;
      height: 28px;
      width: 28px;
      background: #e3e8eb;
      text-align: center;
      border-radius: 50%;
      i {
        font-size: 14px;
        line-height: 28px;
        color: #85949b;
      }
    }
  }
}
.noactendusers_cont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px;
  > div {
    flex-basis: 50%;
    max-width: 50%;
    &.noactendusers_right {
      display: flex;
      flex-direction: column;
      word-break: break-all;
      option {
        font-size: 14px;
        margin: 0 0 20px;
        white-space: normal;
      }
    }
  }
}
.rowmarginbot {
  margin-bottom: 40px;
}

.layout > div .login_container {
  margin: 0;
}
.layout div .layout_cont div.login_container {
  margin: 106px 0 0 66px;
}
/*sorting arrow css*/
.sortArrow {
  position: relative;
  margin: 0 0 0 7px;
  height: 16px;
  width: 15px;
  display: inline-flex;
  i {
    cursor: pointer;
    color: #a2a2a2;
    position: absolute;
    font-size: 12px;
    width: 12px;
    transition: 0.3s;
    &:hover {
      color: $clr-white;
    }
    &.active {
      color: $clr-white;
    }
    &.fas.fa-sort-up {
      height: 8px;
      top: 2px;
      margin: 0;
      line-height: 16px;
    }
    &.fas.fa-sort-down {
      height: 2px;
      bottom: 2px;
      margin: 0;
      line-height: 0;
    }
  }
}
/*sorting arrow css*/
.growalarm_content {
  .commontblfltr_wrap {
    .showtxtnbtns_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .showingtxt {
        margin: 15px 0;
        font-size: 16px;
        line-height: 31px;
        font-weight: 700;
        display: flex;
        .projdettime {
          color: #9f9f9f;
          font-size: 14px;
          font-weight: 400;
          margin: 0 10px;
        }
        .healthunhealthybtnswrap {
          margin-left: 15px;
          button {
            padding: 5px 10px;
            background-color: #ebebeb;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            line-height: 20px;
            color: #222;
            font-weight: 600;
            transition: 0.3s;
            &.healthy {
              margin-right: 15px;
            }
            &:hover {
              background-color: #61c6f1;
              color: $clr-white;
            }
          }
        }
      }
      .showbtn_cont {
        display: flex;
        align-items: center;
        a {
          display: flex;
          margin: 0 10px;
          button {
            background-color: $clr-btngrn;
            font-size: 14px;
          }
          &.adddetbtn {
            background: $clr-btngrn;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 20px;
            color: $clr-white;
            font-size: 14px;
            border-radius: 4px;
          }
          &.lineicon {
            i {
              font-size: 24px;
              color: #bfbfbf;
            }
            &.active i {
              color: #61c6f1;
            }
          }
          &.pinicon {
            background: $clr-white;
            display: inline-flex;
            padding: 2px;
            border: 1px solid #aaaaaa;
            i {
              font-size: 20px;
              color: #bfbfbf;
            }
            &.active {
              border-color: #61c6f1;
              i {
                color: #61c6f1;
              }
            }
          }
        }
      }
      &.paymentlistcont_wrap {
        justify-content: flex-start;
        .showingtxt {
          &.totalReport {
            margin: 15px 50px 15px 0;
            white-space: nowrap;
            .react-datepicker-wrapper {
              margin-left: 10px;
              input {
                padding-left: 10px;
              }
            }
            .react-datepicker-popper {
              z-index: 10;
            }
          }
        }
        label {
          white-space: nowrap;
        }
      }
    }
  }
  .viewmap .mapMainDiv .mapViewer {
    flex-basis: 100%;
    max-width: 100%;
    .satmapcont {
      .cesium-viewer .cesium-viewer-toolbar {
        display: none;
      }
    }
  }
  .commontbl_wrap {
    width: 100%;
    height: calc(100vh - 260px);
    overflow-x: auto;
    .commontbl_cont {
      width: 100%;
      thead {
        background: #333333;
        position: sticky;
        top: 0;
        z-index: 1;
        th {
          height: 36px;
          color: #cccccc;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          white-space: nowrap;
          padding: 0 10px;
          position: relative;
          &.status,
          &.action_cont {
            text-align: center;
          }
          &.idth {
            width: 5%;
          }
          .grpName {
            display: flex;
            flex-direction: column;
            padding: 5px 0;
            select {
              border: none;
              outline: none;
              margin: 3px 0 0 -4px;
              width: 100%;
            }
          }
          &.action {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          background: $clr-white;
          transition: 0.3s;
          td {
            height: 60px;
            padding: 0 10px;
            font-size: 13px;
            line-height: 18px;
            border-bottom: 1px solid #ddd;
            position: relative;
            &.datetd {
              white-space: nowrap;
            }
            &.idtd {
              width: 5%;
              text-align: left;
            }
            &.statusone,
            &.statustwo {
              span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: $clr-white;
                font-size: 13px;
                font-weight: 400;
                padding: 0 15px;
                white-space: nowrap;
              }
            }
            &.statusone {
              span {
                border-radius: 5px;
              }
              &.healthy span {
                background: $clr-alive;
              }
              &.unhealthy span {
                background: #b54e56;
              }
            }
            &.statustwo {
              span {
                border-radius: 20px;
              }
              &.resolved span {
                background: $clr-btngrn;
              }
              &.inprogress span {
                background: #d9b000;
              }
              &.unassigned span {
                background: #778186;
              }
            }
            &.statusone,
            &.statustwo {
              width: 10%;
              text-align: center;
              line-height: 22px;
            }
            &.action_cont {
              text-align: center;
              vertical-align: middle;
              align-items: center;
              a {
                margin: 0 8px;
                i {
                  font-size: 16px;
                }
              }
              &.mediatd_cont {
                a {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  &:not(:first-child) {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
          &:hover {
            background: #f2fbff;
          }
          &.tblsearch_cont {
            background: #f0fbff !important;
            border: 1px solid #d6e1e5;
            .tblsearch {
              display: flex;
              align-items: center;
              i {
                font-style: italic;
                margin-right: 10px;
              }
              input {
                width: 100%;
                border: none;
                font-size: 14px;
                line-height: 25px;
                color: #222;
                font-weight: 300;
                background: transparent;
                &:focus {
                  outline: none;
                }
                &::-webkit-input-placeholder {
                  color: #222 !important;
                }
              }
            }
          }
          &.unhealthytr {
            td {
              &:first-child {
                &::before {
                  background: #b54e56;
                  width: 3px;
                  height: 100%;
                  position: absolute;
                  content: "";
                  top: 0px;
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
/*home page css*/
.homepage_container {
  .card_box {
    .section_heading {
      padding: 20px;
    }
  }
}
/*ngodashboard page css*/
.ngodashboard_cont {
  .container {
    .row {
      &.row-1,
      &.row-2,
      &.row-3 {
        > div {
          &:not(:last-child) {
            @include media-max(md) {
              margin-bottom: 20px;
            }
          }
        }
      }
      &.row-4 {
        .col-lg-8 {
          .container {
            padding: 0;
          }
          .row {
            > div {
              margin-bottom: 20px;
              &.col-lg-6 {
                .card_box {
                  &.cdboxnotify_cont {
                    .cardboxinner_wrap {
                      padding: 0;
                    }
                  }
                }
                .section_heading {
                  padding: 20px;
                  font-size: 16px;
                }
              }
            }
          }
        }
        .col-lg-4 {
          .card_box {
            &.homemap_cont {
              height: auto;
            }
            .section_heading {
              padding: 20px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
/*floating label*/
.field {
  display: flex;
  flex-flow: column-reverse;
  input,
  label {
    transition: all 0.05s;
    touch-action: manipulation;
  }
  label {
    font-size: 12px;
  }
  input {
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid #ccc;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
    height: 30px;
    line-height: 30px;
    &:focus {
      outline: 0;
    }
    &::-moz-placeholder {
      opacity: 0;
      transition: inherit;
    }
    &:focus::-moz-placeholder {
      opacity: 1;
    }
    &::-webkit-input-placeholder {
      opacity: 0;
      transition: inherit;
    }
    &:focus::-webkit-input-placeholder {
      opacity: 1;
    }
  }
  input:placeholder-shown + label {
    cursor: text;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 1.125rem) scale(1);
  }
  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    color: $clr-btngrn;
    margin-bottom: 3px;
  }
}

/*registration page css*/
.registartion_container {
  background: #f7f9fb;
  padding-bottom: 10px;
  .registation_head {
    padding: 5px 20px;
    background: transparent linear-gradient(326deg, #118d45 0%, #95c13d 100%) 0%
      0% no-repeat padding-box;
    border-bottom: 1px solid #ddd;
    display: flex;
    .growlogo {
      margin-right: 20px;
    }
    .section_heading {
      color: $clr-white;
      display: flex;
      align-items: center;
    }
  }
  .registartion_cont {
    background: $clr-white;
    margin: 20px 40px;
    padding: 20px;
    box-shadow: 0 0 10px #d0cece;
    .registartioninner_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .section_heading {
        font-size: 18px;
      }
      .mandatory_txt {
        font-size: 12px;
        color: #626565;
      }
    }
    .register_form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0;
      width: 100%;
      padding: 0;
      li {
        width: 48%;
        margin: 10px 1% 0;
        position: relative;
        @include media-max(md) {
          width: 98%;
        }
        input {
          border-bottom: 1px solid #ddd !important;
          border: none;
          width: 90%;
          padding: 0 10px;
          &:focus {
            outline: none;
          }
          &::-webkit-input-placeholder {
            color: #222 !important;
          }
          @include media-max(md) {
            width: 100%;
          }
        }
        &.emailreg_cont,
        &.mobilereg_cont {
          margin-top: 0 !important;
        }
        &.incorporation_cont {
          label {
            font-size: 14px !important;
            padding: 10px 0;
          }
          .radiobtns_wrap {
            flex-wrap: nowrap;
            align-items: center;
            @media (max-width: 460px) {
              flex-direction: column;
            }
            .radio__input {
              display: flex;
              background: #ebebeb;
              height: 35px;
              line-height: 35px;
              // min-width: 100px;
              margin: 0 5px;
              justify-content: center;
              align-items: center;
              padding: 0 10px;
              @media (max-width: 460px) {
                margin: 0 0 10px;
              }
              .custom-radio {
                display: flex;
                width: 20px;
                input {
                  width: 13px;
                  height: 20px;
                  line-height: 20px;
                  border: 1px solid #ddd;
                  border-radius: 50px;
                }
              }
              label {
                font-size: 13px !important;
                width: 100%;
                padding: 0 0 0 5px;
              }
            }
          }
          .othersinputcont {
            display: flex;
            align-items: center;
            position: relative;
            .has-error {
              position: absolute;
              left: 0;
              top: -35px;
            }
            .form-group {
              margin-top: -10px;
              input {
                height: 25px;
                line-height: 25px;
              }
              label {
                padding: 0 !important;
                font-size: 12px !important;
              }
            }
          }
        }

        &.website_cont {
          line-height: 85px;
        }
        &.submitbtn_wrap {
          width: 100%;
          margin-top: 30px;
          button {
            min-width: 100px;
            height: 50px;
            line-height: 50px;
            border-radius: 5px;
            &.submit_btn {
              background: #50b041;
              border: 1px solid #50b041;
              color: $clr-white;
              margin-right: 10px;
            }
            &.cancel_btn {
              background: transparent;
              border: 1px solid #ddd;
            }
          }
        }
        &.contactdet_cont {
          width: 100% !important;
          margin: 15px 1% 0;
          .section_heading {
            font-size: 18px;
          }
        }
        &.inventory_cont {
          label {
            font-size: 14px !important;
            padding: 10px 0;
          }
          .divUploadFile {
            display: flex;
            width: 90%;
            .qq-upload-drop-area {
              display: none;
            }
            .qq-upload-button {
              display: flex;
              flex-direction: column;
              height: 60px;
              justify-content: center;
              align-items: center;
              background: #f4f4f4;
              border: 1px dashed #707070;
              color: #333333;
              font-weight: 500;
              width: 100%;
              font-size: 14px;
              span {
                color: #333;
                font-size: 11px;
                width: 100%;
                text-align: center;
                font-weight: 400;
              }
            }
            input {
              position: absolute;
              opacity: 0;
              height: 60px !important;
            }
          }
          .ul-project-files {
            padding: 0;
            margin: 10px 0;
            .file-list-li {
              font-size: 13px;
              i {
                color: $clr-btngrn;
                margin-right: 5px;
                font-size: 14px;
              }
              .remove-files-list {
                color: #61c6f1;
                padding: 0 15px;
                font-size: 13px;
                font-weight: 300;
              }
            }
          }
        }
        &.regsucess {
          width: 98%;
        }
      }
    }
  }
}
.msgsucess {
  color: $clr-btngrn;
}
/*registration page css*/
/*co2 emission calculator page css*/
.co2emicalc_container {
  background: transparent;
  padding-bottom: 0;
  height: 100%;
  .registartion_cont {
    margin: 0;
    box-shadow: none;
    padding: 0 0 20px;
    height: 100%;
    .register_form {
      margin: 0 auto;
      height: 100%;
      @media (max-width: 820px) and (min-width: 767px) {
        width: 94%;
      }
      // @media(max-width: 1288px) {width: 42%;}
      // @media(max-width: 1233px) {width: 43%;}
      // @media(max-width: 1200px) {width: 45%;}
      // @media(max-width: 1155px) {width: 55%;}
      // @include media-max(md) {width: 60%;}
      // @include media-max(sm) {width: 94%;}
      .halfwidth_cont {
        display: flex;
        width: 50%;
        flex-direction: column;
        @media (max-width: 991px) {
          width: 100%;
        }
        &.calculationinfo_cont {
          padding-left: 30px;
          @media (max-width: 991px) {
            padding-left: 0;
          }
        }
      }
      li {
        margin: 0 auto;
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .calcradiowrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto !important;
          position: absolute !important;
          top: 15px;
          left: 15px;
          input {
            width: 15px !important;
            height: 15px !important;
          }
        }
        .has-error {
          position: absolute;
          left: 0;
          font-size: 11px;
          bottom: -15px;
        }
        > label {
          width: 100%;
          text-transform: uppercase;
          font-weight: 700;
          color: #555;
          font-family: $font-calc;
          margin: 0 25px 0;
          @media (max-width: 420px) {
            font-size: 14px;
          }
        }
        > div {
          position: relative;
          label {
            order: 1;
            font-family: $font-calc;
          }
          input {
            background: transparent;
            line-height: 1.5;
            height: auto;
            font-size: 14px;
            padding: 0;
            font-family: $font-calc;
            &#MakeModelDistance {
              width: 80%;
            }
          }
          select {
            border-bottom: 1px solid #ddd !important;
            border: none;
            width: 100%;
            padding: 0;
            font-size: 14px;
            -webkit-appearance: auto;
            background: transparent;
            border-radius: 0;
            font-family: $font-calc;
            &:focus {
              box-shadow: none;
            }
          }
          .calcmeasur {
            font-size: 12px;
            font-family: $font-calc;
          }
          &.searchmakecont {
            .basic-single.css-2b097c-container {
              border-bottom: 1px solid #ddd;
              .css-yk16xz-control,
              .css-1pahdxg-control {
                border: none;
                background: transparent;
                min-height: 0;
                font-family: $font-calc;
                div {
                  &:first-child {
                    padding: 0;
                    .css-1uccc91-singleValue {
                      font-size: 14px;
                    }
                    .css-1wa3eu0-placeholder {
                      font-size: 14px;
                      color: #212529;
                      margin: 0;
                    }
                    .css-b8ldur-Input {
                      padding: 0;
                      margin: 0;
                    }
                  }
                  &:nth-child(2) {
                    .css-1okebmr-indicatorSeparator {
                      display: none;
                    }
                    .css-tlfecz-indicatorContainer,
                    .css-1gtu0rj-indicatorContainer {
                      padding: 0;
                      color: #000;
                      svg {
                        height: 15px;
                        width: 15px;
                      }
                    }
                  }
                }
              }
              .css-1pahdxg-control {
                box-shadow: none;
                border-radius: 0;
                .css-2613qy-menu {
                  padding: 0;
                  div,
                  li,
                  span,
                  filter,
                  option {
                    font-size: 14px;
                    font-family: $font-calc;
                  }
                }
              }
            }
          }
        }
        &.calcbtnerror {
          .has-error {
            bottom: -20px;
          }
        }
        &.paybtnerror {
          > div {
            width: calc(100% / 3 - 10px);
            margin: 0 10px 15px 0;
            position: relative;
            label {
              .mandatory {
                color: red;
                font-size: 10px;
              }
            }
            > div {
              width: 100%;
              input {
                width: 100%;
              }
            }
            @media (max-width: 480px) {
              width: calc(100% / 1 - 10px);
            }
            &:last-child {
              margin-right: 0;
            }
            &.Divcountryphonecode {
              .countrycodeinnerwrap {
                display: flex;
                position: relative;
                width: 100%;
                &::before {
                  position: absolute;
                  content: "";
                  bottom: 9px;
                  left: 46px;
                  border-right: 2px solid #222;
                  border-bottom: 2px solid #222;
                  width: 7px;
                  height: 7px;
                  transform: translate(-50%, -50%) rotate(45deg);
                  z-index: 0;
                }
                input#countryphonecode {
                  cursor: pointer;
                  width: 55px;
                  padding: 0 5px;
                  z-index: 1;
                }
                i.fa.fa-times {
                  position: absolute;
                  right: 0;
                  font-size: 12px;
                  border: 1px solid #222;
                  padding: 2px;
                  border-radius: 50px;
                  height: 18px;
                  width: 18px;
                  justify-content: center;
                  align-items: center;
                  top: 0;
                }
                .countrycode_list {
                  border: 1px solid #ddd;
                  flex-wrap: wrap;
                  height: 130px;
                  padding: 0;
                  overflow-y: scroll;
                  overflow-x: hidden;
                  position: absolute;
                  z-index: 9;
                  top: 24px;
                  width: 167px;
                  background: #fff;
                  left: 0;
                  li {
                    padding: 5px;
                    border-bottom: 1px solid #ddd;
                    font-size: 13px;
                    &.searchcountrycont {
                      position: sticky;
                      top: 0;
                      background: #fff;
                      z-index: 9;
                      input {
                        height: 23px;
                        margin-bottom: 5px;
                        border-bottom: none !important;
                        opacity: 1 !important;
                        &::-moz-placeholder {
                          opacity: 1 !important;
                        }
                        &::-webkit-input-placeholder {
                          opacity: 1 !important;
                        }
                      }
                    }
                  }
                }
              }

              &.show {
                .countrycode_list,
                i.fa.fa-times {
                  display: flex;
                }
              }
              &.hide {
                .countrycode_list,
                i.fa.fa-times {
                  display: none;
                }
              }
            }
          }
        }
        &.panaddfieldscont {
          > div {
            &.panfield {
              width: 33%;
              @media (max-width: 480px) {
                width: 98%;
                margin-bottom: 15px;
              }
              > div {
                width: 100%;
                input {
                  width: 96%;
                  @media (max-width: 480px) {
                    width: 100%;
                  }
                }
              }
            }
            &.addressfield {
              width: 65%;
              @media (max-width: 480px) {
                width: 98%;
              }
              input {
                width: 100%;
              }
            }
          }
        }
        &.seletngovalid {
          .has-error {
            bottom: 0;
          }
        }
        &.calcco2heading {
          .section_heading {
            width: 100%;
            justify-content: center;
            text-transform: uppercase;
            flex-direction: column;
            align-items: center;
            margin: 10px 0;
            font-family: $font-calc;
            @media (max-width: 420px) {
              font-size: 20px;
            }
            img {
              width: auto;
              height: auto;
              margin-top: 7px;
            }
          }
        }
        &.vehicaletyprcalc {
          .drivingmode {
            justify-content: space-evenly;
          }
          > label {
            display: flex;
            flex-direction: row;
            justify-content: initial;
            width: 100%;
            margin-bottom: 10px;
            select {
              width: 35%;
              border-bottom: 1px solid #ddd !important;
              border: none;
              padding: 0;
              font-size: 14px;
              -webkit-appearance: auto;
              border-radius: 0;
              background: transparent;
              margin-left: 10px;
              &:focus {
                box-shadow: none;
              }
            }
          }
          // .searchmakecont label {position: relative;top: 6px;}
          .Modal_Series_Number {
            position: absolute;
            width: 100%;
            display: inline-block;
            bottom: -28px;
            height: auto;
            line-height: 14px;
            span {
              font-size: 10px;
              width: 100%;
              display: inline-block;
              line-height: 1px;
            }
          }
          > div {
            > div {
              &:nth-last-child(-n + 3) {
                margin: 0 10px 0 0;
              }
            }
          }
        }
        &.vehicaletyprcalc,
        &.journeycont {
          .fields_wrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            > div {
              width: calc(100% / 3 - 10px);
              margin: 0 10px 15px 0;
              position: relative;
              @media (max-width: 480px) {
                width: calc(100% / 1 - 10px);
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .nodata_error {
            bottom: -10px;
            position: relative;
            width: 100%;
            text-align: center;
          }
        }
        &.othercont {
          > div {
            width: calc(100% / 2 - 10px);
            margin: 0 10px 15px 0;
            position: relative;
            div input {
              width: 100%;
            }
            @media (max-width: 480px) {
              width: calc(100% / 1 - 10px);
            }
            &:last-child {
              margin-right: 0;
            }
            &:nth-last-child(-n + 2) {
              margin: 0 10px 0 0;
            }
          }
        }
        &.journeycont {
          > div {
            > div {
              &:nth-last-child(-n + 2) {
                margin: 0 10px 0 0;
              }
              div {
                display: flex;
              }
            }
          }
        }
        &.selectngodiv {
          margin-top: 20px;
          > label {
            display: none;
          }
          .searchmakecont {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            @media (max-width: 480px) {
              flex-direction: column;
              align-items: flex-start;
            }
            label {
              order: 0;
              white-space: nowrap;
              margin-right: 30px;
            }
            .basic-single {
              width: 100%;
              .css-2613qy-menu {
                top: auto !important;
                bottom: 0 !important;
              }
            }
          }
        }
        &.distancecont {
          label {
            font-size: 12px;
            text-transform: inherit;
            font-weight: inherit;
          }
        }
        &.calcco2bgcolor {
          background: #f7f9fb;
          padding: 15px 15px 20px 15px;
        }
        &.orcalccont {
          background: transparent;
          display: flex;
          position: relative;
          padding: 2px 0;
          justify-content: center;
          &::before {
            background: #ddd;
            height: 1px;
            top: 50%;
            left: 0;
            width: 100%;
            position: absolute;
            content: "";
            z-index: 0;
          }
          span {
            text-align: center;
            font-size: 14px;
            font-weight: 800;
            text-transform: uppercase;
            background: $clr-white;
            padding: 0 15px;
            display: inline-block;
            z-index: 1;
          }
        }
        &.calccondetcont {
          display: flex;
          justify-content: space-between;
          flex-wrap: inherit;
          font-family: "Chivo", sans-serif;
          @media (max-width: 480px) {
            flex-wrap: wrap;
          }
          h3 {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            margin-right: 5px;
          }
          p {
            font-size: 12px;
            margin-bottom: 5px;
            color: #686868;
          }
        }
        &.calcfoottextwrap {
          margin: 10px 0;
          .calcfoottext {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            font-weight: 500;
            font-size: px-to-rem(14);
            font-family: $font-calc;
            margin-bottom: 15px;
            @media (max-width: 480px) {
              font-size: 14px;
            }
            span {
              width: calc(100% - 75%);
              text-align: right;
              @media (max-width: 420px) {
                width: 35%;
                margin-left: auto;
                display: inline-flex;
                justify-content: flex-end;
              }
            }
            &:first-child {
              margin-bottom: 10px;
            }
          }
        }
        &.calco2ftprintbtn {
          flex-direction: row;
          margin: auto 0 0;
          .calcresetbtn {
            width: 20%;
            margin-right: 10px;
            @media (max-width: 1288px) {
              margin-right: 9px;
            }
            @media (max-width: 480px) {
              width: 100%;
              order: 1;
              margin-right: 0;
            }
          }
          .calcsubmitbtn {
            width: 78%;
            @media (max-width: 480px) {
              width: 100%;
              margin-bottom: 10px;
            }
          }
          button {
            background: $clr-white;
            border: 1px solid $clr-calc;
            color: $clr-calc;
          }
        }
        &.calcco2paynowbtn {
          margin: 10px 0 0;
          > div {
            width: 100%;
            button {
              background: $clr-calc;
              border: 1px solid $clr-calc;
              color: $clr-white;
            }
          }
        }
        &.calco2ftprintbtn,
        &.calcco2paynowbtn {
          button {
            width: 100%;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            height: 50px;
            line-height: 50px;
            font-family: $font-calc;
            @media (max-width: 420px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  /*co2 emission calculator page css for AO site*/
  &.co2calcforao_cont {
    height: 100%;
    .registartion_cont {
      height: auto;
      background: #fff;
      .register_form {
        height: auto;
        .calcao_heading {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          padding: 10px 0;
          > div {
            &.calcaohead_left {
              font-family: $font-poppins;
              h1 {
                font-size: px-to-rem(30);
                font-weight: 600;
                color: #434343;
                margin-bottom: 5px;
              }
              p {
                font-size: px-to-rem(14);
                color: #686868;
              }
            }
            &.calcaohead_right {
              margin-left: auto;
              @media (max-width: 767px) {
                margin: auto;
                margin-bottom: 15px;
              }
              img {
                &:last-child {
                  margin-left: 20px;
                }
              }
            }
          }
        }
        .halfwidth_cont {
          background: #fff;
          width: 49%;
          border-radius: 10px;
          box-shadow: 0 1px 5px 0 #d7d7d7;
          padding: 5px 30px 20px;
          min-height: 400px;
          @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 25px;
            padding: 5px 15px 20px;
          }
          &.calculationinfo_cont {
            margin-left: 2%;
            @media (max-width: 991px) {
              margin-left: 0%;
            }
            font-family: $font-poppins;
            .calcfoottextwrap {
              .calcfoottext {
                justify-content: flex-start;
                font-family: $font-poppins;
                font-weight: 400;
                margin-bottom: 5px;
                color: #393939;
                span {
                  margin-left: auto;
                  font-weight: 600;
                }
              }
            }
            .carbofsetforveh_field {
              display: flex;
              width: 100%;
              flex-direction: row;
              label {
                font-family: $font-poppins;
                font-size: px-to-rem(14);
                font-weight: 400;
                margin: 0 auto 5px 0;
                order: 0;
              }
              div select {
                border-bottom: none !important;
                option {
                  border-radius: 4px;
                  border: 1px solid #ffb224 !important;
                  background: #fffcf5;
                  color: #333;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  padding: 0 20px;
                }
              }
            }
            .calcaodonatefield_cont {
              padding-bottom: 10px;
              border-bottom: 1px solid #d6d6d6;
            }
            .calccondetcont {
              font-family: $font-poppins;
              flex-direction: column;
              h3 {
                font-size: px-to-rem(22);
              }
              p {
                font-size: px-to-rem(12);
              }
            }
            .paybtnerror {
              > div {
                width: calc(100% / 2 - 10px);
                margin: 0 10px 15px 0;
                @media (max-width: 767px) {
                  width: 100%;
                  margin-bottom: 10px;
                }
                &.Divcountryphonecode {
                  > div {
                    display: flex;
                    select {
                      width: 40%;
                      margin-right: 4%;
                    }
                    > div {
                      width: 100%;
                    }
                  }
                }
                &.Divcountryphonecode {
                  > div {
                    display: flex;
                    select {
                      width: 40%;
                      margin-right: 4%;
                    }
                    > div {
                      width: 100%;
                    }
                  }
                }
                label {
                  font-family: $font-poppins;
                  font-size: px-to-rem(14);
                  font-weight: 400;
                  margin: 5px auto 0 0;
                  order: 1;
                  .mandatory {
                    color: #ff2f2f;
                    font-family: $font-poppins;
                    font-size: px-to-rem(16);
                    font-weight: 500;
                    top: -3px;
                  }
                }
                input {
                  background: transparent;
                  line-height: 1.5;
                  height: auto;
                  font-size: px-to-rem(14);
                  padding: 0;
                  font-family: $font-poppins;
                  color: #393939;
                  font-weight: 500;
                  border-bottom: 1px solid #a7a7a7;
                  &::placeholder {
                    opacity: 1;
                  }
                }
                .has-error {
                  bottom: -18px;
                  font-size: 0.75rem;
                }
              }
              &.pymntgloberr_cont {
                justify-content: center;
                .has-error {
                  padding: 10px 0 0;
                  text-align: center;
                  margin: 0;
                  font-size: 0.75rem;
                }
              }
            }
            .panaddfieldscont {
              > div {
                width: 100%;
                label {
                  font-family: $font-poppins;
                  font-size: px-to-rem(14);
                  font-weight: 400;
                  margin: 0 auto 5px 0;
                  order: 1;
                }
                input {
                  font-family: $font-poppins;
                  font-weight: 500;
                  font-size: px-to-rem(14);
                  &::placeholder {
                    opacity: 1;
                  }
                }
              }
            }
            .calcco2paynowbtn {
              > div {
                button {
                  width: 52%;
                  background-color: #50b041 !important;
                  border-color: #50b041 !important;
                  color: #fff !important;
                  font-size: 16px;
                  border-radius: 6px;
                  font-weight: 600;
                  font-family: "poppins";
                  text-transform: capitalize;
                  @media (max-width: 767px) {
                    order: 1;
                    width: 100%;
                  }
                  @media (max-width: 300px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
          .calcaotab_wrap {
            display: flex;
            border-bottom: 1px solid #d9d9d9;
            button {
              background: none;
              position: relative;
              border: none;
              padding: 10px 20px;
              font-family: "Poppins";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              color: #676767;
              &.activecalcaotab_btn {
                color: #333;
                &::after {
                  background: #ffb224;
                  position: absolute;
                  content: "";
                  bottom: -1px;
                  left: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 100px;
                }
              }
              @media (max-width: 767px) {
                padding: 10px 15px;
                font-size: 16px;
              }

              @media (max-width: 350px) {
                padding: 10px 14px;
                font-size: 15px;
              }
              @media (max-width: 310px) {
                padding: 10px 10px;
                font-size: 14px;
              }
            }
          }
          .calcaotab_content {
            margin: 15px 0 0;
            > div {
              p {
                color: #686868;
                font-family: $font-poppins;
                font-size: px-to-rem(13);
                font-style: normal;
                font-weight: 400;
                margin-bottom: 10px;
              }
              .nodata_error {
                margin-bottom: 10px;
                margin-top: 0;
                font-size: px-to-rem(12);
                font-family: $font-poppins;
              }
            }
            .fields_wrap {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: baseline;
              > div {
                width: calc(100% / 2 - 10px);
                margin: 0 10px 10px 0;
                position: relative;
                label {
                  order: 1;
                  font-family: $font-poppins;
                  font-size: px-to-rem(14);
                  font-weight: 400;
                  .mandatory {
                    color: #ff2f2f;
                    font-family: $font-poppins;
                    font-size: px-to-rem(16);
                    font-weight: 500;
                    top: -3px;
                  }
                }
                input {
                  background: transparent;
                  line-height: 1.5;
                  height: auto;
                  width: 100%;
                  font-size: px-to-rem(14);
                  padding: 0;
                  font-family: $font-poppins;
                  color: #393939;
                  font-weight: 500;
                  border-bottom: 1px solid #a7a7a7;
                }
                select {
                  border-bottom: 1px solid #a7a7a7 !important;
                  border: none;
                  width: 100%;
                  padding: 0 0 0 5px;
                  font-size: px-to-rem(14);
                  background: transparent;
                  border-radius: 0;
                  font-family: $font-poppins;
                  font-weight: 500;
                  color: #393939;
                  -moz-appearance: none;
                  -webkit-appearance: none;
                  appearance: none;
                  background-image: url("../assets/downarrow_aocalc.png");
                  background-repeat: no-repeat, repeat;
                  background-position: right 0.2em top 50%, 0 0;
                  background-size: 0.65em auto, 100%;
                  &:focus {
                    box-shadow: none;
                  }
                  option {
                    padding: 0 5px;
                  }
                }
                @media (max-width: 480px) {
                  width: calc(100% / 1 - 10px);
                }
                &:last-child {
                  margin-right: 0;
                }
                &.searchmakecont {
                  .has-error {
                    order: -1;
                  }
                }
                .has-error {
                  margin-top: 2px;
                  font-size: px-to-rem(12);
                  font-family: $font-poppins;
                }
              }
            }
            .calcao_journeycont {
              .fields_wrap > div {
                > div {
                  display: flex;
                  flex-direction: column;
                }
                .has-error {
                  order: -1;
                }
              }
            }
            .calcao_othercont {
              .fields_wrap > div {
                .has-error {
                  order: -1;
                }
              }
            }
          }
          .calco2ftprintbtn {
            .calcresetbtn {
              border-radius: 6px;
              border: 1px solid #ffb224;
              background: #fffcf5;
              font-family: $font-poppins;
              font-size: px-to-rem(16);
              font-weight: 600;
              color: #50b041 !important;
              text-transform: capitalize;
              padding: 0 22px;
              width: auto;
              @media (max-width: 767px) {
                order: 1;
                width: 100%;
              }
              @media (max-width: 300x) {
                font-size: 14px;
              }
            }
            .calcsubmitbtn {
              width: 52%;
              background: #50b041;
              border-color: #50b041 !important;
              color: #fff !important;
              font-size: 16px;
              border-radius: 6px;
              font-weight: 600;
              font-family: "poppins";
              text-transform: capitalize;
              @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
              }

              @media (max-width: 300px) {
                font-size: 14px;
              }
            }
          }
        }
        .calcaofooter_copyright {
          display: flex;
          justify-content: center;
          width: 100%;
          margin: 30px 0 0;
          p {
            font-family: "poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: #686868;
            margin: 0;
            img {
              width: 20%;
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
}
/*co2 emission calculator page css*/

/*project listing and detail page common css*/
.prolistinner_list {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  align-items: center;
  .prolist_tree {
    min-width: 60px;
    height: 60px;
    border-radius: 6px;
    background: #eef8ec;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    img {
      width: auto;
    }
    img.img-tree {
      height: 30px;
      width: 30px;
      background-size: cover;
    }
  }
  .prolist_treedet {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    span {
      &.prolisttreedet_lbl {
        color: #444;
        font-family: $font-poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      }
      &.prolisttreedet_val {
        color: #50b041;
        font-family: $font-poppins;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        &.prolisttreedet_valsource {
          font-size: 13px;
        }
      }
    }
  }
  .prolist_progress {
    display: flex;
    width: 60px;
    margin-left: auto;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.disturbanceImpact {
    .disturbanceTitle {
      color: #333 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .disturbanceValue {
      color: #118d45;
      font-weight: 700;
    }
    .tooltiptext {
      visibility: hidden;
      background-color: #fff;
      color: #000;
      text-align: center;
      padding: 5px 10px;
      border-radius: 4px;
      position: absolute;
      z-index: 1;
      bottom: 45px;
      left: 20%;
      font-size: 12px;
      border: 1px solid #e3e3e3;
    }
    &:hover .tooltiptext {
      visibility: visible;
    }
    img {
      width: 28px;
    }
  }
  &.opportunity {
    position: relative;
    img {
      width: 28px;
    }
    .opportunityScore {
      color: blue !important;
      &.title {
        font-weight: 500 !important;
      }
      &.score {
        font-weight: 700 !important;
      }
    }
    .tooltiptext {
      visibility: hidden;
      background-color: #fff;
      color: #000;
      text-align: center;
      padding: 5px 10px;
      border-radius: 4px;
      position: absolute;
      z-index: 1;
      bottom: 45px;
      left: 20%;
      font-size: 10px;
      border: 1px solid #e3e3e3;
    }
    &:hover .tooltiptext {
      visibility: visible;
    }
    ul.ScoreCard {
      padding: 20px 2px 20px 8px;
      background: #ffffff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      position: absolute;
      z-index: 9;
      top: 100%;
      transform: translateY(-10%);
      width: 112%;
      transition: all 0.3s;
      display: none;
      animation: anim1 0.3s ease-in-out;
      left: -19px;
      h5.cardTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #444444;
        margin-bottom: 10px;
      }
      li {
        box-shadow: none;
        width: 100%;
        padding: 5px 0;
        margin: 0px;
        display: flex;
        justify-content: flex-start;
        span.cardInnerTitle {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: #999999;
          padding-right: 3px;
          width: 42%;
        }
        span.cardScore {
          font-style: normal;
          font-weight: 400 !important;
          font-size: 12px !important;
          line-height: 12px !important;
          color: #444444 !important;
          padding-left: 0px;
          width: 55%;
          display: flex;
          span {
            font-style: normal;
            font-weight: 400 !important;
            font-size: 12px !important;
            line-height: 12px !important;
            color: #444444 !important;
            &.colon {
              padding-right: 2px;
            }
          }
        }
      }
    }
    @keyframes anim1 {
      from {
        transform: translateY(-10%);
      }
      to {
        transform: translateY(0%);
      }
    }
    &:hover ul.ScoreCard {
      display: block;
      transform: translateY(0%);
    }
  }
  &.fundraisedlistiutem_cont {
    .prolist_treedet {
      position: relative;
      .totalfndrsedamt_cont {
        position: absolute;
        color: #444;
        right: 0;
        bottom: 12px;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
      .prolist_progress {
        width: auto;
        margin: 10px 0 0;
        > div {
          width: 100%;
          grid-gap: 0;
          .pf-c-progress__status {
            display: none;
          }
          .pf-c-progress__bar {
            height: 6px;
            &::before {
              background-color: #dcefd9;
              border-radius: 50px;
              opacity: 1;
            }
            .pf-c-progress__indicator {
              height: 6px;
              border-radius: 50px;
              background-color: #50b041;
            }
          }
        }
      }
    }
  }
}
.border-bottom {
  height: 1px;
  width: 100%;
  margin: 0 auto 30px;
  border-bottom: 1px solid #bbf7d4 !important;
}
.prolistverified_btn {
  position: absolute;
  top: 10px;
  right: 20px;
  span {
    color: #c7c7c7;
    font-size: 12px;
    margin-right: 5px;
    text-transform: uppercase;
  }
  i {
    background: $clr-alive;
    padding: 3px;
    border-radius: 4px;
    color: $clr-white;
    font-size: 12px;
  }

  &.prolistunverified_btn {
    i {
      background: #b54e56;
    }
  }
  .icon_txtveriftedhovr {
    bottom: -33px;
  }
  &:hover {
    .icon_txtveriftedhovr {
      visibility: visible;
      opacity: 1;
      &::after {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.prolist_name {
  font-size: 20px;
  color: $clr-btngrn;
  line-height: 35px;
  font-weight: 700;
  // width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.prolisttime_cont {
  display: flex;
  justify-content: space-between;
  width: 100%;
  span {
    font-size: 13px;
    font-weight: 600;
    color: #222;
    i {
      margin-right: 5px;
    }
  }
  &.logilangval {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .polycord {
      width: 100%;
      flex-basis: 100%;
      white-space: nowrap;
      > div {
        display: flex;
        span {
          font-size: 8px;
          line-height: 11px;
          font-weight: 500;
          margin-right: 5px;
        }
      }
    }
  }
}

/*projects listing page csss*/
.projects_list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 15px 4px 20px;
  li {
    display: flex;
    flex-wrap: wrap;
    background: $clr-white;
    width: calc(100% / 4 - 15px);
    margin: 0 15px 20px 0;
    padding: 20px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    position: relative;
    border: 1px solid transparent;
    transition: 0.3s;
    height: fit-content;
    &:nth-child(4n + 4) {
      margin-right: 0;
    }
    @include media-max(md) {
      width: calc(100% / 1 - 30px);
    }
    &:hover {
      border: 1px solid #50b041;
    }
    .projectlistcards_parenttop {
      display: flex;
      flex-wrap: wrap;
    }
    .projectlistcards_topcont {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .projectlistcards_topleft {
        display: flex;
        flex-direction: column;
        width: 75%;
        .prolistcards_pronmenvfycont {
          display: flex;
          align-items: center;
          .project-name .prolist_name {
            color: #444;
            line-height: 24px;
            word-break: break-all;
            white-space: normal;
          }
          .prolistverified_btn {
            position: relative;
            top: auto;
            right: auto;
            margin-left: 10px;
          }
        }
        .prolisttime_cont {
          span {
            color: #444;
            font-family: $font-poppins;
            font-size: 14px;
            font-weight: 500;
            i {
              font-size: 18px;
              color: #50b041;
            }
          }
        }
      }
      .projectlistcards_topright {
        display: flex;
        padding-top: 5px;
        margin-left: 10px;
        a {
          &:only-child {
            margin-right: 0;
          }
          &.ecosysdash_link {
            order: -1;
            margin-right: 10px;
          }
          &.downloadrept_icon {
            .icon_txtveriftedhovr {
              &::after {
                left: auto;
                right: 10px;
              }
            }
          }
          &.downloadrept_icon,
          &.ecosysdash_link {
            position: relative;
            top: auto;
            right: auto;
            height: 24px;
            .icon_txtveriftedhovr {
              bottom: -25px;
              left: auto;
              right: 0;
              white-space: nowrap;
              &::after {
                left: auto;
                right: 10px;
              }
            }
            &:hover {
              .icon_txtveriftedhovr {
                visibility: visible;
                opacity: 1;
                &::after {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .typeofverify_forest {
      border-radius: 50px;
      display: flex;
      height: 30px;
      line-height: 30px;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      margin-top: 10px;
      color: #fff;
      font-family: $font-poppins;
      font-size: px-to-rem(13);
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      @include media-max(xl) {
        font-size: px-to-rem(12);
      }
      &.unhlthyforbtn {
        background: #b54e56;
      }
      &.hlthyforbtn {
        background: #50b041;
      }
      svg {
        margin-left: 5px;
      }
    }
    a {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .prolist_det {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;
    }
    .prolist_no {
      color: #bcbcbc;
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
    }

    .campaign_txt {
      font-size: 14px;
      font-style: italic;
      font-weight: 300;
    }
    table {
      border: 1px solid #ccc;
      margin-top: 15px;
      max-width: 100%;
      th {
        padding: 5px;
        border: 1px solid #ccc;
        color: #fff;
        background-color: #50b041;
        text-align: center;
        font-size: 14px;
      }
      td {
        padding: 5px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  /*projectlisting list view*/
  &.projlistview {
    margin: 20px 0 0;
    li {
      width: calc(100% / 1);
      margin: 0 2px 20px;
      flex-wrap: nowrap;
      .ecosysdash_link {
        top: 55px;
      }
      a {
        .prolistverified_btn {
          top: 25px;
        }
        .prolistinner_list {
          width: calc(100% / 4 - 20px);
          margin: 0 10px 20px;
          &.coemicont {
            justify-content: center;
          }
        }
      }
      .projectlistcards_parenttop {
        width: 240px;
      }
      .projectlistcards_topcont {
        width: auto;
        flex-direction: column;
        .projectlistcards_topleft {
          width: 100%;
        }
        .projectlistcards_topright {
          position: absolute;
          right: 10px;
          flex-direction: column;
          top: 50%;
          transform: translateY(-50%);
          a {
            &.downloadrept_icon,
            &.ecosysdash_link {
              .icon_txtveriftedhovr {
                bottom: auto;
                top: 25px;
                left: auto;
                right: 0;
              }
            }
            &.downloadrept_icon {
              margin-bottom: 15px;
            }
            &.ecosysdash_link {
              order: 0;
            }
          }
        }
      }
    }
  }
}
.projects_container {
  .projectsinner_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .viewbtn_cont {
      display: flex;
      align-items: center;
      margin: 0;
      @include media-max(md) {
        display: none;
      }
      .viewbtnli {
        margin-left: 5px;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          i {
            color: #bfbfbf;
          }
          &.grid_btn {
            i {
              font-size: 20px;
            }
          }
          &.list_btn i {
            font-size: 24px;
          }
        }
        &.active {
          a {
            background: #50b041;
            &.grid_btn {
              svg path {
                stroke: #fff;
              }
            }
            &.list_btn {
              svg path {
                fill: #fff;
              }
            }
            &.mapview_btn {
              svg path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

/*project detail page css*/
.projdet_heading {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  height: 100%;
  width: 83%;
  @media (max-width: 1440px) {
    width: 75%;
  }
  @media (max-width: 1246px) {
    width: 100%;
    padding: 10px 0;
  }
  > div {
    display: flex;
    margin: 0 0 0 20px;
    &:last-child {
      @media (max-width: 1246px) {
        margin-left: auto;
      }
    }
  }
  .prolistverified_btn {
    position: relative;
    top: auto;
    right: auto;
  }
  .prolisttime_cont {
    display: inline-flex;
    width: 36%;
  }
  .prodetprogresscont {
    width: 40px;
  }
  .projdetcont_btn {
    display: flex;
    padding: 0 10px;
    a {
      display: flex;
      border-radius: 4px;
      justify-content: center;
      align-items: center;
      color: $clr-white;
      font-size: 14px;
      height: 34px;
      padding: 0 20px;
      &.unhlthyforbtn {
        background: #b54e56;
      }
      &.hlthyforbtn {
        background: $clr-btngrn;
      }
    }
  }
  @media (max-width: 1246px) {
    &::after {
      background: #ddd;
      height: 1px;
      width: calc(100% + 60px);
      position: absolute;
      bottom: 0;
      left: -20px;
      content: "";
    }
  }
}
.projdetcontlist_wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 80px;
  align-items: center;
  // &::before {
  //   background: #D0D0D0;
  //   width: 100vw;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   height: 1px;
  //   content: "";
  //   margin-left: calc(-50vw + 50%);
  // }
  &::after {
    background: #d0d0d0;
    width: 100vw;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    content: "";
    margin-left: calc(-50vw + 50%);
  }
  > div {
    width: calc(100% / 3 - 30px);
    margin: 0px 15px;
    display: flex;
    align-items: center;
    height: 100%;
    &:nth-child(2) {
      border-left: 1px solid #d0d0d0;
      border-right: 1px solid #d0d0d0;
      padding: 0 20px;
    }
  }
}
.projdetcont_row2 {
  .commontbl_wrap .commontbl_cont {
    thead {
      th {
        width: 10%;
        text-align: left;
        padding: 0 10px;
        white-space: nowrap;
        &.co2th,
        &.growthth {
          text-align: right;
        }
        &.treetypeth {
          width: 20% !important;
        }
        &.locationth {
          width: 20% !important;
        }
        &.statusoneth {
          width: 8% !important;
          text-align: center;
        }
        &.actionth {
          width: 15% !important;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          width: 10%;
          &.idtd {
            text-align: left;
          }
          &.co2td,
          &.growthtd {
            text-align: right;
          }
          &.treetypetd {
            width: 20% !important;
          }
          &.locationtd {
            width: 20% !important;
          }
          &.statusone {
            width: 8% !important;
          }
          &.action_cont {
            width: 15% !important;
            text-align: center;
          }
        }
      }
    }
    &.treedettbl_cont {
      thead {
        th {
          width: 10% !important;
          &.statusoneth {
            width: auto !important;
            text-align: left !important;
          }
        }
      }
      tbody {
        tr td {
          &.treeidtd {
            width: 32%;
            a {
              color: blue;
            }
          }
          &.treetypetd {
            width: auto !important;
          }
        }
      }
    }
  }
}
.projdetcont_row1 {
  margin-top: -40px;
  @include media-max(md) {
    margin-top: -20px;
  }
}
/*react ciruclar progress bar custom css*/
.CircularProgressbar-text {
  font-size: 20px !important;
  font-weight: 600;
}
/*modal popup custom css*/
.addproject_modal {
  button.pf-c-button.pf-m-plain {
    display: none;
  }
  .pf-c-modal-box__title {
    padding: 0 15px;
    margin-bottom: 10px;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-1 !important;
    }
  }
  .pf-c-modal-box__body {
    height: 100%;
    padding: 0 20px !important;
    flex: auto;
    width: 100%;
    .successmsgcont {
      position: absolute;
      bottom: 8px;
      font-size: 12px;
      text-align: center;
      color: #999;
      width: 100%;
      left: 0;
      label {
        width: 70%;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .pf-c-modal-box__footer {
    padding-left: 40px;
    padding-right: 55px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px !important;
    button.modal_btn {
      min-width: 115px;
      height: 40px;
      border-radius: 5px;
      &.submit_btn {
        background: #50b041;
        border: none;
        color: $clr-white;
        margin-right: 10px;
      }
      &.cancel_btn {
        background: transparent;
        border: 1px solid #50b041;
        color: #50b041;
      }
    }
  }
  &.addmeetingnotepop {
    .pf-c-modal-box__header h1 {
      margin-bottom: 20px;
    }
    .addproject_form {
      li {
        label {
          margin-bottom: 5px;
          color: $clr-btngrn;
        }
        textarea {
          border: 1px solid #ddd;
          height: 150px;
          font-size: 14px;
          padding: 10px;
          line-height: 24px;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  &.makeapptment_modal {
    .pf-c-modal-box__header {
      h1 {
        margin-left: 20px;
      }
    }
    .pf-c-modal-box__body {
      .applistpopup_container {
        padding: 0 10px;
        .applistpoptop {
          border-bottom: 1px solid #ddd;
          padding-bottom: 20px;
          margin-bottom: 20px;
          .backtolist {
            position: absolute;
            top: 30px;
            left: 20px;
            font-size: 0;
            i {
              margin-right: 5px;
              font-size: 22px;
              color: #222;
            }
          }
          .page_heading {
            color: #333;
            font-size: 16px;
            font-weight: 800;
            line-height: 40px;
            margin: 20px 0 0;
          }
          .applistpoploc {
            color: #959595;
            font-size: 14px;
            font-weight: 400;
          }
          .applistpopbtn {
            display: none;
            .statustwo {
              span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: $clr-white;
                font-size: 12px;
                font-weight: 400;
                padding: 0 15px;
                white-space: nowrap;
                border-radius: 10px;
                margin: 15px 0 0;
              }
              &.newrequest span {
                background: #d9b000;
              }
            }
          }
        }
        .applistpopup_content {
          padding: 0;
          li {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 0 0 20px;
            .docname {
              a {
                color: $clr-btngrn;
                transition: 0.3s;
                font-size: 14px;
              }
            }
            .meetingnoteslist {
              padding: 0;
              margin-top: 10px;
              li {
                margin: 0 0 5px !important;
                font-size: 14px;
                position: relative;
                padding-left: 10px;
                &::before {
                  content: "*";
                  position: absolute;
                  top: 4px;
                  left: 0;
                  font-size: 12px;
                }
              }
            }
            span {
              color: #333;
              &.applistpoplabel {
                font-size: 12px;
                font-weight: 400;
              }
              &.applistpopinputvalue {
                font-size: 14px;
                font-weight: 500;
                a {
                  color: $clr-btngrn;
                }
              }
            }
            &.docnamefield {
              margin: 0;
            }

            &.upload_projAgremnt {
              label {
                font-size: 14px !important;
                padding: 10px 0;
              }
              .divUploadFile {
                display: flex;
                width: 100%;
                .qq-upload-drop-area {
                  display: none;
                }
                .qq-upload-button {
                  display: flex;
                  flex-direction: column;
                  height: 60px;
                  justify-content: center;
                  align-items: center;
                  background: #f4f4f4;
                  border: 1px dashed #707070;
                  color: #333333;
                  font-weight: 500;
                  width: 100%;
                  font-size: 14px;
                  span {
                    color: #333;
                    font-size: 11px;
                    width: 100%;
                    text-align: center;
                    font-weight: 400;
                  }
                }
                .uploadfldbtn {
                  display: flex;
                  margin: 10px 0 0;
                  width: 100%;
                  justify-content: flex-end;
                  button {
                    background: transparent;
                    border: 1px solid #ddd;
                    color: #767676;
                    min-width: 80px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 5px;
                    font-size: 13px;
                  }
                }
                input {
                  position: absolute;
                  opacity: 0;
                  height: 60px !important;
                }
              }
              .ul-project-files {
                padding: 0;
                margin: 10px 0;
                .file-list-li {
                  font-size: 13px;
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: row;
                  margin: 0;
                  i {
                    color: $clr-btngrn;
                    margin-right: 5px;
                    font-size: 14px;
                  }
                  .remove-files-list {
                    color: #61c6f1;
                    font-size: 13px;
                    font-weight: 300;
                    margin-left: auto;
                  }
                }
              }
            }
          }
          .addinfobox {
            border: 1px solid #ddd;
            padding: 20px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  select.form-control {
    appearance: auto;
    -webkit-appearance: auto;
    margin: 10px 0 0 0;
    + label {
      margin-top: 10px;
    }
  }
  &.fieldofficer_modal {
    .pf-c-modal-box__footer {
      margin-bottom: 0 !important;
    }
  }
}
.projdetailpage_modal {
  max-width: 700px !important;
  header.pf-c-modal-box__header,
  footer.pf-c-modal-box__footer {
    display: none;
  }
  .pf-c-modal-box__body {
    padding: 20px;
    .container {
      .row {
        .col-lg-6 {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
  .treedetails_cont {
    width: 100%;
    .container {
      max-width: 100% !important;
      .treedetails_wrap {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        flex-direction: column;
        .treename {
          font-size: 20px;
          color: #333;
          font-weight: 700;
          margin: 15px 0;
        }
        .treeid {
          color: #999999;
          font-weight: 600;
          font-size: 14px;
        }
        .treedetails_list {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          margin: 0;
          li {
            display: flex;
            margin: 0 0 20px;
            align-items: center;
            width: 100%;
            .treedetlistimg {
              width: 50px;
            }
            .treedetpopright {
              display: flex;
              flex-direction: column;
              span {
                font-size: 16px;
                &:first-child {
                  color: #333;
                  font-weight: 500;
                }
                &.treedetpopvalue {
                  color: $clr-btngrn;
                  font-weight: 700;
                }
              }
            }
          }
          //css for project view popup content design
          &.projectview_cont {
            margin: 0;
            li {
              margin: 0 0 20px;
              .treename {
                font-size: 16px;
                font-weight: 500;
                width: 100%;
                margin: 0;
                img {
                  width: 30%;
                }
                table {
                  width: 100%;
                  border: 1px solid #666;
                  margin: 15px 0 0;
                  tr {
                    th {
                      background: #ebebeb;
                      font-weight: 600;
                    }
                    th,
                    td {
                      padding: 10px;
                      border-bottom: 1px solid #666;
                      border-right: 1px solid #666;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.projectdetactnbtn_modal {
    button.pf-c-button.pf-m-plain {
      right: 0;
      top: 0;
    }
  }
}
.uploadtreedata_modal {
  max-width: 700px !important;
  header.pf-c-modal-box__header,
  footer.pf-c-modal-box__footer {
    display: none;
  }
  .upload_projTreeData {
    label {
      font-size: 14px !important;
      padding: 10px 0;
    }
    .divUploadFile {
      display: flex;
      width: 100%;
      .qq-upload-drop-area {
        display: none;
      }
      .qq-upload-button {
        display: flex;
        flex-direction: column;
        height: 60px;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        border: 1px dashed #707070;
        color: #333333;
        font-weight: 500;
        width: 100%;
        font-size: 14px;
        span {
          color: #333;
          font-size: 11px;
          width: 100%;
          text-align: center;
          font-weight: 400;
        }
      }
      input {
        position: absolute;
        opacity: 0;
        height: 60px !important;
      }
    }
    .ul-project-files {
      padding: 0;
      margin: 10px 0;
      .file-list-li {
        font-size: 13px;
        i {
          color: $clr-btngrn;
          margin-right: 5px;
          font-size: 14px;
        }
        .remove-files-list {
          color: #61c6f1;
          padding: 0 15px;
          font-size: 13px;
          font-weight: 300;
        }
      }
    }
  }
  .treedatarow {
    padding: 5px;
    margin: 15px 0;
    .treeplntdte,
    .treeplntby {
      border-bottom: 1px solid #ddd !important;
      border: none;
      width: 100%;
      padding: 5 10px;
      font-size: 14px;
    }
    input:focus-visible {
      outline: none !important;
    }
  }

  .savetree_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;
    a {
      display: flex;
      margin: 0 10px;
      button {
        background-color: $clr-btngrn;
        font-size: 14px;
      }
      &.savebtn {
        background: $clr-btngrn;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        color: $clr-white;
        font-size: 14px;
        border-radius: 4px;
      }
    }
  }
}
.addproject_modal {
  position: absolute !important;
  top: 50px;
  right: 0;
  height: 100%;
  width: 350px !important;
  .addproject_form {
    padding: 0 20px;
    li {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      label {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #444;
        sup {
          font-size: 10px;
        }
        &[for="age"] {
          line-height: 11px;
        }
      }
      &.typeproject_cont > label {
        color: $clr-btngrn;
      }
      &.treetypeselect {
        label {
          margin-bottom: 10px;
          color: $clr-btngrn;
        }
        select {
          padding: 0;
          appearance: auto;
          -webkit-appearance: auto;
          -moz-appearance: auto;

          &:focus {
            box-shadow: none;
          }
        }
      }
      &.projectidfield {
        label {
          margin-bottom: 5px;
        }
      }
      &.typeproject_cont,
      &.inventory_cont {
        label {
          .mandatory {
            position: absolute;
            top: 40px;
            left: 0;
            z-index: 1;
          }
        }
      }
      input,
      select {
        border-bottom: 1px solid #999 !important;
        border: none;
        width: 100%;
        padding: 0;
        font-weight: 400;
        font-size: 11px;
        line-height: 25px;
        color: #444;
        height: 25px;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        &::-webkit-input-placeholder {
          color: #222 !important;
        }
      }
      input {
        &#age {
          &:disabled {
            background: #e9e9e9;
          }
        }
      }
      select {
        position: relative;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 12px;
        background-origin: content-box;
        background-image: url("../assets/dropdow_arrow.png");
        padding: 0 5px;
      }
      &.profundgoal_cont,
      &.treeprice_cont {
        margin-top: 10px;
        label {
          font-size: 14px !important;
          padding: 10px;
          width: 100%;
          .mandatory {
            top: 15px;
          }
        }
        .checkbox__input {
          background: $clr-white;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          border: 1px solid #ddd;
          input {
            height: auto;
            width: auto;
          }
          label {
            padding: 5px 10px;
          }
        }
      }
      &.typeproject_cont {
        label {
          font-size: 14px !important;
          padding: 10px;
          .mandatory {
            top: 15px;
          }
        }
        .radio__input {
          display: flex;
          background: #ebebeb;
          height: 35px;
          line-height: 35px;
          min-width: 100px;
          margin: 0 5px;
          justify-content: center;
          align-items: center;
          .custom-radio {
            display: flex;
            width: 15px;
            input {
              height: 13px;
              line-height: 13px;
              border: 1px solid #ddd;
              border-radius: 50px;
            }
          }
          label {
            font-size: 13px !important;
            padding: 0 0 0 5px;
            text-transform: uppercase;
            font-weight: 500;
          }
        }
      }
      &.addprosuccessmsg {
        margin: 30px 0;
        // >div {
        //   height: 30px;
        //   line-height: inherit;
        // }
      }
      &.areamapcont {
        margin-top: 20px !important;
      }
      &.location_cont {
        .showmapsymbol {
          position: absolute;
          right: 0;
          font-size: 12px;
          bottom: 5px;
          color: #999;
          i {
            color: #bcbcbc;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      &.inventory_cont {
        > label {
          font-size: 12px;
          padding: 0 7px;
          margin-bottom: 5px;
          .mandatory {
            top: 10px;
          }
        }
        .divUploadFile {
          display: flex;
          .qq-upload-drop-area {
            display: none;
          }
          .qq-upload-button {
            display: flex;
            flex-direction: column;
            height: 120px;
            justify-content: center;
            align-items: center;
            background: #f4f4f4;
            border: 1px dashed #707070;
            color: #333333;
            font-weight: 500;
            width: 100%;
            span {
              color: #999999;
              font-size: 12px;
              width: 63%;
              text-align: center;
              font-weight: 300;
            }
          }
          input {
            position: absolute;
            opacity: 0;
            height: 120px !important;
          }
        }
        .ul-project-files {
          padding: 0;
          margin: 10px 0;
          .file-list-li {
            font-size: 13px;
            i {
              color: $clr-btngrn;
              margin-right: 5px;
              font-size: 14px;
            }
            .remove-files-list {
              color: #61c6f1;
              padding: 0 15px;
              font-size: 13px;
              font-weight: 300;
            }
          }
        }
      }
      .mandatory.has-error {
        position: absolute;
        bottom: -20px;
        font-size: 12px;
        right: 0;
      }
    }
    &.fieldofficer_form {
      li {
        label {
          .mandatory {
            z-index: 0;
            color: red;
            font-size: 10px;
          }
        }
      }
    }
  }
}

/*form validation design css*/
.has-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
/*treetpelist page css*/

/*Application list page css*/
.applist_cont {
  .growalarm_content .commontbl_wrap .commontbl_cont {
    select.form-control {
      -webkit-appearance: auto;
      appearance: auto;
      width: 55%;
      margin: 5px 0;
    }
    thead {
      th {
        padding: 0 10px;
        &.actionth {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          &.nametd {
            width: 15%;
            a {
              color: blue;
              transition: 0.3s;
              &:hover {
                text-decoration: underline !important;
              }
            }
          }
          &.headtd,
          &.treeplantedtd {
            text-align: center;
          }
          &.statustwo {
            width: 15%;
            &.rejected span {
              background: #aaa;
            }
            &.newrequest span {
              background: #e5b000 !important;
              cursor: pointer;
            }
          }
          &.action_cont {
            width: auto;
            text-align: center;
            vertical-align: middle;
            display: flex;
            a {
              vertical-align: middle;
              &.meetingreqpopup {
                margin: 0;
                order: 1;
                padding: 0 15px 0 20px;
              }
              i {
                color: #333;
              }
              &.emailicon,
              &.pdficon {
                i {
                  font-size: 18px;
                }
              }
              &.mobileicon i {
                font-size: 24px;
              }
            }
            .apprvepopup_cont {
              display: inline-block;
              .apprvepopup_btn {
                display: flex;
                a i {
                  font-family: "FontAwesome";
                  font-style: normal;
                }
              }
            }
            .makeappoint_cont {
              display: inline-block;
              margin-left: 10px;
              a {
                margin: 0;
              }
              button,
              a {
                background: #50b041;
                color: $clr-white;
                padding: 0 10px;
                border-radius: 5px;
                height: 30px;
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                border: none;
                white-space: nowrap;
                width: 160px;
                justify-content: center;
              }
            }
          }
          &.actiontd {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  &.treetypelist_cont {
    .growalarm_content .commontbl_wrap {
      height: calc(100vh - 170px);
      .commontbl_cont {
        tbody {
          tr {
            td {
              &.idtd {
                width: auto;
              }
              &.treespeciednametd {
                width: 30%;
                word-break: break-all;
              }
              &.imgtreetd {
                padding: 10px 20px;
                .addtreeimg_cont {
                  width: 80px;
                  height: 80px;
                  img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              &.action_cont {
                display: table-cell;
                a {
                  display: flex;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.nomeetreqbtn {
  background: transparent;
  color: $clr-white;
  border: none;
  cursor: none;
  pointer-events: none;
}
/*project list page css*/
.projectlist_cont {
  .growalarm_content .commontbl_wrap .commontbl_cont {
    tbody {
      td {
        &.tdnum {
          text-align: center;
        }
        &.action_cont {
          width: auto;
        }
        &.projnametd {
          width: 15%;
          a {
            color: blue;
            transition: 0.3s;
            &:hover {
              text-decoration: underline !important;
            }
          }
        }
      }
    }
  }
}
/*Grow Manager pages css*/
.growmanhome_cont {
  .card_box {
    > a {
      width: 100%;
      height: 100%;
    }
    .section_heading {
      font-size: 16px;
      padding: 20px;
    }
    &.growmanapplist_cont a {
      display: flex;
      flex-basis: 100%;
      max-width: 100%;
    }
    .cardboxinner_wrap {
      .section_heading {
        padding: 0;
      }
    }
    &.survival_cont {
      .cardboxinner_wrap {
        flex-wrap: nowrap;
        .section_heading {
          padding: 0;
        }
      }
    }
  }
}
/*calendar widget css*/
.calendly-inline-widget iframe {
  height: 100vh;
}
.iSDZ5t7E7z {
  background-color: #333 !important;
}

.curvtext {
  text-align: center;
  color: green;
  position: absolute;
  right: -55px;
  top: 7px;
  span {
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
  }
}
.c1 {
  transform: translate(-23px, 8px) rotate(-87deg);
}

.c2 {
  transform: translate(-29px, 1px) rotate(-80deg);
}

.c3 {
  transform: translate(-34px, -6px) rotate(-76deg);
}

.c4 {
  transform: translate(-37px, -12px) rotate(-61deg);
}

.c5 {
  transform: translate(-37px, -17px) rotate(132deg);
}

.c6 {
  transform: translate(-39px, -23px) rotate(-44deg);
}

.c7 {
  transform: translate(-36px, -27px) rotate(-20deg);
}

.c8 {
  transform: translate(-36px, -29px) rotate(-7deg);
}

.c9 {
  transform: translate(-34px, -29px) rotate(1deg);
}

.c10 {
  transform: translate(-33px, -29px) rotate(9deg);
}

.c11 {
  transform: translate(-32px, -28px) rotate(17deg);
}

.c12 {
  transform: translate(-31px, -25px) rotate(28deg);
}

.c13 {
  transform: translate(-27px, -18px) rotate(55deg);
}

.c14 {
  transform: translate(-29px, -11px) rotate(75deg);
}

.c15 {
  transform: translate(-33px, -4px) rotate(71deg);
}

.c16 {
  transform: translate(-38px, 3px) rotate(82deg);
}

.c17 {
  transform: translate(-42px, 10px) rotate(90deg);
}

.paginationDiv {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    li {
      a {
        border: none;
        background: transparent;
        color: #333;
        font-size: 16px;
        font-weight: 600;
        transition: 0.3s;
        border: 1px solid #ddd;
        min-width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        padding: 0 10px;
        &:hover {
          color: #229444;
        }
      }
      &.active a {
        background: transparent;
        color: #229444;
        border-color: #249444;
      }
      &.disabled {
        display: none;
      }
    }
  }
}

.growalarm_content .commontbl_wrap > div > div:last-child {
  opacity: 1 !important;
}

/*MeetingScheduledModel popup css*/
body.popupmeet.pf-c-backdrop__open .pf-c-backdrop {
  background-color: rgba(3, 3, 3, 0.12);
}
.MeetingScheduledModel {
  border-radius: 5px;
  .pf-c-button {
    border: 1px solid;
    border-radius: 50px;
    padding: 0;
    width: 30px;
    height: 30px;
    top: 23px;
    z-index: 9;
  }
  .pf-c-modal-box__header {
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
    padding-bottom: 20px;
    position: relative;
    h1 {
      text-align: center;
      span {
        font-weight: 600;
        text-align: center;
      }
    }
  }
  .MeetingScheduledModelDiv {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    strong {
      width: 40%;
      display: flex;
    }
    .infotag {
      width: 60%;
      display: flex;
    }
    strong,
    .infotag,
    .meetingGuestsDiv {
      padding: 12px 0;
      font-size: 14px;
    }
    .meetingGuestsDiv {
      display: flex;
      width: 100%;
      strong {
        width: 40%;
        display: flex;
      }
      ul {
        padding: 0;
        width: 60%;
      }
    }
  }
}
/*addproject latitude, longitude field css*/
.mapmodal_cont {
  &.laglongaddfieldcont {
    position: relative;
    height: 125px;
    .showcords {
      display: flex;
      position: absolute;
      flex-wrap: wrap;
      width: 100%;
      bottom: 8px;
      span {
        font-size: 10px;
        width: 100%;
        line-break: anywhere;
        b {
          margin-right: 3px;
        }
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
/*mappopup css*/
.tooltipdata {
  visibility: hidden;
  background-color: $clr-white;
  color: #3d3e40;
  text-align: center;
  border-radius: 0;
  padding: 5px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  border: 1px solid #3d3e40;
  min-width: 70px;
  height: 30px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  bottom: -35px;
  border-radius: 4px;
}
body.pf-c-backdrop__open.mapmodalopen .pf-l-bullseye {
  padding: 0 !important;
  width: 100% !important;
}
.addprojectmap_modal {
  top: 0 !important;
  max-height: 100% !important;
  max-width: 100% !important;
  .pf-c-button.pf-m-plain {
    top: 8px;
    right: 25px;
    padding: 0;
    z-index: 1;
    background: #edffff;
    height: 30px;
    width: 30px;
    color: #333;
    &:focus-visible {
      outline: none;
    }
    &:hover {
      fill: $clr-white;
      border-color: #aef;
      box-shadow: 0 0 8px #fff;
    }
  }
  .pf-c-modal-box__body {
    height: 100vh;
    margin-right: 0 !important;
    padding: 0 !important;
  }
  .btn_mainviewer {
    position: relative;
  }
  button.modal_btn {
    &.submit_btn,
    .reset_btn {
      background: $clr-btngrn;
      border: none;
      color: $clr-white;
      margin-right: 10px;
    }
    &.submit_btn {
      position: absolute;
      left: 150px;
      top: 7px;
      z-index: 1;
      font-size: 0;
      min-width: auto;
      padding: 0;
      border: 1px solid #444;
      background: #303336;
      border-radius: 0;
      line-height: initial;
      width: 32px;
      height: 32px;
      border-radius: 4px;
      &::before {
        content: "\f0c7";
        font-family: "Font Awesome 5 Free";
        font-size: 22px;
        color: #edffff;
      }
      &:hover {
        fill: $clr-white;
        border-color: #aef;
        box-shadow: 0 0 8px #fff;
        .tooltipdata {
          visibility: visible;
        }
      }
    }
    &.cancel_btn {
      background: #303336;
      border: 1px solid #303336;
      border-radius: 0;
      width: 32px;
      height: 32px;
      line-height: 32px;
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      top: 0;
      transition: 0.1s;
      i {
        color: #edffff;
      }
      .tooltipdata {
        left: 0;
      }
      &:hover {
        fill: $clr-white;
        border-color: #aef;
        box-shadow: 0 0 8px #fff;
        .tooltipdata {
          visibility: visible;
        }
      }
    }
  }
}
.projdetailpageMap_modal {
  .mapMainDiv .mapViewer {
    flex-basis: 100%;
    max-width: 100%;
    .cesium-viewer .cesium-viewer-toolbar {
      display: none;
    }
  }
}
.mapMainDiv {
  display: flex;
  flex-wrap: wrap;
  .polyControls {
    // display: flex;
    // flex-basis: 13%;
    // max-width: 13%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 7px;
    .posnOneDic {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    > div {
      .posnOneDiccontent {
        display: flex;
        margin-top: 4px;
        p {
          display: flex;
          order: 1;
          font-weight: 500;
          margin: 0;
          color: $clr-white;
          text-shadow: 0 0 10px #555;
        }
        input {
          border: 1px solid #ddd;
          margin: 5px;
          width: 15px;
          height: 15px;
          padding: 0 0 0 10px;
          border-radius: 0;
          &:focus {
            outline: none;
          }
        }
      }
      .mapresetwrap {
        margin-left: 10px;
        .reset_btn {
          background: $clr-btngrn;
          border: none;
          color: $clr-white;
          margin-right: 10px;
        }
      }
    }
  }
  .mapViewer {
    flex-basis: 100%;
    max-width: 100%;
    height: 100%;
    > div {
      height: 100%;
      .cesium-viewer-bottom,
      .cesium-viewer-animationContainer {
        display: none;
      }
      .cesium-viewer-toolbar {
        .cesium-navigationHelpButton-wrapper {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}
.addtreetypemap_modal .modal_btn.submit_btn {
  left: 50px !important;
}
.mapMainDiv
  .mapViewer
  > div
  .cesium-viewer-toolbar
  [data-bind="attr: { title: buttonTooltip },click: toggleDropDown"] {
  display: none;
}
.sequencewrapdiv {
  margin: 50px 40px 40px 110px;
  .growalarm_content .commontbl_wrap {
    h2 {
      font-weight: 700;
      color: #384d76;
    }
    .commontbl_cont {
      border: 1px solid #333;
      thead {
        background: transparent;
        th {
          white-space: inherit;
          padding: 10px;
          border-right: 1px solid #333;
          border-bottom: 1px solid #333;
          color: #333;
          font-weight: 600;
          &.carbonseqth {
            background: yellow;
          }
        }
      }
      tbody tr td {
        border-right: 1px solid #333;
        border-bottom: none !important;
        &.carbonseqtd {
          background: yellow;
        }
      }
    }
  }
}
/*source popup css*/
.sourcebtn {
  background-color: transparent !important;
  position: absolute !important;
  right: -7px;
  color: blue !important;
  bottom: -25px;
  text-decoration: underline;
  font-weight: 600;
}
.sourcemodal_cont {
  .pf-c-button.pf-m-plain {
    top: 0 !important;
    right: 0 !important;
  }
  .pf-c-modal-box__body {
    margin-right: 0 !important;
    margin-top: 10px !important;
    .sequencewrapdiv {
      margin: 0;
      .growalarm_content .commontbl_wrap {
        height: auto;
      }
    }
  }
}
.referlinktxt {
  display: inline-flex;
  margin: 20px 0 0;
  align-items: center;
  font-size: 14px;
  a {
    color: blue;
    margin-left: 5px;
  }
}
.Toastify__progress-bar {
  transform-origin: right !important;
}
/*paymentsuccess page css*/
.paysuccesscont {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div {
    &:first-child {
      font-family: $font-calc;
      line-height: 34px;
      margin-bottom: 40px;
      font-size: 24px;
      text-align: center;
    }
    button {
      width: 300px;
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 700;
      height: 50px;
      line-height: 50px;
      font-family: "Chivo", sans-serif;
    }
  }
}
.fundmappro_cont {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0;
  .form-group {
    > Label {
      order: 1;
    }
    &.halfwidth,
    &.websitefieldcont {
      .mandatory.has-error {
        bottom: -20px;
        position: absolute;
        font-size: 11px;
      }
    }
    &.halfwidth {
      width: 48%;
      margin: 10px 1% 0;
      position: relative;
      .basic-single.css-2b097c-container .css-2613qy-menu {
        z-index: 9 !important;
      }
      // .basic-single.css-2b097c-container {
      //   border-bottom: 1px solid #ddd;
      //   .css-yk16xz-control {
      //     border: none;
      //     background: transparent;
      //   }
      // }
    }
    &.websitefieldcont {
      flex-wrap: wrap;
      width: 98%;
      margin: 30px 1%;
      position: relative;
      > label {
        font-size: 14px;
      }
      .websitescheckbxwrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > div {
          width: calc(100% / 4);
          display: flex;
          label {
            order: 2;
          }
          input {
            -webkit-appearance: checkbox;
            order: 1;
            line-height: 15px;
            height: 15px;
            margin-right: 5px;
          }
        }
      }
    }
    &.foundmapbtn_cont {
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        background: #118d45;
        border: none;
        color: #fff;
        min-width: 120px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
      }
    }
  }
}

.card_box.nooftreplnt_cont {
  position: relative;
}
.donutpercent {
  position: absolute;
  top: calc(100% - 77%);
  left: calc(100% - 50%);
  right: auto;
}
.donutpercent div {
  font-size: 12px;
}
.donutpercent div:first-child {
  transform: translate(-2px, 6px) rotate(2deg);
}
.donutpercent div:nth-child(2) {
  transform: translate(73px, 38px) rotate(54deg);
}

.donutpercent div:nth-child(3) {
  transform: translate(81px, 110px) rotate(106deg);
}

.donutpercent div:nth-child(4) {
  transform: translate(25px, 151px) rotate(340deg);
}

.donutpercent div:nth-child(5) {
  transform: translate(-67px, 123px) rotate(399deg);
}

.donutpercent div:nth-child(6) {
  transform: translate(-116px, 18px) rotate(270deg);
}
.mapmodalviewpopup {
  // max-width: 1000px !important;

  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
  position: absolute !important;
  right: 0px;
  left: 0px;
  bottom: 0px;
  top: 0px;
  height: 100%;
  > .pf-m-plain {
    top: -3px !important;
    right: -30px !important;
    color: #fff !important;
    z-index: 1;
    outline: none !important;
    border: none !important;
    cursor: pointer !important;
  }
  .pf-c-modal-box__body {
    padding: 0px;
    padding-top: 30px;
    margin: 0px !important;
    overflow: hidden;
    background: #8eba76;
    // height: 100%;
    padding-bottom: 0px !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    .plotTitle {
      position: absolute;
      top: 3px;
      color: #fff;
      left: 10px;
      cursor: pointer !important;
    }

    .mapmodalview_cont {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
/*Treeties home page css*/
.treetiesfont {
  font-family: $font-opensans;
}
/* header css */
.header {
  background: transparent;
  width: 100%;
  padding: 35px 5%;
  transition: 0.2s;
  display: flex;
  align-items: center;
  .container {
    padding: 0;
  }
  @include media-max(xs) {
    top: 20px;
    padding: 15px 15px;
    height: auto;
  }
  &.main-nav-scrolled {
    background: rgba(0, 23, 24, 0.9) no-repeat;
  }
  .header_cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 0;
    text-decoration: none;
    @include media-max(xs) {
      margin-left: 2%;
    }
    @media (max-width: 420px) {
      img {
        width: 140px;
      }
    }
  }

  .menu-icon {
    cursor: pointer;
    display: flex;
    position: relative;
    user-select: none;
    .navicon {
      display: flex;
      position: relative;
      transition: background 0.2s ease-out;
      padding: 0 15px;
      border: 1px solid #fff;
      height: 48px;
      align-items: center;
      border-radius: 50px;
      color: #ffff;
      width: 120px;
      justify-content: center;
      &:after {
        top: -5px;
      }
      @media (max-width: 420px) {
        height: 35px;
        width: 100px;
      }
    }
  }
  .menu-btn {
    display: none;
    &:checked ~ .menu {
      max-height: 240px;
    }
    &:checked ~ .menu-icon .navicon {
      background: transparent;
    }
    &:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
    }
    &:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
    }
    &:checked ~ .menu-icon:not(.steps) .navicon:before,
    &:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    li a {
      display: block;
      text-decoration: none;
      color: #fff;
    }
  }
}
@include media-max(xs) {
  .header .header_cont {
    .menu-icon {
      display: flex;
    }
  }
}
.treeties_heading {
  font-family: $font-opensans;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 30px;
  span {
    color: #8eba76;
  }
  @include media-max(xs) {
    font-size: 24px !important;
    line-height: 36px !important;
    margin-bottom: 15px !important;
  }
  @media (max-width: 420px) {
    font-size: 18px !important;
    line-height: 28px !important;
  }
}
.homePlanet {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  background-color: #fff;
  @include media-max(xs) {
    padding: 20px 0;
  }
  h1 {
    font-family: $fonteuclid;
    font-size: 36px;
    line-height: 46px;
    text-align: left;
    margin-bottom: 30px;
    span {
      color: #8eba76;
    }
  }
  .List-View {
    margin-top: 30px;
    @include media-max(xs) {
      margin-top: 10px;
    }
    .item {
      margin-bottom: 30px;
      h4,
      p {
        font-family: $fonteuclid;
        font-size: 16px;
        line-height: 20px;
      }
      h4 {
        color: #333;
      }
      p {
        color: #999999;
      }
    }
  }
}
.ourFactor {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  background-color: #f7f9fb;
  @include media-max(xs) {
    padding: 20px 0;
  }
  h2 {
    text-align: center;
    span {
      color: #8eba76;
    }
  }
  .row {
    justify-content: space-between;
    margin: 0 -40px;
    @include media-max(md) {
      margin: 0 -30px;
    }
    > * {
      @include media-max(md) {
        width: calc(100% / 2 - 20px);
        margin: 0 10px 20px;
      }
      @include media-max(sm) {
        width: calc(100% / 1 - 20px);
      }
    }

    .col-md-4 {
      padding: 0 40px;
      @media (max-width: 1200px) {
        padding: 0 20px;
      }
      @include media-max(sm) {
        margin-bottom: 30px;
      }
    }
  }
  .card {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 360px;
    border-radius: 5px;
    border: none;
    > div {
      padding: 20px;
      h4,
      p {
        font-family: $fonteuclid;
        font-size: 16px;
        line-height: 20px;
      }
      h4 {
        color: #333;
        margin-bottom: 20px;
      }
      p {
        color: #999999;
      }
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.collaborate {
  background-image: url(https://d3kn46kx26n24t.cloudfront.net/websites/staticimages/collaborate-banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  background-color: #f7f9fb;
  @include media-max(xs) {
    padding: 20px 0;
  }
  h3 {
    text-align: left;
    color: #fff;
    span {
      color: #8eba76;
    }
  }
  .horList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    @include media-max(sm) {
      margin-bottom: 10px;
      flex-direction: column;
    }
    .item {
      @include media-max(sm) {
        width: 100%;
        margin: 0 0 20px 0;
      }
      font-family: $fonteuclid;
      background-color: #fff;
      padding: 20px 15px;
      width: 255px;
      border-radius: 4px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-max(xs) {
        padding: 13px 15px;
      }
      &.retail,
      &.corporates {
        margin-right: 15px;
        @include media-max(sm) {
          margin: 0 0 20px 0;
        }
      }
      &.retail,
      &.corporates,
      &.ngo {
        &:after {
          content: "\f133";
          font-family: "FontAwesome";
          font-size: 16px;
          position: absolute;
          right: 15px;
          display: none;
        }
      }
      img {
        width: 22px;
      }
    }
  }
  p {
    font-family: $fonteuclid;
    color: #fff;
    font-size: 20px;
    @include media-max(xs) {
      font-size: 16px;
      text-align: center;
    }
    a {
      color: #fff;
      text-decoration: underline !important;
    }
  }
}
.sustainability {
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  @include media-max(xs) {
    padding: 20px 0 50px;
  }
  h4 {
    text-align: left;
    margin-bottom: 10px;
    span {
      color: #8eba76;
    }
  }
  .treeties_subheading {
    font-size: 24px;
    text-transform: uppercase;
    font-family: "EuclidCircularBRegular";
    line-height: 26px;
    @include media-max(xs) {
      font-size: 15px;
      line-height: 20px;
    }
  }
  .content {
    font-size: 16px;
    color: #666;
    margin: 0 auto;
  }
}
.track {
  position: relative;
  padding: 40px 0;
  background-color: #f6f5f7;
  height: auto !important;
  @include media-max(xs) {
    padding: 20px 0;
  }
  .content {
    padding: 40px 0;
    @include media-max(xs) {
      padding: 20px 0;
    }
    h5 {
      font-size: 36px;
      line-height: 46px;
      text-align: left;
      margin-bottom: 30px;
      span {
        color: #8eba76;
      }
    }
    p {
      font-family: $fonteuclid;
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin-bottom: 20px;
    }
    .btn {
      background-color: #a1d5ab;
      font-size: 16px;
      color: #666;
      text-decoration: none;
      padding: 10px 15px;
    }
  }
  .tracktop_img {
    position: absolute;
    top: -50px;
    left: 50%;
    z-index: 1;
    width: 160px;
    transform: translateX(10%);
    @media (max-width: 1200px) {
      transform: translateX(-10%);
    }
    @include media-max(md) {
      transform: translateX(-30%);
    }
    @include media-max(sm) {
      transform: translateX(70%);
    }
    @include media-max(xs) {
      transform: translateX(60%);
      width: 100px;
      top: -50px;
    }
  }
  .trackbotm_img {
    position: absolute;
    bottom: -70px;
    right: 35px;
    width: 160px;
    @media (max-width: 1200px) {
      right: 25px;
    }
    @include media-max(xs) {
      right: 5px;
      bottom: -40px;
      width: 100px;
    }
  }
}

footer {
  background-color: #222222;
  padding: 30px 0;
  .btmLinks,
  .social {
    @include media-max(sm) {
      text-align: center;
    }
  }
  .btmLinks {
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;
    @include media-max(xs) {
      margin-bottom: 1rem;
    }
    li {
      display: inline-block;
      a {
        font-family: $fonteuclid;
        font-size: 12px;
        line-height: 15px;
        color: #999999 !important;
        border-right: 1px solid #999;
        padding: 0 10px;
        &:hover {
          color: #a1d5ab !important;
        }
      }
      &:first-child {
        a {
          padding-left: 0px;
        }
      }
      &:last-child {
        a {
          border-right: 0px;
        }
      }
    }
  }
  .social {
    p {
      font-family: $fonteuclid;
      font-size: 12px;
      color: #999999;
      margin-bottom: 0.5rem;
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        a {
          font-size: 14px;
          color: #999999;
          padding: 0 5px;
          i.fa {
            font-family: FontAwesome !important;
          }
        }
        &:first-child {
          a {
            padding-left: 0px;
          }
        }
      }
    }
  }
  .copyright {
    text-align: right;
    margin-top: 40px;
    @include media-max(sm) {
      text-align: center;
      margin-top: 0;
    }
    .footer_logo {
      width: 130px;
      margin: 0 0 20px;
      @include media-max(xs) {
        margin-bottom: 40px;
      }
    }
    p {
      font-family: $fonteuclid;
      font-size: 12px;
      color: #999999;
    }
  }
}
.bannerTxt {
  font-family: $fonteuclid;
  font-size: 36px;
  white-space: nowrap;
  @include media-max(sm) {
    font-size: 24px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
  &.bannerTxtUpr {
    @include media-max(sm) {
      div {
        line-height: 26px;
      }
    }
    @include media-max(xs) {
      transform: translate(-53%, 0%) !important;
      div {
        line-height: 26px;
      }
    }
    @media (max-width: 420px) {
      div {
        line-height: 20px;
      }
    }
  }
  &.bannerTxtLwr {
    @include media-max(sm) {
      transform: translate(-53%, -5%) !important;
      div {
        line-height: 0;
      }
    }
  }
  &.regHed {
    font-size: 72px;
    @include media-max(sm) {
      font-size: 42px;
    }
    @include media-max(xs) {
      font-size: 24px;
      transform: translate(-53%, 0%);
    }
  }
  &.regSubHed {
    font-size: 20px;
    @include media-max(sm) {
      font-size: 16px;
    }
    @include media-max(xs) {
      font-size: 12px;
    }
  }
}
.registerbtn {
  background: #a1d5ab;
  border: none;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px;
  font-family: $fonteuclid;
  font-size: 16px;
  color: #283c1d;
  @media (max-width: 400px) {
    margin: 10px 0;
  }
}
.treetiescontainer {
  padding: 0 5% !important;
  max-width: 100% !important;
  font-family: $fonteuclid;
}
.homepagecont {
  @include media-max(md) {
    display: none;
  }
  height: auto !important;
}
.mobilebanner_cont {
  display: none;
  @include media-max(md) {
    display: block;
    pointer-events: none;
    overflow-y: auto;
  }
  .carousel.carousel-slider .control-arrow {
    pointer-events: fill;
  }
  .slide {
    .mobbanner_slide {
      .mobban_content {
        -webkit-perspective: 1000px;
        -moz-perspective: 1000px;
        -ms-perspective: 1000px;
        -o-perspective: 1000px;
        perspective: 1000px;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #fff;
        overflow: hidden;
        .registerbtn {
          cursor: pointer;
          pointer-events: fill;
        }
        .mobbantxt {
          -webkit-transition: all 1500ms cubic-bezier(0.35, 0.43, 0, 1);
          -moz-transition: all 1500ms cubic-bezier(0.35, 0.43, 0, 1);
          -ms-transition: all 1500ms cubic-bezier(0.35, 0.43, 0, 1);
          -o-transition: all 1500ms cubic-bezier(0.35, 0.43, 0, 1);
          transition: all 1500ms cubic-bezier(0.35, 0.43, 0, 1);
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
          &.mobbantextlwr,
          &.mobbantextupr {
            display: block;
            -webkit-transform: translate(0%, 100%);
            -ms-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
            -webkit-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
            -moz-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
            -ms-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
            -o-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
            transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }
      }
    }
    &.selected {
      .mobbanner_slide {
        .mobban_content {
          .mobbantxt {
            &.mobbantextlwr {
              -webkit-transform: translate(0%, 0%);
              -moz-transform: translate(0%, 0%);
              -ms-transform: translate(0%, 0%);
              -o-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
              -webkit-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -moz-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -o-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -webkit-transition-delay: 0.7s;
              -moz-transition-delay: 0.7s;
              -ms-transition-delay: 0.7s;
              -o-transition-delay: 0.7s;
              transition-delay: 0.7s;
            }
            &.mobbantextupr {
              -webkit-transform: translate(0%, 0%);
              -moz-transform: translate(0%, 0%);
              -ms-transform: translate(0%, 0%);
              -o-transform: translate(0%, 0%);
              transform: translate(0%, 0%);
              -webkit-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -moz-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -o-transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              transition: all 1500ms cubic-bezier(0.075, 0.82, 0.165, 1);
              -webkit-transition-delay: 0.7s;
              -moz-transition-delay: 0.7s;
              -ms-transition-delay: 0.7s;
              -o-transition-delay: 0.7s;
              transition-delay: 0.7s;
              -webkit-transition-delay: 0.85s;
              -moz-transition-delay: 0.85s;
              -ms-transition-delay: 0.85s;
              -o-transition-delay: 0.85s;
              transition-delay: 0.85s;
            }
          }
        }
      }
    }
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
}
.registerpopup {
  .pf-c-button {
    top: 30px !important;
    right: 30px !important;
    color: #333 !important;
    -webkit-appearance: none;
  }
  .pf-c-modal-box__body {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0 !important;
    .registartion_container {
      padding-bottom: 0;
      .registation_head {
        border-bottom: none;
      }
      .registartion_cont {
        margin: 40px;
        .register_form li.submitbtn_wrap {
          margin-top: 30px;
        }
      }
    }
    h1 {
      text-align: center;
      line-height: 48px;
      font-size: 32px;
    }
  }
}
.selectCollaborate {
  border-bottom: 1px solid #ddd !important;
  border: none;
  width: 80%;
  padding: 0 10px;
  font-size: 14px;
  &:focus {
    outline: none;
  }
}
.homepagecont .react-parallax img {
  width: 100% !important;
  height: 100% !important;
  @include media-max(xs) {
    height: auto !important;
  }
}
/*about us page design*/
.aboutuspage_cont {
  .aboutbanner {
    display: flex;
    height: 250px;
    border-bottom: 1px solid #ddd;
    background: #999;
    position: relative;
    img {
      width: 100%;
    }
    h1 {
      position: absolute;
      bottom: 10px;
      left: 70px;
      font-size: 32px;
      font-weight: 700;
      color: #fff;
      @include media-max(xs) {
        left: 40px;
      }
    }
  }
  .about_content {
    padding: 50px 0 80px;
    @include media-max(xs) {
      padding: 20px 0 80px;
    }
    @media (max-width: 480px) {
      padding: 20px 0 50px;
    }
    p {
      font-size: 36px;
      line-height: 46px;
      color: #333;
      @include media-max(md) {
        font-size: 24px;
        line-height: 34px;
      }
      @include media-max(xs) {
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
  .vision_cont {
    background: #8bb351;
    position: relative;
    padding: 50px 0;
    @include media-max(xs) {
      padding: 20px 0;
    }
    .vision_img {
      position: absolute;
      top: -90px;
      right: 0;
      @include media-max(xs) {
        top: -84px;
      }
      @media (max-width: 480px) {
        top: -72px;
      }
      @media (max-width: 420px) {
        top: -63px;
      }
      @media (max-width: 380px) {
        top: -57px;
      }
      @media (max-width: 320px) {
        top: -48px;
      }
    }
    .treeties_heading {
      text-transform: uppercase;
      color: $clr-white;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      color: #fff;
    }
  }
  .mission_cont {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    @include media-max(xs) {
      padding-bottom: 0;
    }
    > div {
      &.mission_img {
        flex: 0 0 35%;
        max-width: 35%;
        @include media-max(md) {
          order: 1;
        }
      }
      &.mission_content {
        flex: 0 0 65%;
        max-width: 65%;
        padding: 50px 12%;
        @include media-max(md) {
          padding: 20px 5%;
        }
        .treeties_heading {
          text-transform: uppercase;
        }
      }
      @include media-max(md) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .ourvalues_cont {
    background: #f7f9fb;
    padding: 50px 0;
    @include media-max(xs) {
      padding: 20px 0;
    }
    .treeties_heading {
      text-align: center;
    }
    .values_list {
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      margin: 0 -10px;
      li {
        width: calc(100% / 4 - 80px);
        margin: 0 40px 0;
        @include media-max(lg) {
          width: calc(100% / 4 - 40px);
          margin: 0 20px 0;
        }
        @media (max-width: 1080px) {
          width: calc(100% / 2 - 40px);
          margin: 0 20px 20px;
        }
        @include media-max(xs) {
          width: calc(100% / 1 - 40px);
        }
        h4 {
          font-size: 16px;
          margin-bottom: 20px;
          white-space: nowrap;
        }
        p {
          color: #999;
        }
      }
    }
  }
  .ourteam_Cont {
    padding: 50px 0;
    @include media-max(xs) {
      padding: 20px 0;
    }
    .teamwrap_cont {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -10px;
      > .teamwrap {
        display: flex;
        background: #8eba76;
        border-radius: 5px;
        padding: 30px;
        width: calc(100% / 3 - 20px);
        margin: 0 10px;
        @media (max-width: 1120px) {
          width: calc(100% / 2 - 20px);
          margin: 0 10px 20px;
        }
        @include media-max(sm) {
          width: calc(100% / 1 - 20px);
        }
        @media (max-width: 360px) {
          flex-direction: column;
          align-items: center;
        }
        .teamimg_cont {
          display: flex;
          width: 130px;
          margin-right: 20px;
          img {
            border-radius: 50%;
          }
        }
        .team_content {
          display: flex;
          flex-direction: column;
          width: 100%;
          @media (max-width: 360px) {
            align-items: center;
            margin-top: 15px;
          }
          h4,
          span {
            color: #fff;
          }
          h4 {
            font-size: 20px;
            line-height: 25px;
            margin: 0;
          }
          span {
            font-size: 16px;
            line-height: 20px;
          }
          .teamsocial_links {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 25px 0 0;
            li {
              margin-right: 15px;
              i {
                color: #fff;
                font-size: 18px;
                font-family: FontAwesome !important;
              }
            }
          }
        }
      }
    }
  }
}
.homepagecont .react-parallax:first-child .bannerTxt.bannerTxtLwr > div {
  @media (max-width: 320px) {
    opacity: 1;
  }
}
.pagentfundcont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.body_container {
  width: 100%;
  height: 700px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 50px;
  border-radius: 20px;
  margin: 20px 10px 40px 60px !important;
}
// PROFILE DETAILS
ul.profileDetails {
  display: flex;
  padding: 10px 0 0 0px;
  li {
    padding-right: 15px;
    p {
      margin: 0;
      text-transform: capitalize;
      &.info {
        text-align: center;
      }
    }
  }
}
.linkData {
  position: absolute;
  left: 36%;
  transform: translateX(-36%);
}
.linkImage {
  position: absolute;
  left: 43%;
  transform: translateX(-43%);
}
.linkInsert {
  position: absolute;
  left: 54%;
  transform: translateX(-54%);
}

.projectModal {
  button.pf-c-button {
    top: -33px;
    right: -40px;
    color: $clr-white;
  }
  .pf-c-modal-box__body {
    height: 600px;
    .treedetails_cont {
      width: 100%;
      height: 100%;
      .treedetimg {
        .carousel-root {
          .carousel-slider {
            .control-dots {
              display: none;
            }
            .control-arrow {
              opacity: 1;
              font: 300 normal normal 32px/1 FontAwesome;
              background-color: transparent !important;
            }
            .carousel-status {
              display: none;
            }
            button.control-prev {
              &::before {
                border-right: none !important;
                content: "\f104";
                font-family: "Font Awesome 5 Free" !important;
                font-weight: 600;
                border: none;
                background-color: #118d45;
                padding: 3px 10px;
                border-radius: 40px;
                font-style: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
              }
            }
            button.control-next {
              &::before {
                border-right: none !important;
                content: "\f105";
                font-family: "Font Awesome 5 Free" !important;
                font-weight: 600;
                border: none;
                background-color: #118d45;
                padding: 3px 10px;
                border-radius: 40px;
                font-style: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
              }
            }
            .slider-wrapper {
              height: 450px;
              ul.slider {
                li.slide {
                  width: 100%;
                  max-width: 100%;
                  height: 450px;
                  img {
                    height: 100%;
                    width: auto;
                  }
                }
              }
            }
          }
          .carousel {
            .thumbs-wrapper {
              margin: 15px 0;
              ul.thumbs {
                padding-left: 0;
                margin-bottom: 0;
                overflow-x: auto;
                &::-webkit-scrollbar {
                  width: 2px;
                  background-color: #f5f5f5;
                  height: 4px;
                }

                &::-webkit-scrollbar-thumb {
                  background-color: #000000;
                  border-radius: 9px;
                }
                li {
                  height: 84px;
                  border-radius: 5px;
                  img {
                    height: 100%;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.projectModalVideo {
  .pf-c-modal-box__body {
    height: 500px;
  }
}

/*Treeties new ui css*/
.home_bg {
  .treetiesfont {
    background-image: url("../assets/home_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_homebg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_homebg.jpg");
    }
  }
}

.ourexperience_bg {
  .treetiesfont {
    background-image: url("../assets/ourexperience_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_ourexperiencebg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_ourexperiencebg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.contact_us_bg {
  .treetiesfont {
    background-image: url("../assets/contactus_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_contactusbg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_contactusbg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.partners_bg {
  .treetiesfont {
    background-image: url("../assets/partners_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_partnersbg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_partnersbg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.repository_bg {
  .treetiesfont {
    background-image: url("../assets/knowrepositoy_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_repositorybg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_repositroybg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.about_us_bg {
  .treetiesfont {
    background-image: url("../assets/aboutus_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_aboutusbg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_aboutusbg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.platform_bg {
  .treetiesfont {
    background-image: url("../assets/platform_bg.jpg");
    @media (min-width: 1600px) {
      background-image: url("../assets/big_platformbg.jpg");
    }
    @include media-max(xs) {
      background-image: url("../assets/small_platformbg.jpg");
    }
  }
  .treeties_container::after {
    display: none;
  }
}

.refundpage_bg {
  .treetiesfont {
    background-image: url("../assets/menuBg.jpg");
  }
  .treeties_container::after {
    display: none;
  }
}

.treetiesfont {
  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -o-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  @include media-max(xs) {
    background-size: cover;
  }
  .header.main-nav {
    position: fixed;
    top: 0;
    padding: 0;
    z-index: 10;
    .header_cont {
      height: 120px;
      padding: 0 5%;
    }
    &.main-nav-scrolled {
      height: 80px;
    }
  }
}
.greenunderline {
  display: inline-flex;
  border-bottom: 3px solid #83c340;
}
.morelrpadd_cont {
  .treeties_container {
    padding: 0 16% 0 20%;
    @include media-min(xl) {
      padding: 0 16% 0 23%;
    }
    @media (max-width: 1200px) {
      padding: 0 6% 0 7%;
    }
  }
}
.lesslrpadd_cont {
  .treeties_container {
    padding: 0 15% 0 20%;
    @include media-min(xl) {
      padding: 0 16% 0 23%;
    }
    @media (max-width: 1200px) {
      padding: 0 5% 0 7%;
    }
  }
}
.purpose_cont {
  padding: 330px 0 210px;
  @include media-max(md) {
    padding: 100px 0;
  }
  @include media-max(xs) {
    padding: 200px 0 120px;
  }
}
.paddingbotm {
  padding-bottom: 210px;
  @include media-max(lg) {
    padding-bottom: 100px;
  }
  &.pd-100 {
    padding-bottom: 100px;
  }
}
.experience_list {
  @include media-max(sm) {
    flex-wrap: wrap;
    margin: 30px 0 0 0;
    width: 100%;
  }
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  width: 80%;
  > div {
    @include media-max(xs) {
      width: 50%;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      &.explistnum {
        font-weight: 700;
        font-size: 48px;
        line-height: 65px;
        display: flex;
        @include media-max(xl) {
          font-size: 40px;
        }

        @include media-max(mini-tab) {
          font-size: 30px;
        }
      }
      &:last-child {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
.partners_list {
  // background: rgba(203, 230, 230, 0.15);
  // border-radius: 20px;
  // padding: 30px 20px;
  //  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  align-items: center;
  > div {
    &.partnerslist_left {
      width: 73%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      @include media-min(xl) {
        width: 68%;
      }
      @media (min-width: 1920px) {
        width: 60%;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        > div {
          padding-right: 20px;
          @include media-max(xs) {
            padding-right: 5px;
            padding-bottom: 5px;
          }
        }
        &.partnerslistleft_top {
          margin-bottom: 20px;
          @include media-max(xs) {
            margin-bottom: 0;
          }
          > div {
            width: calc(100% / 5);
            @include media-max(xs) {
              width: calc(100% / 3);
            }
            @media (max-width: 420px) {
              width: calc(100% / 2);
            }
            &.golderlogoformob {
              @include media-min(xs) {
                display: none;
              }
            }
          }
        }
        &.partnerslistleft_bot {
          > div {
            width: calc(100% / 3);
            @include media-max(xs) {
              width: calc(100% / 2);
            }
          }
        }
      }
    }
    &.partnerslist_right {
      width: 15%;
      @include media-min(xl) {
        width: 14%;
      }
      @media (min-width: 1920px) {
        width: 12.5%;
      }
      @include media-max(xs) {
        display: none;
      }
    }
    @include media-max(xs) {
      width: 100% !important;
    }
  }
}
.mission_list {
  @include media-max(sm) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;
  > div {
    @include media-max(sm) {
      width: 50%;
      margin-right: 0px;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
    display: flex;
    margin-right: 80px;
    > div {
      @include media-max(sm) {
        width: 100%;
        margin-right: 0px;
        flex-wrap: wrap;
        margin-bottom: 20px;
      }
      &.missionleftimg {
        margin-right: 20px;
      }
      &.missionrighttxt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          &:first-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
          &:last-child {
            font-weight: 700;
            font-size: 36px;
            line-height: 49px;
            @include media-max(xl) {
              font-size: 26px;
            }

            @include media-max(mini-tab) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.icons_list {
  .row {
    & > div {
      &:last-child {
        .list::after {
          display: none;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      font-size: 16px;
      text-align: center;
      margin-top: 15px;
    }
    &::after {
      content: "+";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      font-size: 45px;
      font-weight: 700;
    }
  }
}
.technology_list {
  @include media-max(sm) {
    flex-wrap: wrap;
  }
  display: flex;
  margin: 30px 80px 0 0;
  justify-content: space-between;
  div {
    @include media-max(sm) {
      width: 50%;
      margin-bottom: 40px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      text-transform: uppercase;
    }
    &::after {
      position: absolute;
      content: "";
      background: url("../assets/plusicon.png");
      top: 35px;
      right: -52px;
      width: 15px;
      height: 15px;
      @include media-max(xs) {
        right: -14px;
      }
    }
    &:last-child::after {
      display: none;
    }
  }
}
.propostions_list {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  @include media-max(sm) {
    flex-wrap: wrap;
  }
  > div {
    @include media-max(sm) {
      width: 33.33%;
      margin-bottom: 40px;
    }

    @include media-max(xs) {
      width: 50%;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    span {
      &.propolistheading {
        margin-top: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
      }
      &:last-child {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 12px;
        text-align: center;
      }
    }
  }
}
.propostions_list > div span:first-child .solution_cont .solution_list {
  margin: 30px -30px;
  div {
    span {
      &:last-child {
        width: 100%;
      }
    }
  }
}
.solution_cont,
.platform_cont {
  .treeties_container {
    .solution_list,
    .platform_list {
      width: 100%;
      > div {
        // @include media-max(sm){
        //   width: 33.33%!important;
        // }
        // @include media-max(xs){
        //   width: 50%!important;
        //   &:nth-child(even){
        //     &::after{
        //       content: none;
        //       right: -30px;
        //     }
        //   }
        // }
        justify-content: space-around;
      }
    }
  }
}
.platform_list {
  margin: 100px 0 50px;
  div {
    position: relative;
    &:first-child {
      &::after {
        content: "";
        background: url("../assets/isequalto.png");
        position: absolute;
        top: 35px;
        right: -40px;
        width: 24px;
        height: 15px;
        @include media-max(xs) {
          right: -30px;
        }
      }
    }
    &::after {
      content: "";
      background: url("../assets/dottedline.png");
      position: absolute;
      top: 43px;
      right: -50px;
      width: 50px;
      height: 2px;
      @include media-max(xs) {
        right: -30px;
      }
      @include media-max(sm) {
        display: none;
      }
    }
    &:last-child::after {
      display: none;
    }
  }
}
.treeties_container {
  position: relative;
  color: #fff;
  .eco-heading {
    padding: 0 35px;
    br {
      @include media-max(sm) {
        display: none;
      }
    }
    @include media-max(xs) {
      padding: 0 0px;
    }
  }
  h5 {
    font-size: 24px;
    line-height: 33px;
    font-weight: 400;
    @include media-max(xl) {
      font-size: 20px;
      line-height: 1.7;
    }
    @include media-max(xs) {
      font-size: 16px;
      line-height: 1.4;
    }
  }
  h1 {
    font-size: 48px;
    line-height: 62px;
    text-transform: uppercase;
    font-weight: 700;
    @include media-max(xl) {
      font-size: 40px;
      line-height: 1.4;
    }
    @include media-max(mini-tab) {
      font-size: 30px;
    }
    @include media-max(mini-tab) {
      font-size: 25px;
    }
    @include media-max(xs) {
      font-size: 20px;
      line-height: 1.4;
    }
  }
  p {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    @include media-max(mini-tab) {
      font-size: 16px;
    }
    @include media-max(sm) {
      font-size: 16px;
      line-height: 1.7;
    }
    @include media-max(xs) {
      line-height: 1.5;
    }
    br {
      @media (max-width: 620px) {
        display: none;
      }
    }
  }
  .comnright_txt {
    @include media-max(mini-tab) {
      right: 40px;
      top: -50px;
    }
    position: absolute;
    right: 20px;
    top: 0;
    color: #fff;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .greentxt {
    color: #83ba01;
    margin-right: 20px;
  }
  .leafbtn_cont {
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    padding: 0;
    margin: 40px 0 0;
    border: none !important;
    a {
      position: relative;
      display: flex;
      align-items: center;
      color: #fff;
    }
    span {
      position: absolute;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  &::after {
    position: absolute;
    content: "";
    background: #fff;
    width: 1px;
    height: calc(100% + 150px);
    right: 32px;
    top: 45px;
    @include media-max(xs) {
      right: 15px;
    }
  }
}
.propostions_cont {
  padding-bottom: 80px;
}
.treeties_footer {
  background: rgba(0, 23, 24, 0.65) no-repeat;
  padding: 50px 5% 0;
  .footer_logo {
    @include media-max(xs) {
      margin-bottom: 40px;
    }
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .footmiddle_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 20px 0 30px;
    .copyright {
      margin-top: 20px;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #fff;
      }
    }
  }
  .social_links {
    display: flex;
    justify-content: flex-start;
    margin: 30px 0 0;
    padding: 0;
    li {
      margin-right: 20px;
      a {
        color: #fff;
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .partnersfooter_cont {
    h3 {
      font-weight: 600;
      font-size: 15px;
      color: #fff;
      margin-bottom: 15px;
    }
    .footerparners_list {
      display: flex;
      flex-direction: row;
      margin: 0;
      flex-wrap: wrap;
      padding: 0;
      li {
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        width: 50%;
        margin-bottom: 10px;
        list-style: disc inside;
      }
    }
  }
  .footermenu {
    display: flex;
    flex-direction: row;
    margin: 0;
    flex-wrap: wrap;
    padding-left: 20px;
    @include media-max(xs) {
      text-align: center;
      margin-top: 30px;
      padding: 0;
    }
    li {
      line-height: 22px;
      margin-bottom: 15px;
      width: 50%;
      a {
        font-weight: 400;
        font-size: 15px;
        color: #fff;
        &:hover {
          color: #83ba01;
        }
      }
      &:last-child {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
  .partners_list {
    margin: 0;
  }
}
.propostions_cont .treeties_container::after {
  height: calc(100% + 55px);
}
.solution_cont .treeties_container::after {
  height: calc(100% + 180px);
}
.mission_cont .treeties_container::after {
  height: calc(100% + 200px);
}
.expstory_cont {
  padding: 330px 0 200px;
  @include media-max(md) {
    padding: 100px 0 100px;
  }
  @include media-max(xs) {
    padding: 200px 0 120px;
  }
  p {
    margin: 30px 0 50px;
  }
}
.keyachiev_cont {
  h1 {
    margin-bottom: 20px;
  }
  .keyachiev_list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    li {
      position: relative;
      padding: 0 15px;
      margin: 0 0 15px;
      font-weight: 400;
      font-size: 24px;
      line-height: 33px;
      @include media-max(mini-tab) {
        font-size: 16px;
      }
      &::before {
        position: absolute;
        content: "";
        width: 5px;
        height: 5px;
        background: #fff;
        border-radius: 50px;
        top: 15px;
        left: 0;
      }
    }
  }
}
.keylearn_cont {
  .keylearn_list {
    width: 100%;
    div {
      span {
        &:first-child {
          font-weight: 700;
          font-size: 48px;
          line-height: 65px;
        }
      }
    }
  }
}
.forestchange_list {
  width: auto;
  justify-content: flex-start;
  > div {
    width: 33.33%;
    @include media-max(mini-tab) {
      width: 33.33%;
    }
    @include media-max(xs) {
      width: 33.33%;
    }
    // padding: 0 80px 0 0;
    align-items: flex-start;
    span {
      &.forechngnum {
        font-weight: 700;
        font-size: 48px;
        line-height: 65px;
        @include media-max(xs) {
          font-size: 20px;
        }
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          @include media-max(xs) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.networkmod_middlecontainer {
  display: flex;
  @include media-max(sm) {
    flex-direction: column;
  }
  > div {
    @include media-max(sm) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @include media-max(xs) {
    flex-wrap: wrap;
  }
}
.netwrkmod_topheading {
  @include media-max(xs) {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
}
.netwrkmod_left {
  padding-right: 20px;
  @include media-max(sm) {
    margin-bottom: 20px;
  }
}
.netwrkmod_right {
  padding-left: 20px;
  @include media-max(sm) {
    margin-top: 20px;
    padding-left: 0;
  }
}
.netwrkmod_left,
.netwrkmod_right {
  @include media-max(xs) {
    width: 100%;

    flex-basis: 100%;
    max-width: 100%;
  }
  flex-basis: 20%;
  max-width: 20%;
  span {
    display: flex;
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
.netwrkmod_middle {
  @include media-max(xs) {
    flex-basis: 100%;
    max-width: 100%;
    margin: 30px 0;
  }
  flex-basis: 60%;
  max-width: 60%;
  border: 1px dashed #fff;
  border-radius: 25px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 20px;
  justify-content: center;
  align-items: baseline;
  > div {
    @include media-max(xs) {
      width: 50%;
      margin: 15px 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 4 - 10px);
    margin: 0 5px 20px;
    justify-content: flex-start;
    span {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
    }
  }
}
.netwrkmod_botheading {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
}
.morelrpadd_cont.greenpoints_cont {
  .treeties_container {
    padding: 0 12% 0 20%;
    @include media-min(xl) {
      padding: 0 12% 0 23%;
    }
    @include media-max(mini-tab) {
      padding: 0 5% 0 5%;
    }
  }
}
.greenpoints_list {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
  padding: 0;
  li {
    @include media-max(xs) {
      width: 100%;
      margin-bottom: 30px;
    }
    width: calc(100% / 2 - 10px);
    margin: 0 5px;
    position: relative;
    filter: drop-shadow(0px 0px 8px rgba(131, 186, 1, 0.3));
    img {
      width: 100%;
    }
    span {
      position: absolute;
      top: 50%;
      left: 40px;
      transform: translateY(-30px);
    }
  }
}
.backbtn {
  background-color: transparent !important;
  border: none !important;
  display: flex !important;
  align-items: center;
  margin: 20px 60px 20px !important;
  padding: 0 !important;
  position: fixed !important;
  top: 15%;
  left: calc(100% - 89%);
  z-index: 1;
  @media (max-width: 1600px) {
    left: calc(100% - 96%);
  }
  @media (max-width: 1440px) {
    left: calc(100% - 99%);
  }
  @include media-max(xs) {
    margin: 30px 15px !important;
  }
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    margin-left: 15px;
  }
  &:focus {
    box-shadow: none !important;
  }
}

// /////////////////////// ABOUT US PAGE CSS START ///////////////////////

.about-us-page {
  .aboutourstory_cont {
    padding: 300px 0;
    .aboutourstory_content {
      padding: 0 16% 0 0;
      @include media-max(xs) {
        padding: 0;
      }
    }
  }
  .col-extra-padding {
    margin: 0 -19px;
    @include media-max(sm) {
      margin: 0 -7px;
    }
    div {
      padding: 0 10px;
      @include media-max(sm) {
        padding: 0 7px;
      }
      @include media-max(xs) {
        padding: 0 3px;
      }
    }
  }
  .leaders-box {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @include media-max(xs) {
      margin-top: 30px;
    }
    // .leaderbox_info {
    //   padding: 20px 10px;
    //   color: transparent;
    //   background-color: transparent;
    //   position: absolute;
    //   z-index: 1;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 0;
    //   overflow: hidden;
    //   visibility: hidden;
    //   transition: all 0.3s ease-in-out;
    //   font-size: 12px;
    //   line-height: 22px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }
    .img {
      margin-bottom: 20px;
      img {
        width: 170px;
      }
    }
    .text-center {
      h5 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        @include media-max(xs) {
          font-size: 15px;
          line-height: 18px;
          margin-top: 10px;
        }
      }
    }
    @include media-max(xs) {
      h5 {
        font-size: 14px;
        line-height: 1.5;
      }
      span {
        font-size: 12px;
        line-height: 1.5;
        display: block;
      }
    }
    // &:hover .leaderbox_info {
    //   visibility: visible;
    //   color: #fff;
    //   background: rgba(0, 0, 0, 0.75);
    //   height: 100%;
    //   transition: all 0.3s ease-in-out;
    // }
  }
  .treeties_container {
    p {
      margin-bottom: 30px;
      @include media-max(xs) {
        padding-right: 0px;
      }
    }
    .our-story-img {
      img {
        padding: 15px 0;
      }
    }
  }
}

.progression_list {
  .row {
    > div {
      @include media-max(xs) {
        width: 50% !important;
      }
      @media (max-width: 480px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 20px;
        .img-arrow {
          display: none;
        }
      }
    }
  }
  .row {
    &:last-child {
      .img-arrow {
        display: none;
      }
    }
  }

  .img-arrow {
    &.left-img {
      transform-style: preserve-3d;
      transform: rotateY(180deg);
    }
    height: 100%;
    display: flex;
    align-items: center;
    .pointer {
      max-width: 67%;
      margin-top: 16%;
      padding-right: 7px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        right: 0;
        bottom: 0;
        background: #83ba01;
        border-radius: 50%;
      }
    }
  }
}
.stakeholders_list {
  > .row {
    > div {
      &.col-lg-10 {
        > .row {
          .col-sm-6 {
            width: 33.33% !important;
            @include media-max(xs) {
              width: 50% !important;
            }
          }
          > div {
            &:nth-child(3),
            &:nth-child(3n + 3) {
              .stakeholders_box::after {
                display: none;
              }
            }
          }
        }
      }
      &.col-lg-2 {
        .stackholersicons_cont {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
        &.curlybacketimg {
          justify-content: center;
          display: flex;
          position: relative;
          &::after {
            content: "";
            background: url("../assets/icon_shaping.svg") no-repeat;
            position: absolute;
            top: 18%;
            left: -70px;
            width: 54px;
            height: 100%;
          }
        }
      }
      @media (max-width: 800px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  ul {
    list-style: unset;
    margin: 0px;
    padding: 0 0 0 20px;
  }
  h5 {
    width: fit-content;
    text-align: center;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

// /////////////////////// ABOUT US PAGE CSS End ///////////////////////

// /////////////////////// CONTACT US CSS START ///////////////////////
.contact_us_form {
  padding: 30px 20px;
  background: rgba(1, 48, 0, 0.7);
  overflow: hidden;
  border-radius: 20px;
  .row {
    > div {
      @media (max-width: 800px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &.col-sm-4 {
        .form-img {
          background: url("../assets/contactform_leftimg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
      }
      &.col-sm-8 {
        @media (max-width: 800px) {
          order: -1;
        }
        form {
          div {
            margin-bottom: 15px;
            position: relative;
            label {
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              sup.mandatory {
                color: red;
                top: -0.2rem;
              }
            }
            .form-control {
              border-radius: 10px;
            }
            textarea {
              height: 180px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            .mandatory.has-error {
              position: absolute !important;
            }
          }
          .leafbtn_cont {
            span {
              appearance: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              -o-appearance: none;
            }
          }
        }
      }
      .form-img {
        @media (max-width: 800px) {
          display: none;
        }
      }
    }
  }
}

.theme-accordion {
  margin-top: 20px;
  .szh-accordion {
    .szh-accordion__item {
      border-bottom: 1px solid hsla(0, 0%, 95.3%, 0.2);
      padding: 0 0 10px;
      .szh-accordion__item-heading button {
        padding: 15px 30px 15px 0;
        background: transparent;
        border: none;
        color: #fff;
        width: 100%;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: left;
        position: relative;
        &::after {
          position: absolute;
          content: "\f107";
          font-family: fontawesome;
          font-size: 18px;
          top: 30%;
          right: 0;
          border: 1px solid;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          line-height: 30px;
          transition: 0.2s;
        }
        @include media-max(xs) {
          font-size: 16px;
          line-height: 28px;
        }
      }
      &.szh-accordion__item--expanded {
        .szh-accordion__item-heading button {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// /////////////////////// CONTACT US CSS END ///////////////////////

// ////////////////////// PARTNERS CSS START //////////////////////
.content-box {
  margin-bottom: 30px;
  border-radius: 20px;
  padding: 40px 60px 60px 60px;
  @include media-max(xs) {
    padding: 20px;
    margin-bottom: 20px;
  }
  &.bg-color {
    background-color: #d9d9d9;
    min-height: 426px;
  }
  &.bg-dark {
    background: rgba(7, 29, 27, 0.7);
    opacity: 70%;
  }
  &.bg-trans {
    background: transparent;
  }

  .partner-img {
    text-align: center;
    margin-bottom: 40px;
  }
}

.theme-feedback-slider {
  sup {
    vertical-align: super;
  }
  .partner-image {
    width: 95px;
    height: 95px;
    min-width: 95px;
    min-height: 95px;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .carousel img {
    width: initial;
    height: inherit;
  }
  h2 {
    text-indent: -56px;
    padding-left: 56px;
    @include media-max(xs) {
      font-size: 22px;
      line-height: 36px;
    }
  }
  .partner-profile {
    .about-partner {
      h4 {
        &.greenunderline {
          font-size: 18px;
        }
        &.treeties_subheading {
          font-size: 16px;
        }
      }
    }
  }
}
.menu-links {
  display: none;
  z-index: 9;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  // display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  ul {
    text-align: center;
    li {
      a {
        list-style-type: none;
        font-weight: 700;
        font-size: 24px;
        color: #fff;
        margin-bottom: 33px;
        &:hover {
          color: #83ba01;
        }
      }
      &.activecurtmenu {
        a {
          color: #83ba01;
        }
      }
    }
  }
}
.header_cont {
  .close-icon {
    margin: 30px auto;
  }
}
.close-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 30px auto 0;
  @media (min-width: 1920px) {
    margin: auto auto 150px;
  }
  &.bg-clr {
    background-color: rgba(0, 13, 16, 0.4);
    cursor: pointer;
  }
}
.bg-cover {
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.partner-profile {
  padding-left: 56px;
}
// ////////////////////// PARTNERS CSS END //////////////////////

// ///////////////////// PLATFORM CSS START ////////////////////
.after-none {
  &::after {
    content: none !important;
  }
}

.opration-process {
  background-size: contain;
  background-position: left 0, right 0;
  background-repeat: no-repeat, no-repeat;
  position: relative;
  height: 126px;
  @media (min-width: 1660px) {
    height: 160px;
  }
  .process-inner {
    position: absolute;
    top: 60px;
    padding: 0 65px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .process-box {
    border: 3px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 5);
    height: 110px;
    margin: 0 17px;
    position: relative;
    margin-bottom: -60px;
    padding: 10px;
    @media (min-width: 1660px) {
      width: calc(100% / 5 - 45px);
      height: 169px;
    }
    @media only screen and (min-width: 1450px) and (max-width: 1640px) {
      height: 140px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 39px;
      height: 3px;
      background-color: #fff;
      top: 50%;
      right: -39px;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
    p {
      font-size: 14px !important;
      line-height: 1.2;
      text-align: center;
    }
  }
}

.half-round-borer {
  &.right {
    right: -10px;
    transform: rotate(90deg);
  }
  &.left {
    left: -10px;
    transform: rotate(-90deg);
  }
  position: absolute;
  top: 14px;

  height: 100px;
  width: 126px;
  border-radius: 30px 30px 0 0;
  border: 3px solid #fff;
  border-bottom: none;
}

.ownership {
  @media (max-width: 991px) {
    width: 1000px;
  }
  .partner-image {
    max-width: 115px;
    margin-left: -54px;
  }
  .partner-box {
    margin: 20px 0 20px -20px;
    p {
      text-align: center;
      line-height: 1.5;
      width: fit-content;
    }
  }
  .partner_box_first {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .partner-content {
    min-height: 126px;
    border-left: 3px solid #fff;
    .col-2 {
      width: 20% !important;
    }
    .content-row {
      height: 100%;
      position: relative;
      min-height: 126px;
      p {
        text-align: center;
        line-height: 1.5;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        height: 24px;
        width: 24px;
        border-radius: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        background-color: #fff;
        height: 3px;
        width: 100%;
        transform: translateY(-50%);
      }
    }
  }
}

// ///////////////////// RESPONSIVE CSS START /////////////////
.home_bg {
  .treeties_container {
    @include media-max(mini-tab) {
      padding-right: 10%;
    }
  }
  .solution_list {
    margin: 30px 0;
  }
}
.leaders_list {
  @media (max-width: 800px) {
    .col-sm-4 {
      width: 50% !important;
    }
  }
}
.ecosystem_list {
  margin-left: -30px;
  width: 95%;
  @include media-max(xs) {
    margin-left: 0;
    width: 100%;
  }
  h5 {
    line-height: 30px;
  }
  @media (max-width: 800px) {
    .col-sm-6 {
      width: 50% !important;
      margin-bottom: 40px;
    }
  }
}
// ///////////////////// RESPONSIVE CSS END /////////////////
.carousel-indicators {
  bottom: -40px !important;
  li {
    width: 12px;
    height: 12px;
    background: #fff;
    margin: 0 6px;
    border-radius: 50%;
    font-size: 0;
    &.active {
      background: #83ba01;
    }
  }
}
.partners_bg {
  .partners_list {
    .CSEIwgiteimg {
      img {
        width: 67px;
      }
    }
  }
}
.platform_bg {
  p {
    br {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}
.shapfutrepltfrm_list {
  > .row {
    > div {
      @media (max-width: 991px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      &.col-sm-10 {
        .row {
          > div {
            @media (max-width: 991px) {
              flex: 0 0 50%;
              max-width: 50%;
            }
            .list::after {
              @media (max-width: 991px) {
                display: none;
              }
            }
          }
        }
      }
      &.col-sm-2 {
        &.curlybacketimg {
          justify-content: center;
          display: flex;
          position: relative;
          &::after {
            content: "";
            background: url("../assets/icon_shaping.svg") no-repeat;
            position: absolute;
            top: 18%;
            left: -36px;
            width: 54px;
            height: 100%;
          }
          .restorght_img {
            margin-top: -70px;
          }
        }
      }
    }
  }
}
.scroller_container {
  display: block;
  width: 100%;
  position: relative;
  &.oprationpro_container {
    height: 215px;
    margin-top: 100px;
  }
  .process-title {
    position: absolute;
    top: -30px;
    left: calc(100% - 50%);
    transform: translateX(-50%);
    @media (max-width: 991px) {
      top: -7px;
      transform: translateX(0%);
    }
  }
  @media (max-width: 991px) {
    overflow-x: auto;
  }
  .opration-process {
    @media (max-width: 991px) {
      width: 1000px;
    }
  }
}

.stakeholders_box {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    right: 11px;
    border-top: 1px dashed #fff;
    top: -10px;
    width: 50px;
    @include media-max(xs) {
      display: none;
    }
  }
}
.stakeholders_list
  .row
  .col-lg-10
  .row
  > div:nth-child(3)
  .stakeholders_box::after,
.stakeholders_list
  .row
  .col-lg-10
  .row
  > div:last-child
  .stakeholders_box::after {
  display: none;
}
.commonfirstpadd_cont {
  padding-top: 330px;
}
.digiecoplatlst {
  margin-left: -45px !important;
  justify-content: space-between;
}
.proevonplatlst {
  > div {
    position: relative;
    &::after {
      content: "";
      background: url("../assets/dottedline.png");
      position: absolute;
      top: 43px;
      right: -90px;
      width: 50px;
      height: 2px;
      @include media-max(xs) {
        right: -30px;
      }
    }
    &:last-child::after {
      display: none;
    }
    &:nth-child(2)::after {
      @include media-max(xs) {
        display: none;
      }
    }
  }
}
.aboutuslist {
  margin-left: -25px;
}
.midmarginleft {
  margin-left: -40px !important;
}
.lessmarginleft {
  margin-left: -30px !important;
}
.treerestproplt {
  width: 76%;
  @include media-max(xs) {
    width: 100%;
    .row > div {
      flex: 0 0 48%;
      max-width: 48%;
    }
  }
}
/*safari design issue solved css*/
@supports (-webkit-hyphens: none) {
  .technology_list {
    div {
      &::after {
        right: -110px;
      }
    }
  }
  .shapfutrepltfrm_list {
    .row .col-sm-10 {
      .row {
        &.midmarginleft {
          margin-left: -100px !important;
        }
        &.lessmarginleft {
          margin-left: -90px !important;
        }
      }
    }
  }
  .digiecoplatlst {
    margin-left: -80px !important;
  }
  .proevonplatlst {
    > div {
      &::after {
        right: -140px;
      }
    }
  }
  .aboutuslist {
    margin-left: -110px;
  }
  .treerestproplt .lessmarginleft {
    margin-left: -90px !important;
  }
}
.about-us-page .row.col-extra-padding div {
  padding: 0 19px;
}
.header .right-wrapper {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  gap: 15px;
  align-items: center;
}
/*projectdetails action button popup css*/
.treedetails_wrap {
  .szh-accordion {
    .szh-accordion__item {
      border: 1px solid #ddd;
      margin: 0 0 10px;
      padding: 0;
      border-radius: 4px;

      .szh-accordion__item-heading button {
        color: #fff;
        padding: 15px;
        font-size: 18px;
        line-height: 18px;
        background: #8eba76;
        border-radius: 3px 3px 0 0;
        width: 100%;
        border: none;
        position: relative;
        text-align: left;

        &::after {
          position: absolute;
          content: "\f107";
          font-family: fontawesome;
          font-size: 18px;
          transition: 0.2s;
          border: none;
          top: 30%;
          right: 15px;
        }
      }

      .szh-accordion__item-content {
        border-top: 1px solid #ddd;
        padding: 15px;
        max-height: 215px;
        overflow-y: auto;

        .treedetails_list {
          &.soiltreedetails_list {
            margin: 0;

            li {
              .treedetpopright {
                flex-direction: initial;
                margin-left: 0;
                width: 100%;
                justify-content: space-between;

                span {
                  &:first-child {
                    margin-right: 20px;
                    white-space: nowrap;
                  }

                  &:last-child {
                    text-align: right;
                  }
                }
              }
            }
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background: #8eba76;
          border-radius: 10px;
        }
      }

      &.szh-accordion__item--expanded {
        .szh-accordion__item-heading button {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &.porjectviewmod_cont {
    .szh-accordion .szh-accordion__item .szh-accordion__item-content {
      max-height: 310px;
    }
  }
}
/****Ecosys edit Project page design changes*****/
.ecosysdash_link {
  top: 43px;
  color: #118d45;
  position: absolute;
  right: 18px;
  width: auto !important;
}
.prolistinner_list {
  &.opportunity,
  &.disturbanceImpact {
    display: none !important;
  }
}
.headbottom_cont {
  .headbottom_content {
    .search_container {
      display: none;
    }
  }
}
/********user not exist msg css****/
.usernotexst_errmsg {
  color: red;
  p {
    margin: 0;
  }
}
/**********Download report icon and button design css************/
.downloadrept_icon {
  position: absolute;
  top: 10px;
  display: inline-flex !important;
  cursor: pointer;
  width: auto !important;
  i {
    font-size: 20px;
    color: #118d45;
  }
}
.downloadreprt_btn {
  @media (max-width: 1246px) {
    border-right: 1px solid #ddd;
    margin-left: auto;
    padding-right: 20px;
  }
  button {
    background-color: #50b041 !important;
    font-size: 14px;
    height: 34px;
    padding: 0 10px;
  }
}
/*********popup desing issue css******/
.pf-c-modal-box {
  background-color: #fff !important;
}
.projectdetactnbtn_modal {
  button.pf-c-button.pf-m-plain {
    display: block !important;
    right: 5px;
    top: 10px;
  }
  .pf-c-modal-box__body {
    padding-top: 50px !important;
    .treedetails_cont .container {
      padding: 0;
    }
  }
}
.footerlogo_toltip {
  position: absolute;
  width: max-content;
  background-color: #333;
  color: #fff;
  padding: 5px;
  bottom: 0;
  right: -155px;
  border-radius: 5px;
  z-index: 9999;
  color: #fff;
}
.nav-open .sidebar_cont .sidebarinner_cont {
  .footersitelogo .footerlogo_toltip {
    right: -85px;
  }
}
//css for Firefox browser
@-moz-document url-prefix() {
  .projdet_heading {
    flex-wrap: nowrap;
    .projdetcont_btn {
      margin-top: 3px;
      a {
        white-space: nowrap;
      }
    }
  }
}
.policypage_cont {
  .treeties_container {
    h1 {
      font-size: 34px;
    }
    > .greentxt {
      font-size: 18px;
      font-weight: 600;
    }
    .defination_cont {
      > .greentxt {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .policypage_bullets {
      margin: 0;
      padding: 0;
      li {
        list-style: outside disc;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }
    p {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .paddleft_plcypage {
      padding-left: 30px;
      > .greentxt {
        font-size: 18px;
        font-weight: 600;
      }
      p {
        margin-bottom: 5px;
      }
    }
    .halfwidth_plcycont {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 48%;
        margin-bottom: 20px;
        @media (max-width: 768px) {
          width: 100%;
        }
        &:nth-child(odd) {
          padding-right: 2%;
          @media (max-width: 768px) {
            padding-right: 0;
          }
        }
        &.fullwidth_plcycont {
          width: 98%;
        }
      }
      .policypage_bullets li {
        list-style-position: inside;
      }
    }
    &::after {
      display: none;
    }
  }
}
.contactfrmpgebot_cont {
  h1 {
    font-size: 34px;
    margin-bottom: 25px;
  }
  .contactpgefoot_addcont {
    h3 {
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 600;
    }
    p {
      line-height: 28px;
      font-size: 17px;
      span {
        display: flex;
        margin-top: 15px;
      }
    }
  }
}
.co2emicalcwrap_cont.selectngoshowcont {
  overflow-y: auto;
  scrollbar-width: thin;
}

.co2emicalcwrap_cont.selectngoshowcont::-webkit-scrollbar {
  width: 5px;
}
.ReactStickyHeader_fixed {
  z-index: 999 !important;
}
.modalforteammeb_infocont {
  button[aria-label="Close"] {
    top: 5px;
    right: 0;
  }
  .pf-c-modal-box__header {
    background: #f1f1f1;
    margin-right: 0 !important;
    padding: 10px 20px;
    .pf-c-modal-box__title {
      font-size: px-to-rem(20);
      font-weight: 600;
      .pf-c-modal-box__title-text {
        color: #50b041;
      }
    }
  }
  .pf-c-modal-box__body {
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }
}
.projects_container {
  .viewmap {
    &.projectpg_mapcont {
      width: 100%;
      margin: 80px 0 40px 0 !important;
      .mapClass {
        width: 100%;
        height: 100vh;
        .root-hamburger-menu {
          .hamburger-menu {
            top: 130px;
            right: 30px;
            position: absolute;
            z-index: 2;
            svg {
              transition: 0.3s;
            }
            .prosumrymodal_close {
              display: none;
            }
            &.iconmapsidebar {
              width: 340px;
              border-radius: 6px 6px 0 0;
              .prosumrymodal_close {
                display: flex;
                position: absolute;
                right: 20px;
              }
            }
          }
          .hamburger-menu-sidebar {
            top: 166px;
            position: absolute;
            z-index: 2;
            width: 340px;
            &.togglemapsidebar {
              right: 30px;
            }
            .prolisttime_cont {
              span {
                color: #444;
                font-family: $font-poppins;
                font-size: 14px;
                font-weight: 500;
                i {
                  font-size: 16px;
                  color: #50b041;
                }
              }
            }
            .typeofverify_forest {
              border-radius: 50px;
              display: inline-flex;
              height: 28px;
              line-height: 28px;
              justify-content: center;
              align-items: center;
              padding: 0 12px;
              margin: 10px 0 20px;
              color: #fff;
              font-family: $font-poppins;
              font-size: px-to-rem(13);
              font-weight: 500;
              text-transform: uppercase;
              white-space: nowrap;
              @include media-max(xl) {
                font-size: px-to-rem(11);
              }
              &.unhlthyforbtn {
                background: #b54e56;
              }
              &.hlthyforbtn {
                background: #50b041;
              }
              svg {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
.statisticslist_cont {
  padding: 0;
  margin: 0;
}
.sidebarfoot_cont {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  a {
    display: flex;
    align-items: center;
    span {
      color: #50b041;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    &.backtoprojects_btn {
      span {
        margin-left: 10px;
      }
    }
    &.projectspg_viewdetlsbtn {
      span {
        margin-right: 10px;
      }
    }
  }
}
a.backmainmapview {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    color: #50b041;
    padding-left: 5px;
  }
}
.ecosysprosimguplds_contianer {
  .addproject_form {
    padding: 0;
    li {
      &:first-child {
        label {
          font-size: 14px !important;
          margin: 0 0 20px;
          color: #444;
          font-weight: 700;
          line-height: 16px;
          .mandatory {
            top: 15px;
          }
        }
      }
      &.inventory_cont {
        display: flex;
        border: 1px solid #ddd;
        padding: 30px;
        border-radius: 10px;
        justify-content: space-between;

        .divUploadFile {
          display: flex;
          width: 45%;
          .qq-upload-drop-area {
            display: none;
          }
          .qq-upload-button {
            display: flex;
            flex-direction: column;
            height: 230px;
            justify-content: space-evenly;
            align-items: center;
            background: #f4f4f4;
            border: 1px dashed #757575;
            color: #333333;
            font-weight: 500;
            width: 100%;
            border-radius: 10px !important;
            .prolvlimgs_updtxt {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              span {
                color: #999999;
                font-size: 12px;
                width: 63%;
                text-align: center;
                font-weight: 300;
              }
            }
            .prolvlimgs_uploadbtn {
              background: #50b041;
              color: #fff;
              height: 36px;
              display: flex;
              border-radius: 6px;
              width: 100px;
              justify-content: center;
              align-items: center;
              font-size: 13px;
            }
          }
          input {
            position: absolute;
            opacity: 0;
            height: 100% !important;
            width: 100%;
            cursor: pointer;
          }
        }
        .proimgsupld_list {
          margin: 20px 0 0;
          width: 45%;
          margin-left: 5%;
          padding-right: 3%;
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          height: 210px;
          &::-webkit-scrollbar {
            width: 4px;
            background-color: #ebebeb;
            height: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #c8c8c8;
            border-radius: 9px;
          }
          .file-name-container {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 20px;
            .file-name {
              display: inline-flex;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              color: #444;
              text-transform: capitalize;
              word-break: break-word;
              padding-right: 20px;
              .uploadedfile_icon {
                margin: 0 10px 0 0;
              }
            }
            .remove-file-button {
              position: absolute;
              right: -5px;
              top: 0;
            }
          }
        }
      }
      &.addpro_btn {
        margin: 20px 0 0;
        .prolvlimgsbtn_wrap {
          display: flex;
          margin-left: auto;
          width: 100%;
          justify-content: flex-end;
          button {
            &:first-child {
              margin-right: 10px;
              background: transparent !important;
              color: #50b041 !important;
              border: 1px solid #50b041 !important;
            }
          }
        }
        button {
          border: none;
        }
        label[for="forinventory"] {
          font-size: 16px;
          font-weight: 600;
          margin: 20px 0;
          width: 100%;
        }
        .project-images-list {
          display: flex;
          flex-wrap: wrap;
          padding: 0;
          flex-direction: row;
          width: 100%;
          li {
            display: flex;
            width: calc(100% / 3 - 20px);
            margin: 0 20px 15px 0;
            div {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              word-break: break-all;
              a {
                background: transparent !important;
                color: #333 !important;
                padding: 0;
                letter-spacing: 0;
                height: auto;
                line-height: normal;
                font-weight: 500;
              }
              button {
                height: 20px !important;
                width: 20px !important;
                padding: 0 10px;
                letter-spacing: 0;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
/************map bounds info window css****************/
.mapboundsfield_cont {
  position: relative;
  .hero-title13 {
    .project-summary16 {
      font-size: 11px;
    }
  }
  #mapBounds::placeholder {
    color: #333;
    transform: 0;
  }
  .mapboundinfo_cont {
    position: absolute;
    right: -20px;
    bottom: 0;
    svg {
      &:hover {
        path {
          fill: #50b041;
        }
      }
    }
  }
  .mapbound_infowindow {
    position: absolute;
    background: #fff;
    box-shadow: 0 0 15px 0 #00000014;
    border-radius: 6px;
    overflow: hidden;
    padding: 10px;
    left: 100px;
    top: -28px;
    transition: 0.3s ease-out;
    width: 240px;
    z-index: 1;
    word-break: break-all;
    .mapinfowidw_clsbtn {
      border: none;
      background: transparent;
      position: absolute;
      right: 5px;
      top: 5px;
      svg {
        &:hover {
          path {
            stroke: #50b041;
          }
        }
      }
    }
  }
}
.uploadedimagestbl_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  label[for="forinventory"] {
    color: #444;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    margin: 0 0 10px;
  }
  .uploadedimgtbl {
    width: 100%;
    thead {
      border-radius: 5px 5px 0px 0px;
      background: #444;
      height: 30px;
      tr {
        th {
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          padding: 0 10px;
          &:first-child {
            border-radius: 5px 0 0 0;
          }
          &:nth-child(4) {
            width: 35%;
          }
          &:last-child {
            border-radius: 0 5px 0 0;
          }
        }
      }
    }
    tbody {
      tr {
        height: 60px;
        td {
          padding: 10px;
          border-bottom: 1px solid #d4d4d4;
          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
          }
          .uploadedimgwrap {
            display: flex;
            justify-content: center;
            width: 40px;
            height: 40px;
            img {
              object-fit: cover;
              border-radius: 6px;
            }
          }
          .upldimgactionswrap {
            display: flex;
            a {
              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
    &.waterparamtbl_cont {
      thead {
        tr {
          th {
            white-space: nowrap;
          }
        }
      }
    }
  }
  .uploadedimgs_pagination {
    display: flex;
    width: 100%;
    .pagination {
      margin: 20px 0 0 auto;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      li {
        width: 36px;
        height: 30px;
        border-right: 1px solid #d9d9d9;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          font-family: $font-1;
          color: #333;
          font-size: 14px;
          i {
            color: #333;
          }
        }
        &:last-child {
          border-right: none;
        }
        &.active {
          a {
            color: #50af41;
          }
        }
      }
    }
  }
}
.addlayesrfrom_cont .land-parecel-content-area1 .left-content1 {
  > .hero-title13 {
    align-items: center;
    .addlayer_btn {
      margin-left: 20px;
    }
  }
  .newlayers_form {
    width: 100%;
    .container-26 {
      > div {
        gap: 25px;
        &:first-child {
          > div {
            &:nth-child(2) {
              margin-top: 7px;
              margin-bottom: 0;
            }
          }
        }
        &:last-child {
          > div {
            &:nth-child(2) {
              margin-top: 0;
              margin-bottom: 0;
            }
            &:last-child {
              .hero-title13 {
                align-items: flex-start;
              }
            }
            .radio-button-input-text2 {
              margin: 0 !important;
            }
          }
        }
      }
    }
  }
}
.fieldofficertbl_cont {
  .fieldofficer_tbl {
    tbody {
      tr {
        td {
          &.action_cont {
            white-space: nowrap;
            padding-right: 20px;
            button {
              background: transparent;
              padding: 0;
              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
  &.addlayermastertbl_cont {
    .growalarm_content {
      .commontbl_wrap {
        height: calc(100vh - 210px);
      }
    }
  }
}
.timeseriestbl_cont {
  .timeseriesdata_tbl {
    tbody {
      tr {
        td {
          .tbltimelapse_imgcont {
            img {
              width: 50px;
            }
          }
        }
      }
    }
  }
}
.layermaster_modal {
  .addproject_form {
    li {
      label {
        .mandatory {
          color: red;
          font-size: 10px;
        }
      }
      .radiobtns_wrap {
        flex-wrap: nowrap;
        align-items: center;
        @media (max-width: 460px) {
          flex-direction: column;
        }
        .radio__input {
          display: flex;
          background: #ebebeb;
          height: 35px;
          line-height: 35px;
          // min-width: 100px;
          margin: 0 5px;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          @media (max-width: 460px) {
            margin: 0 0 10px;
          }
          .custom-radio {
            display: flex;
            width: 20px;
            input {
              height: 20px;
              line-height: 20px;
              border: 1px solid #ddd;
              border-radius: 50px;
            }
          }
          label {
            font-size: 13px !important;
            width: 100%;
            padding: 0 0 0 5px;
          }
          &:first-child {
            margin-left: 0;
          }
        }
      }
      &.inventory_cont {
        .mandatory.has-error {
          position: relative;
          top: 0;
        }
      }
    }
  }
}
.field input:placeholder-shown + label {
  cursor: text !important;
}
sup.mandatory {
  color: red;
}
/************common tooltip css*************/
.icon_txtveriftedhovr {
  background: #333;
  border-radius: 4px;
  color: #fff;
  opacity: 0;
  padding: 5px;
  position: absolute;
  left: -10px;
  transition: 0.3s ease-out;
  visibility: hidden;
  z-index: 1;
  font-size: 13px;
  &::after {
    border-bottom: 7px solid #333;
    border-left: 7px solid #0000;
    border-right: 7px solid #0000;
    content: "";
    opacity: 1;
    position: absolute;
    left: 6px;
    top: -6px;
    transform: translateX(30%);
    transition: 0.3s;
    visibility: visible;
  }
}
.growalarm_container {
  @media (min-width: 1920px) {
    width: calc(100% - 6%) !important;
  }
  &.timeseriestbl_cont,
  &.fieldofficertbl_cont {
    .growalarm_content {
      .commontbl_wrap {
        height: calc(100vh - 200px);
      }
    }
    @media (min-width: 1700px) {
      max-width: 100% !important;
    }
  }
}
/****************loader css**************/
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  &.listspages_cont,
  &.loaderprojectspg_cont {
    margin: 50px 50px 0;
  }
  &.loaderprojectspg_cont {
    background-color: transparent;
  }
}
.spinner-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #e5f3e3;
  border-top: 4px solid #50b041;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.prolistmodal {
  .loader-overlay {
    position: absolute;
    .spinner-loader {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
    }
  }
}
/***********css for add dashboard url***************/
.registartion_container {
  &.adddashboardurl_cont {
    margin-top: 80px;
    height: calc(100vh - 90px);
    .registartion_cont {
      margin: 0;
      box-shadow: none;
      border-radius: 10px;
      height: 100%;
      .register_form {
        li {
          input {
            width: 98%;
          }
        }
      }
    }
  }
}
